import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useCanBreakPointSmallMobile} from "../../../hooks/useViewport";
import {formatAmount} from "../../../utils/helpers";
import {CHARGE_METHOD} from "../../../config/constants";
import loadjs from "xp-loadjs";
import {ENV} from "../../../config/env";
import styles from "./methods.module.scss";
import {CustomerCheckoutButton, Image} from "../../index";
import {Col, Grid, Icon, Message, Row, Radio} from "rsuite";
import VgsFormLoader from "../../checkout-link/vgs-form-loader";
import FieldError from "../../checkout-link/card-form/field-error";

/**
 *
 * @param amount
 * @param currency
 * @param checkoutButtonColor
 * @param publicKey
 * @param methods
 * @param paymentToken
 * @param callback
 * @param beforeSubmit
 * @param isChecked
 * @param handleMethodChange
 * @param testMode
 * @returns {JSX.Element|null}
 * @constructor
 */
function CreditAmexMethod({
                              amount,
                              currency,
                              checkoutButtonColor,
                              publicKey,
                              methods,
                              paymentToken,
                              callback,
                              beforeSubmit,
                              isChecked,
                              handleMethodChange
                              , testMode
                          }) {
    const [formError, setFormError] = useState({})
    const [loadingBtn, setBtnLoading] = useState(false);
    const {t} = useTranslation("pages");
    const [fullAmount, setFullAmount] = useState({});
    const breakpoint = useCanBreakPointSmallMobile();
    const [canSubmit, setCanSubmit] = useState(false)
    const [dibsySDK, setDibsySDK] = useState(null)
    const [formLoading, setFormLoading] = useState(true);
    useEffect(() => {
        setFullAmount(formatAmount(amount));
        if (methods) {
            initDibsy().then()
        }
    }, [])

    if (!methods)
        return null

    const handleCanSubmit = (can) => {
        setCanSubmit(can)
    }
    const submit = (e) => {
        e.preventDefault()
        setBtnLoading(true)
        dibsySDK.submit({
            paymentToken,
            state: (state) => {
                if (state === 'complete') {
                    setBtnLoading(false)
                }
            },
            onClose: () => {
            },
            callback,
            options: {
                issuerContainer: document.querySelector('#checkout-wrapper'),
                disableCloseButton: true,
            },
        })
    }

    async function initDibsy() {
        if (dibsySDK) return
        try {
            await loadjs(ENV.DIBSY_SDK_URL)
            const dibsy = await window.Dibsy(publicKey, {
                options: {
                    disableLoader: true
                },
                onFieldsError: (errors) => {
                    setFormError(errors)
                },
                onFieldReady: (_, fields) => {
                    // since we don't want cardHolder
                    if (fields?.length === 3) {
                        setFormLoading(false)
                    }
                },
                canSubmit: ((canSub) => {
                    handleCanSubmit(canSub)
                })
            })
            const cardNumber = dibsy.createComponent('cardNumber');
            cardNumber.mount('#card-number');

            // const cardHolder = dibsy.createComponent('cardHolder');
            // cardHolder.mount('#card-holder');

            const cardCode = dibsy.createComponent('cardCode');
            cardCode.mount('#card-code');

            const expiryDate = dibsy.createComponent('expiryDate');
            expiryDate.mount('#card-expiry');

            setDibsySDK(dibsy)
        } catch (ex) {
            console.log("Error while loading Dibsy SDK library")
        }
    }

    return <div className={`${styles.paymentMethodTab} ${isChecked && styles.checked}`}>

        <label className={styles.cardTitleText}>
            {
                testMode ? <span className={styles.cardTitleTextHead}>{t("link_checkout.formTitle")}</span> :
                    <Radio onChange={(_, checked) => handleMethodChange(CHARGE_METHOD.CREDIT_CARD)}
                           checked={isChecked}>{t("link_checkout.formTitle")}</Radio>
            }

            <span className={styles.validCards}>
                     {
                         methods[CHARGE_METHOD.CREDIT_CARD] && <>
                             <Image src={"mastercard.svg"} alt="mastercard"/>
                             <Image src={"visa.svg"} alt="visa"/>
                         </>
                     }
                {
                    methods[CHARGE_METHOD.AMEX] && <Image src={"amex.svg"} alt="amex"/>
                }
                </span>
        </label>

        <div className={`${styles.paymentMethodContentCredit} ${isChecked && styles.checked}`}>
            <form>
                <div className={`${styles.vgsForm} ${formLoading && styles.blur}`}>
                    {
                        formLoading && <VgsFormLoader/>
                    }
                    <div className={"mb-3"}>
                        <label>
                            <span style={{
                                opacity: formLoading ? 0 : 1
                            }}>{t("link_checkout.form_card.cardNumberLabel")}</span>
                            <div id="card-number" className={styles.field}/>
                            <FieldError state={formError} label={t("link_checkout.form_card.cardNumberLabel")}
                                        name={"cardNumber"}/>
                        </label>
                    </div>
                    <Grid fluid>
                        <Row>
                            <Col xs={24} sm={12} lg={12} md={12} className={!breakpoint ? "pl-0" : "pl-0 pr-0" +
                                " mb-2"}>
                                <label>
                                    <span style={{
                                        opacity: formLoading ? 0 : 1
                                    }}>{t("link_checkout.form_card.expiryLabel")}</span>
                                    <div id="card-expiry" className={styles.field}/>
                                    <FieldError state={formError} name={"expiryDate"}
                                                label={t("link_checkout.form_card.expiryLabel")}/>
                                </label>
                            </Col>
                            <Col xs={24} sm={12} lg={12} md={12} className={!breakpoint ? "pr-0" : "pl-0 pr-0"}>

                                <label>
                                    <span style={{
                                        opacity: formLoading ? 0 : 1
                                    }}>{t("link_checkout.form_card.cardCodeLabel")}</span>
                                    <div id="card-code" className={styles.field}/>
                                    <FieldError state={formError}
                                                label={t("link_checkout.form_card.cardCodeLabel")}
                                                name={"cardCode"}/>
                                </label>
                            </Col>
                        </Row>
                    </Grid>
                    {/*<div className={"my-3"}>*/}
                    {/*    <label>*/}
                    {/*        <span style={{*/}
                    {/*            opacity: formLoading ? 0 : 1*/}
                    {/*        }}>{t("link_checkout.form_card.cardHolderLabel")}</span>*/}
                    {/*        <div id="card-holder" className={styles.field}/>*/}
                    {/*        <FieldError state={formError} name={"cardHolder"}*/}
                    {/*                    label={t("link_checkout.form_card.cardHolderLabel")}/>*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                    <CustomerCheckoutButton
                        type={"button"}
                        disabled={loadingBtn || !canSubmit}
                        onClick={submit}
                        className={"w-100 mt-3 checkoutBtn"}
                        customColor={checkoutButtonColor}
                    >
                        <Icon className={styles.submitIcon} icon={loadingBtn ? "spinner" : "lock"}
                              pulse={loadingBtn}/>
                        <span className={"mx-2"}>{t("link_checkout.submitBtn")}</span>
                        <span className={"mr-2"}>
                        {currency}
                    </span>
                        <span>{fullAmount.number || 0}{fullAmount.tens ?
                            <span>{fullAmount.tens}</span> : null}</span>
                    </CustomerCheckoutButton>
                </div>
            </form>
        </div>


    </div>
}

export default CreditAmexMethod;