import React from "react";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";

const fadeAnimation = keyframes`${fadeIn}`;

const Annimated = styled.div`
  animation: 0.8s ${fadeAnimation};
`;

function FadedDiv({ className = "", ...props }) {
  return <Annimated className={`${className}`}>{props.children}</Annimated>;
}

export default FadedDiv;
