import React from 'react';
import {Icon, IconButton} from "rsuite";
import styles from "./drawer-closer.module.scss"

const Index = ({onClick,className}) => {
    return (
        <IconButton className={`${className} ${styles.closeDrawerBtn}`} onClick={onClick} icon={<Icon className={styles.iconClose} icon="close"/>}
                    circle/>
    );
};

export default Index;