import i18n from "i18n";
import {Schema} from "rsuite";

const {StringType} = Schema.Types;

const model = Schema.Model({
    current_password: StringType()
    .addRule((value, data) => {
        return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/.test(value);
    }, i18n.t("pages:change_password.schema.invalidPasswd"))
    .isRequired(i18n.t("pages:change_password.schema.requiredCurrentPasswd")),
    new_password: StringType()
        .addRule((value, data) => {
            return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/.test(value);
        }, i18n.t("pages:change_password.schema.invalidPasswd"))
        .isRequired(i18n.t("pages:change_password.schema.requiredNewPasswd")),
    c_password: StringType()
        .addRule((value, data) => {
            if (value !== data.new_password) {
                return false;
            }

            return true;
        }, i18n.t("pages:change_password.schema.passwordNotMatch"))
        .isRequired(i18n.t("pages:change_password.schema.confirmNewPasswd"))
});

export default model