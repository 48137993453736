import {atom, selector} from "recoil"

export const loadingAtom = atom({
    key: "loadingAtom",
    default: {}
})

export const loadingSelector = selector({
    key: "loadingSelector",
    get: ({get}) => {
        return (key) => {
            const loading = get(loadingAtom)
            return loading[key]
        }
    },
    set: ({set}, key) => {
        set(
            loadingAtom,
            {[key]: true}
        )
    }
})
export const unsetLoadingSelector = selector({
    key: "unsetLoadingSelector",
    set: ({set}, key) => {
        set(
            loadingAtom,
            (old) => {
                let oldState = {...old}
                delete oldState[key]
                return {
                    ...oldState
                }
            }
        )
    }
})