import React from 'react';
import styles from "./merchant-info.module.scss";
import {CDN_URL} from "../../../config/constants";
import {Amount} from "../../index";

const MerchantInfo = ({
                          checkoutLogo,
                          amount,
                          currency,
                          name,
                          description,
                          merchant,
                          isFeedbackVisible
                      }) => {

    return (
        <div className={`${styles.checkoutHeader} ${isFeedbackVisible && styles.blur}`}>
            <img src={checkoutLogo ? checkoutLogo : `${CDN_URL}/dashboard/merchant-placeholder.svg`} alt={""}/>
            <div className={styles.merchantInfo}>
                <h1 className={styles.merchantName}>{merchant || "Unnamed business"}</h1>
                <div>
                    <p className={styles.headerTexts}>{name}</p>
                    <p className={styles.headerTexts}>{description}</p>
                </div>
            </div>
            <Amount tensStyle={styles.tensStyle}
                    className={`${styles.checkoutAmount} ${isFeedbackVisible && styles.hideAmount}`} amount={amount}
                    currency={currency}
            />
        </div>
    )

}

export default MerchantInfo;