import React, {useEffect, useState} from 'react';
import styles from "./Tabs.module.scss"

/**
 *
 * @param {[{id:any,icon:any,title:string,icon:JSX.component,component:JSX.Element}]} items
 * @returns {JSX.Element}
 * @constructor
 */
function Tabs({items}) {
    const [tab, setTab] = useState(0);
    useEffect(() => {

    }, [])
    const handleTabChange = (index)=>{
        setTab(index)
    }
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {
                    items.map((e,index) => {
                        return <div
                            key={e.id}
                            onClick={()=>{
                                handleTabChange(index)
                            }}
                            className={`${styles.headerItem} ${tab === index?styles.headerItemActive:""}`}
                        >{e.icon && <e.icon/>}  {e.title}</div>
                    })
                }
            </div>
            <div className={styles.content}>
                {items[tab].component}
            </div>
        </div>
    );
}

export default Tabs;