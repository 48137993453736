import {createContext, useContext, useEffect, useState} from "react";
import {
    BREAKPOINT_WIDTH,
    LARGE_BREAKPOINT_WIDTH,
    SMALL_MOBILE_BREAKPOINT_WIDTH,
    TABLE_BREAKPOINT_WIDTH
} from "../config/constants";

const viewportContext = createContext({});

export const ViewportProvider = ({children}) => {
    // This is the exact same logic that we previously had in our hook

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{width, height}}>
            {children}
        </viewportContext.Provider>
    );
};


export const useViewport = () => {
    const {width, height} = useContext(viewportContext);
    return {width, height};
}
export const useCanBreakPointSmallMobile = () => {
    const {width} = useContext(viewportContext);
    return width < SMALL_MOBILE_BREAKPOINT_WIDTH;
}

export const useCanBreakPointMobile = () => {
    const {width} = useContext(viewportContext);
    return width < BREAKPOINT_WIDTH;
}

export const useCanBreakPointMobileTable = () => {
    const {width} = useContext(viewportContext);
    return width < TABLE_BREAKPOINT_WIDTH;
}

export const useCanBreakPointLarge = () => {
    const {width} = useContext(viewportContext);
    return width < LARGE_BREAKPOINT_WIDTH;
}