import i18n from "../i18n";
import { Routes } from "./routes";

export const PAYMENT_FILTER_STATUSES = [
  {
    label: i18n.t("components:filters.statues.all"),
    value: "all",
    id: "all",
  },
  {
    label: i18n.t("components:filters.statues.succeeded"),
    value: "succeeded",
    id: "successPayments",
  },
  {
    label: i18n.t("components:filters.statues.refunded"),
    value: "refunded",
    id: "refundedPayments",
  },
  {
    label: i18n.t("components:filters.statues.partially_refunded"),
    value: "partially refunded",
    id: "partiallyRefundedPayments",
  },
  {
    label: i18n.t("components:filters.statues.settled"),
    value: "settled",
    id: "settledPayments",
  },
  {
    label: i18n.t("components:filters.statues.failed"),
    value: "failed",
    id: "failedPayments",
  },
  // {
  //   label: i18n.t("components:filters.statues.expired"),
  //   value: "expired",
  //   id: "expiredPayments",
  // },
  {
    label: i18n.t("components:filters.statues.on_hold"),
    value: "on hold",
    id: "onHoldPayments",
  },
  {
    label: "Chargeback",
    value: "chargeBack",
    id: "chargeBackPayments",
  },
];

export const MEMBERS_PERMISSIONS = [
  {
    value: "admin",
    label: "Admin",
    description:
      "Best for directors and company administrators that need full access.",
  },
  {
    value: "developer",
    label: "Developer",
    description:
      "Best for technical employees or agencies that need API access.",
  },
  {
    value: "finance",
    label: "Finance",
    description:
      "Best for employees who need access to payment information and settlments.",
  },
  {
    value: "support",
    label: "Support",
    description: "Best for employees who only need to see payment information.",
  },
  {
    value: "viewer",
    label: "Viewer",
    description: "Best for employees who just need to view payments.",
  },
];

export const LINK_FILTER_STATUS = [
  {
    label: i18n.t("components:filters.statues.all"),
    value: "all",
    id: "all",
  },
  {
    label: "Active",
    value: "active",
    id: "activePaymentLinks",
  },
  {
    label: "Inactive",
    value: "inactive",
    id: "inactivePaymentLinks",
  },
];

export const PAYMENT_FILTER_METHODS = [
  {
    label: i18n.t("components:filters.methods.creditcard"),
    value: "creditcard",
  },
  {
    label: "NAPS",
    value: "naps",
  },
  {
    label: "Apple Pay",
    value: "applepay",
  },
  {
    label: "Google Pay",
    value: "googlepay",
  },
  {
    label: "American Express",
    value: "amex",
  },
];

export const CUSTOMERS_FILTER_BLACKLIST = [
  {
    label: i18n.t("components:filters.blacklist"),
    value: false,
  },
];

export const LINK_DURATION = [
  {
    label: i18n.t("pages:links.create_link_drawer.validity_date_items.days"),
    value: "days",
  },
  {
    label: i18n.t("pages:links.create_link_drawer.validity_date_items.weeks"),
    value: "weeks",
  },
];

export const FILTER_PERIOD = [
  {
    label: i18n.t("components:filters.periods.anytime"),
    value: "anytime",
  },
  {
    label: i18n.t("components:filters.periods.today"),
    value: "today",
  },
  {
    label: i18n.t("components:filters.periods.yesterday"),
    value: "yesterday",
  },
  {
    label: i18n.t("components:filters.periods.week"),
    value: "week",
  },
  {
    label: i18n.t("components:filters.periods.month"),
    value: "month",
  },
  {
    label: i18n.t("components:filters.periods.custom"),
    value: "custom",
  },
];

export const CREATE_LINK_VALIDITY = {
  today: 1,
  tomorrow: 2,
  custom: 3,
};

export const TEST_CARD_TYPE = {
  success: "success",
  failed: "failed",
  authentication: "authentication",
};

export const STATUSES = {
  EXPIRED: "expired",
  PAID: "paid",
  FAILED: "failed",
  SUCCEEDED: "succeeded",
  SETTLED: "settled",
  REFUNDED: "refunded",
  ON_HOLD: "on hold",
  OPEN: "open",
  PARTIALLY_REFUNDED: "partially refunded",
};

export const LINK_TYPE = {
  OPEN: "Open",
  SINGLE_USE: "Single use",
};

export const ONBOARDING_STATUS = {
  //PENDING: "pending",
  NEEDS_DATA: "needs-data",
  IN_REVIEW: "in-review",
  COMPLETED: "completed",
};

export const ONBOARDING_DOCS = {
  QID: "qid",
  BANK_STATEMENT: "bank_statement",
  OTHER_DOCS: "other_docs",
  COMMERCIAL_REGISTRATION: "commercial_registration",
};

export const INSIGHTS_TYPES = {
  HOURS: "HOURS",
  DAYS: "DAYS",
};

export const CARD_TYPE = {
  CREDIT_CARD: "creditcard",
  DEBIT_CARD: "debitcard",
  TEST_CARD: "testcard",
};

export const RESET_TYPE = {
  PUBLIC: "public",
  SECRET: "secret",
};

export const COGNITO_EXCEPTIONS = {
  NEW_PASSWORD_REQUIRED: "newPasswordRequiredException",
  RESET_PASSWORD_REQUIRED: "PasswordResetRequiredException",
  MFA_REQUIRED: "SOFTWARE_TOKEN_MFA",
};

export const interfaces = {
  onboarding: Routes.OBHome,
  payments: Routes.PYHome,
  customers: Routes.Customers,
  balance: Routes.Balance,
  team: Routes.Team,
  settings: Routes.Settings,
};

export const permessions = {
  onboarding: {
    access: ["owner", "admin"],
    manage: ["owner", "admin"],
  },

  payments: {
    access: ["owner", "admin", "developer", "finance", "support", "viewer"],
    manage: ["owner", "admin", "finance", "developer"],
  },
  links: {
    access: ["owner", "admin", "developer", "finance", "support", "viewer"],
    manage: ["owner", "admin", "developer", "finance", "support"],
  },
  team: {
    access: ["owner", "admin", "viewer"],
    manage: ["owner", "admin"],
  },
  customers: {
    access: ["owner", "admin", "developer", "finance", "support", "viewer"],
    manage: ["owner", "admin"],
  },
  balance: {
    access: ["owner", "admin", "finance", "viewer"],
    manage: ["owner", "admin", "finance"],
  },
  settings: {
    access: ["owner", "admin", "developer", "finance", "support", "viewer"],
    manage: ["owner", "admin", "developer", "finance", "support", "viewer"],
  },
  settings_api: {
    access: ["owner", "admin", "developer", "viewer"],
    manage: ["owner", "admin", "developer"],
  },
  settings_methods: {
    access: ["owner", "admin", "finance", "developer", "viewer"],
    manage: ["owner", "admin", "developer"],
  },
  settings_checkout: {
    access: ["owner", "admin", "developer", "viewer"],
    manage: ["owner", "admin", "developer"],
  },
};
