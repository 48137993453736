import React, { useRef } from "react";
import styles from "./SectionWrapper.module.scss";

/**
 *
 * @param {string} title
 * @param {string} description
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
function SectionWrapper({ title, description, children, className }) {
  const ref = useRef(null);
  return (
    <div className={`"w-100" ${className}`}>
      <h1>{title}</h1>
      <p className={styles.distance}>{description}</p>

      <div ref={ref} className="w-100 mt-3">
        {children}
      </div>
    </div>
  );
}

export { SectionWrapper };
