import React from 'react';
import styles from "./card.module.scss";

const Index = ({children,className}) => {
    return (
        <div className={`${className} ${styles.card}`}>
            {children}
        </div>
    );
};

export default Index;