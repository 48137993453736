import { Schema } from "rsuite";
import i18n from "../i18n";
import { isValidPhoneNumber } from "libphonenumber-js";

const { StringType, NumberType, BooleanType, DateType } = Schema.Types;

const model = Schema.Model({
  name: StringType()
    .isRequired("Payment link name is required.")
    .maxLength(100, "The field cannot be greater than 100 characters"),
  expiresAt: DateType().isRequired(i18n.t("validation:linkValidityRequired")),
  validity_duration: NumberType().addRule((val, data) => {
    return val > 0;
  }, i18n.t("validation:fieldNegative")),
  reusable: BooleanType().isRequired(i18n.t("validation:linkTypeRequired")),
  description: StringType(),
  redirectUrl: StringType().isURL("Redirect url is invalid."),
  webhookUrl: StringType().isURL("Webhook url is invalid."),
  phone: StringType().addRule((value) => {
    if (value.length < 5) {
      return true;
    } else {
      return isValidPhoneNumber(value);
    }
  }, i18n.t("validation:phoneNotValid")),
});
export default model;
