import {atom} from "recoil";


export const APIsAtom = atom({
    key: "APIsAtom",
    default:
        {
            publicKey: "",
            secretKey: "",
        }
})


export const APIsResetModalAtom = atom({
    key: "APIsResetModalAtom",
    default:
        {
            visible: false,
        }
})