import React from 'react';
import styles from "./TestModeToggle.module.scss"


function TestModeToggle({
                            value,
                            loading,
                            onChange = () => {
                            }
                        }) {
    React.useEffect(() => {

    }, [value])

    return <span className={styles.container}>
        <input type={"checkbox"}/>
        <span className={`${styles.slider} ${value ? styles.sliderChecked : ''}`}/>
    </span>
}

export default TestModeToggle;