import { Schema } from "rsuite";
import { isValidPhoneNumber } from "libphonenumber-js";
import i18n from "i18n";

const { StringType } = Schema.Types;

const GeneralSettingsModel = Schema.Model({
  firstName: StringType()
    .isRequired(i18n.t("Your first name is required."))
    .pattern(
      /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
      "Your first name is invalid."
    ),
  lastName: StringType()
    .isRequired(i18n.t("validation:lastNameRequired"))
    .pattern(
      /^(?=.{1,50}$)[a-z]+(?:['-_.\s][a-z]+)*$/i,
      "Your last name is invalid."
    ),
  phone: StringType()
    .isRequired(i18n.t("validation:phoneRequired"))
    .addRule((value) => {
      return isValidPhoneNumber(value);
    }, i18n.t("validation:phoneNotValid")),
});

export default GeneralSettingsModel;
