import { Schema } from "rsuite";
import i18n from "../i18n";

const { NumberType } = Schema.Types;

const model = Schema.Model({
  amount: NumberType()
    .isRequired(i18n.t("validation:amountRequired"))
    .min(0, i18n.t("validation:amountNegative"))
    .max(1000000, i18n.t("validation:amountMax", { max: 1000000 })),
});
export default model;
