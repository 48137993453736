import React, {useState} from 'react';
import {Icon, Radio} from "rsuite";
import {useTranslation} from "react-i18next";
import styles from "./methods.module.scss";
import {CHARGE_METHOD} from "../../../config/constants";
import {CustomerCheckoutButton, Image} from "../../index";
import PayscreenService from "../../../services/PayscreenService";

function DebitCardMethod({isChecked, paymentToken, publicKey, handleMethodChange, checkoutButtonColor}) {
    const {t} = useTranslation("pages");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const payscreenService = PayscreenService()
    const submit = (e) => {
        e.preventDefault()
        setLoadingBtn(true);
        payscreenService.debitCardPayment(publicKey, paymentToken)
            .then(res => {
                if (res.data?.debitcard) {
                    window.location.href = res.data?.debitcard
                } else {
                    setLoadingBtn(false)
                }
            })

    }
    return (
        <div className={`${styles.paymentMethodTab} ${isChecked && styles.checked}`}>
            <label className={styles.cardTitleText}>
                <span onClick={e => handleMethodChange(CHARGE_METHOD.DEBIT_CARD)}>
                    <Radio onChange={(_, e) => e.preventDefault()} checked={isChecked}
                           className={styles.cardTitleText}>
                    {t("link_checkout.debit_card_title")}
                </Radio>
                </span>
                <span className={styles.validCards}>
                      <Image src={"naps.svg"} alt="naps"/>
                </span>
            </label>

            <div className={`${styles.paymentMethodContentDebit} ${isChecked && styles.checked}`}>
                <CustomerCheckoutButton
                    type={"button"}
                    disabled={loadingBtn}
                    onClick={submit}
                    className={"w-100 checkoutBtn"}
                    customColor={checkoutButtonColor}
                >
                    {loadingBtn && <Icon className={styles.submitIcon} icon={"spinner"}
                                         pulse={true}/>}
                    <span className={"pl-2"}>{t("link_checkout.submitBtnDebit")}</span>
                </CustomerCheckoutButton>
            </div>
        </div>
    );
}

export default DebitCardMethod;