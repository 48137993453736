import React, { useEffect } from "react";
import HeaderItem from "./HeaderItem";
import { EmptyTable, Paginator } from "../index";
import styles from "./datatable.module.scss";
import { Checkbox, Icon, Loader } from "rsuite";
import paginationLengths from "assets/data/pagination_lengths.json";
import { useTranslation } from "react-i18next";

const Index = ({
  columns,
  data,
  className,
  emptyTableText,
  rowClick,
  loading,
  pagination = true,
  handlePageChange,
  handlePerPageChange,
  activePage,
  total,
  count,
  checkAll = false,
  ...props
}) => {
  const { t } = useTranslation("components");

  useEffect(() => {}, []);

  return (
    <>
      <table className={`${styles.table} ${className}`}>
        <thead>
          <tr className={styles.tableHeader}>
            {columns?.map((col, index) => {
              if (col?.isCheckbox) {
                return (
                  <th key={index} width={col?.width ? col?.width : undefined}>
                    <Checkbox
                      inline
                      checked={checkAll}
                      indeterminate={false}
                      onChange={(val, checked, e) =>
                        col?.handleCheckAll(val, checked)
                      }
                    />
                  </th>
                );
              }
              return (
                <HeaderItem
                  width={col?.width ? col?.width : undefined}
                  className={styles.tableCol}
                  key={index}
                  title={col?.title}
                />
              );
            })}
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <td colSpan={columns?.length}>
                <div className={"text-center"}>
                  <Loader
                    size="md"
                    className={styles.loading}
                    content={t("loader.content")}
                    vertical
                  />
                </div>
              </td>
            </tr>
          ) : (
            [
              data?.length > 0 ? (
                data.map((item, index) => (
                  <tr
                    className={`${styles.tableRow} ${rowClick && "pointer"}`}
                    key={index}
                    onClick={(e) => rowClick && rowClick(item)}
                  >
                    {columns.map((col, index) => {
                      if (col?.render) {
                        return (
                          <td
                            key={index}
                            className={`${styles.tableCol} ${
                              col?.className ? col?.className : ""
                            }`}
                          >
                            {col.render(item)}
                          </td>
                        );
                      }
                      return (
                        <td
                          key={index}
                          className={`${styles.tableCol} ${
                            col?.className ? col?.className : ""
                          }`}
                        >
                          {item[col?.dataIndex]}
                        </td>
                      );
                    })}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns?.length}>
                    <EmptyTable text={emptyTableText || "no data found"} />
                  </td>
                </tr>
              ),
            ]
          )}
        </tbody>
      </table>
      {pagination && !loading && (
        <Paginator
          className={styles.pagination}
          lengthMenu={paginationLengths}
          activePage={activePage}
          count={count}
          total={total}
          handleChangePage={handlePageChange}
          handleChangeLength={handlePerPageChange}
        />
      )}
    </>
  );
};

export default Index;
