export const ENV = {
  CDN_URL: process.env.REACT_APP_CDN_URL,
  APP_NAME: process.env.REACT_APP_APP_NAME || "Dibsy",
  API_URI:
    process.env.REACT_APP_API_URL || "http://localhost:5000/api-internal",
  NEXT_PAYOUT_FREQUENCY: process.env.REACT_APP_NEXT_PAYOUT_FREQUENCY,
  APP_IS_DEV: process.env.NODE_ENV === "development",
  AWS_LAMBDA_URL: process.env.REACT_APP_AWS_LAMBDA_URL,
  //
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  USER_POOL_REGION: process.env.REACT_APP_USER_POOL_REGION,

  PAYLINK_PREFIX: process.env.REACT_APP_PAYLINK_PREFIX,
  GOOGLE_CAPTCHA_KEY: process.env.REACT_APP_GOOGLE_CAPTCHA_KEY,

  //

  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
};
