import React, { useEffect, useRef } from "react";
import { FormField, PhoneInput, FormErrorMessage } from "components";
import { Form, FormGroup, ControlLabel } from "rsuite";
import { CreateCustomerSchema } from "schemas";
import { useTranslation } from "react-i18next";

function newCustomerForm({
  oldCustomer = false,
  formValue,
  handleChange,
  formErrors,
  disabled,
}) {
  const { t } = useTranslation("pages");
  const form = useRef(null);

  const handleFormChange = (_, element) => {
    if (!element) return;
    const { name, value } = element.target;
    handleChange(name, value);
  };


  return (
    <div className="mt-4">
      <Form
        ref={form}
        checkTrigger={"blur"}
        onChange={handleFormChange}
        formValue={formValue}
        formError={formErrors}
        model={CreateCustomerSchema}
        fluid
      >
        <FormField
          disabled={disabled}
          label={"Name"}
          error={formErrors?.name}
          placeholder={t("John Doe")}
          name={"name"}
        />
        <FormField
          disabled={disabled}
          label={"Email"}
          error={formErrors?.email}
          placeholder={t("john.doe@gmail.com")}
          name={"email"}
        />
        <FormGroup>
          <ControlLabel>{"Phone Number"}</ControlLabel>
          <PhoneInput
            disabled={disabled}
            className={formErrors?.phone && "input-error"}
            name="phone"
            onChange={(val) => {
              handleChange("phone", val);
            }}
          />
          <FormErrorMessage error={formErrors?.phone} />
        </FormGroup>
      </Form>
    </div>
  );
}

export default newCustomerForm;
