import i18n from "i18n";
import { Schema } from "rsuite";

const { StringType } = Schema.Types;

const model = Schema.Model({
  password: StringType()
    .addRule((value, data) => {
      return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/.test(
        value
      );
    }, i18n.t("pages:reset_password.schema.invalidPasswd"))
    .isRequired(i18n.t("pages:reset_password.schema.requiredNewPasswd")),
  c_password: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }

      return true;
    }, i18n.t("pages:reset_password.schema.passwordNotMatch"))
    .isRequired(i18n.t("pages:reset_password.schema.confirmNewPasswd")),
});

export default model;
