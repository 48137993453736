import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {checkoutLinkDataAtom, customerFormAtom, feedbackContentAtom} from "store/checkoutRecoil";
import {useCheckoutService} from "services/CheckoutService";
import CheckoutForm from "../../checkout-form";
import {omit} from "lodash";

const Index = ({...props}) => {
    const [paymentToken, setPaymentToken] = useState(null)
    const [customer, updateCustomerFormState] = useRecoilState(customerFormAtom);
    const {t} = useTranslation("pages");
    const linkData = useRecoilValue(checkoutLinkDataAtom);
    const [serverResponse, setServerResponse] = useState({});
    const {link, checkoutButtonColor, methods} = linkData;
    const updateFeedbackContent = useSetRecoilState(feedbackContentAtom);
    const checkoutService = useCheckoutService()
    useEffect(async () => {
        try {
            const {data, success} = await checkoutService.createPaymentForPaymentLink(link.uid, omit(customer, ["isVisible", "isEditVisible"]))
            if (success && data) {
                setPaymentToken(data)
            }
        } catch (ex) {
        }
    }, [])

    const callback = (payment, error) => {
        if (error) {
            setServerResponse(error)
        } else {
            if (link?.redirectUrl) {
                window.location.href = link.redirectUrl;
            } else {
                updateFeedbackContent({
                    isVisible: true,
                    feedbackText: t("link_checkout.feedbacks.success_payment_text"),
                    success: true,
                    feedbackTitle: t("link_checkout.feedbacks.link_success_title")
                })
            }
        }
    }
    const beforeSubmitHandler = async (e) => {
        updateCustomerFormState({...customer, isEditVisible: false})
    }

    return (
        <CheckoutForm
            checkoutButtonColor={checkoutButtonColor}
            amount={link?.amount}
            label={link?.merchant}
            linkUID={link?.uid}
            paymentToken={paymentToken}
            beforeSubmit={beforeSubmitHandler}
            publicKey={link?.pkey}
            currency={link?.currency}
            serverResponse={serverResponse}
            callback={callback}
            methods={methods}
            testMode={link?.testMode}
            setServerResponse={setServerResponse}
        />
    );
}

export default Index;