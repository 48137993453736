import * as React from "react";

function SvgLogoutIcon(props) {
  return (
    <svg
      id="logout_icon_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 41 41.5"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    >
      <style>{".logout_icon_svg__st0{fill:#fff}"}</style>
      <path
        className="logout_icon_svg__st0"
        d="M20.5 40.7C9.6 40.7.8 31.8.8 21c0-5.3 2.2-10.5 6-14.2.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4-7.1 6.8-7.3 18-.5 25.1s18 7.3 25.1.5 7.3-18 .5-25.1l-.5-.5c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0 7.8 7.5 8.1 20 .6 27.8-3.8 3.9-9 6.1-14.3 6.1z"
      />
      <path
        className="logout_icon_svg__st0"
        d="M20.5 22.6c-.5 0-1-.4-1-1V1.7c0-.5.4-1 1-1 .5 0 1 .4 1 1v19.9c-.1.6-.5 1-1 1z"
      />
    </svg>
  );
}

export default SvgLogoutIcon;
