import React from 'react';
import styles from "./datatable.module.scss"

const HeaderItem = ({title, className, width, ...props}) => {
    return (
        <th width={width ? width : undefined} className={`${className} ${styles.tableHeader}`} {...props}>
            {title}
        </th>
    );
};

export default HeaderItem;