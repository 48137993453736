import React from 'react';
import {ellipsisText} from "utils/helpers";
import {Button, FlexboxGrid, Icon, Popover, Tooltip, Whisper} from "rsuite";
import {useTranslation} from "react-i18next";
import styles from "./link-cell.module.scss";
import SvgUnauthorizeIcon from "assets/icons/UnauthorizeIcon";
import {useCanBreakPointMobile} from "../../../hooks/useViewport";


const Index = ({link,linkName, linkUid, ellipsislength, singleUsePaid, isCopied, copyText, destroyLink, onClick, isExpired}) => {
        const {t} = useTranslation("pages");
        const triggerRef = React.createRef();
        const openConfirm = () => triggerRef.current.open();
        const closeConfirm = () => triggerRef.current.close();

        const breakPoint = useCanBreakPointMobile()

        const handleTextCopy = (text) => {
            if (copyText)
                copyText(text)
        }

        const share = () => {
            navigator?.share({
                title: t("link_details.summary.shareLinkTitle"),
                url: link,
                text: linkName
            })
        }

        const ConfirmDestroy = (
            <Popover className={styles.destroyPopover}>
                <p className={"mb-3"}>{t("link_details.summary.confirmDestroyLinkText")}</p>
                <FlexboxGrid align={"middle"} justify={"end"}>
                    <Button appearance="link"
                            onClick={closeConfirm}>{t("link_details.summary.confirmDestroyLinkNo")}</Button>
                    <Button className={"text-danger"} appearance="link" onClick={e => {
                        closeConfirm();
                        destroyLink()
                    }}
                    >{t("link_details.summary.confirmDestroyLinkYes")}
                    </Button>
                </FlexboxGrid>
            </Popover>
        )

        return (
            <div className={styles.linkCell}>
                <a href={link} rel={"noreferrer"} target={"_blank"} onClick={onClick}
                   className={styles.link}>{ellipsislength ? ellipsisText(link, ellipsislength) : linkUid}</a>
                <Whisper trigger="click" placement={"top"}
                         speaker={<Tooltip visible={isCopied}>{t("links.tableLinkCopied")}</Tooltip>}>
                    <Icon onClick={e => handleTextCopy(e, link)} className={styles.copyIcon} icon={"copy"}/>
                </Whisper>
                {
                    destroyLink && !isExpired && !singleUsePaid ?
                        <Whisper
                            title={t("link_details.summary.confirmDestroyLinkTitle")}
                            trigger="none"
                            ref={triggerRef}
                            placement={!breakPoint ? "topRight" : "bottomEnd"}
                            speaker={ConfirmDestroy}>
                            <SvgUnauthorizeIcon onClick={openConfirm} className={styles.destroyIcon}/>
                        </Whisper> : null
                }
                {
                    breakPoint &&
                    <Icon icon={"share"} onClick={share} className={"ml-2 pointer"}/>
                }
            </div>
        );
    }
;

export default Index;