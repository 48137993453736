import React from 'react';
import styles from "./customer-info.module.scss"
import {useRecoilState,} from "recoil";
import {customerFormAtom} from "store/checkoutRecoil";
import {useTranslation} from "react-i18next";
import {Image} from "../../index";
import {Icon} from "rsuite";

const Index = () => {

    const [customer, setCustomer] = useRecoilState(customerFormAtom)
    const {t} = useTranslation("pages");

    const editClick = () => {
        setCustomer({...customer, isVisible: true})
    }

    return (
        <>
            <p className={styles.personalDetails}>{t("link_checkout.personal_details").toUpperCase()} {!customer.isVisible &&
            <Icon className={styles.personalDetailsIcon} icon={"check"}/>} </p>
            <div className={styles.customerInfo}>
                <div>
                    <p className={"mb-2"}>{customer?.name}</p>
                    <p>{customer?.email && `${customer?.email} /`}  {customer?.phone}</p>
                </div>
                <span onClick={editClick} className={styles.editBtn}>
                {t("link_checkout.editBtn")}
            </span>
            </div>
        </>
    );
};

export default Index;