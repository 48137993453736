import React, {useEffect, useRef, useState} from 'react';
import styles from "./SettingsContentWrapper.module.scss"
import styled from "styled-components";
import {DashboardNav} from "components";
import {BREAKPOINT_WIDTH} from "config/constants";
import Helmet from "react-helmet";

import SettingsHeaderContext from "context/SettingsHeaderContext"
import {useLocation} from "react-router";
import {useTranslation} from "react-i18next";
import {sideBarAtom} from "../../store/sideBarRecoil";
import {useRecoilState} from "recoil";

const ContentContainer = styled.div`
  display: flex;
  margin-top: 20px;
  @media screen and (max-width: ${BREAKPOINT_WIDTH - 1}px) {
    flex-direction: ${(props) => props.reverse ? "column" : "column-reverse"};
    margin-top: 0;
  }
`

/**
 *
 * @param pageTitle
 * @param showHeaderTitle
 * @param reverse
 * @param showTestMode
 * @param children
 * @returns {JSX.Element[]}
 * @constructor
 */
function SettingsContentWrapper({
                                    title: pageTitle,
                                    showHeaderTitle = false,
                                    reverse = false,
                                    children,
                                    docTitle = ""
                                }) {

    const [sectionTitle, setSectionTitle] = useState(pageTitle);
    const [subtitle, setSubtitle] = useState(null);
    const [showTestModeToggle, setShowTestModeToggle] = useState(true);
    const location = useLocation()
    const {t} = useTranslation("pages");
    const ref = useRef(null);


    const [sideBar, setSideBar] = useRecoilState(sideBarAtom);


    useEffect(() => {
        setSideBar(old => ({
            ...old,
            subtitle,
            title: sectionTitle,
            showTestModeToggle,
            showHeaderTitle
        }))
    }, [subtitle, sectionTitle, showTestModeToggle, showHeaderTitle]);


    const changeTitle = (t) => {
        setSectionTitle(t || pageTitle)
    }
    useEffect(() => {

    }, [location])

    const changeSubTitle = (val) => {
        setSubtitle(val)
    }

    const getTitle = () => {
        return `${docTitle ? docTitle : pageTitle} - ${t("common.pageTitleSuffix")}`
    }
    return [
        <Helmet>
            <title>{getTitle()}</title>
        </Helmet>,
        <SettingsHeaderContext.Provider value={{
            title: sectionTitle,
            changeTitle,
            subtitle,
            setSubtitle: changeSubTitle,
            showTestModeToggle,
            setShowTestModeToggle,
            showHeaderTitle
        }}>
            <div className={styles.container}>
                <DashboardNav
                    showTestModeToggle={showTestModeToggle}
                    subTitle={subtitle}
                />
                <ContentContainer ref={ref} reverse={reverse}>
                    {children}
                </ContentContainer>
            </div>
        </SettingsHeaderContext.Provider>

    ];
}

export default SettingsContentWrapper;