import { Schema } from "rsuite";
const { StringType } = Schema.Types;

const model = Schema.Model({
  customerId: StringType()
    .isRequired("Customer ID is required")
    .addRule((value) => {
      return value?.startsWith("ct_");
    }, "please insert a valid customer ID"),
});
export default model;
