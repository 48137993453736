import React, {useEffect, useState} from 'react';
import {FlexboxGrid, SelectPicker} from "rsuite";
import styles from "./paginator.module.scss";
import {pagination} from "utils/helpers";
import {useTranslation} from "react-i18next";


const Index = ({
                   className,
                   handleChangeLength,
                   handleChangePage,
                   lengthMenu,
                   activePage,
                   count,
                   total,
                   settingsAtom,
                   ...props
               }) => {
    let [paginationWithElipsiss, setPagination] = useState([]);
    const {t} = useTranslation("components");

    useEffect(() => {
        setPagination(pagination(activePage, Math.ceil(total / count)));
    }, [activePage, count, total])

    return (
        <div className={`${className} ${!total && styles.hide_paginator}  ${styles.paginator}`}>
            <FlexboxGrid align={"middle"} justify={"space-between"}>
                <div className={styles.paginator_text_wrapper}>
                    {/* <span>{t("paginator.showingTo", {nbRows: (activePage - 1) * count + 1})}</span>
                    <SelectPicker
                        placement={"topStart"}
                        onChange={handleChangeLength}
                        value={count}
                        cleanable={false}
                        data={lengthMenu}
                        searchable={false}
                        className={'lengthsDropdown'}

                    />
                    <span> {t("paginator.entries", {total})} </span> */}
                </div>
                <div className={`${styles.paginator_text_wrapper} ${styles.pagination}`}>
                    <span onClick={activePage !== 1 ? e => handleChangePage(activePage - 1) : null}
                          className={`${styles.prev} ${activePage === 1 && styles.disabled}`}>{t("paginator.prev")} </span>
                    {
                        paginationWithElipsiss.map((item,index) => {
                            if (item !== "..." && item !== activePage) {
                                return <span key={index} onClick={e => handleChangePage(item)}
                                             className={styles.paginator_number}>{item}</span>
                            } else {
                                return <span key={index}
                                    className={`${styles.paginator_number} ${item === activePage && styles.active}`}>{item}</span>
                            }
                        })
                    }

                    <span
                        onClick={activePage !== (Math.ceil(total / count)) ? e => handleChangePage(activePage + 1) : null}
                        className={`${styles.next} ${activePage === (Math.ceil(total / count)) && styles.disabled}`}>{t("paginator.next")}</span>
                </div>
            </FlexboxGrid>
        </div>
    );
};

export default Index;