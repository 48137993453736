import React, { Fragment } from "react";
import { Col, Row } from "rsuite";

const AuthPagesLayout = ({ className, formComponent, children, ...props }) => {
  return (
    <Fragment>
      <div className={`guest-page ${className}`}>
        <Row>
          <Col
            className={"v-center form-section"}
            xs={24}
            md={24}
            lg={12}
            xl={12}
          >
            {formComponent || children}
          </Col>
          <Col
            className={"cover-section d-none d-xl-flex"}
            xs={24}
            md={24}
            lg={12}
            xl={12}
          ></Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AuthPagesLayout;
