import { useAPI } from "../hooks/useAPI";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "store/organizationRecoil";
import { settingsAtom } from "../store/settingsRecoil";
import { stringify as QSStringify } from "qs";
import { filterNonNullForObject } from "utils/helpers";
import {
  customersTableSettingsAtom,
  customerPaymentsTableSettingsAtom,
} from "../store/customersRecoil";

function useCustomersService() {
  const { call, ...rest } = useAPI();
  const { page, count } = useRecoilValue(customersTableSettingsAtom);
  const orgInformation = useRecoilValue(organizationAtom);
  const { testMode } = useRecoilValue(settingsAtom);
  const { page: _page, count: _count } = useRecoilValue(
    customerPaymentsTableSettingsAtom
  );
  return {
    fetch_data: (query) => {
      let dataToSend = QSStringify(
        filterNonNullForObject({
          testMode,
          organizationId: orgInformation?.id,
          page: query ? null : page,
          count: query ? null : count,
          query: query ? null : query,
        })
      );
      return call({
        link: `/search/customers?${dataToSend}`,
      });
    },
    fetch_customer: (id) => {
      let dataToSend = QSStringify(
        filterNonNullForObject({
          testMode,
          organizationId: orgInformation?.id,
        })
      );
      return call({
        link: `/search/customers/${id}?${dataToSend}`,
      });
    },
    customer_transactions: (customerId) => {
      let dataToSend = QSStringify(
        filterNonNullForObject({
          testMode,
          organizationId: orgInformation?.id,
          customerId: customerId,
          page: _page,
          count: _count,
        })
      );
      return call({
        link: `/search/payments?${dataToSend}`,
      });
    },
    delete_customer: (id) => {
      let dataToSend = QSStringify(
        filterNonNullForObject({
          testMode,
          organizationId: orgInformation?.id,
        })
      );
      return call({
        link: `/customers/${id}?${dataToSend}`,
        method: "DELETE"
      });
    },
    ...rest,
  };
}

export { useCustomersService };
