import React from "react";
import { DateRangePicker, InputGroup } from "rsuite";
import { Locale } from "../../utils/helpers";
import "./style.scss";

const Index = ({ name, value, onChange, className, ...props }) => {
  return (
    <div className={`${className} calendar`}>
      <InputGroup>
        <DateRangePicker
          Locale={Locale}
          cleanable={false}
          format="DD.MM.YYYY"
          ranges={[]}
          value={value}
          name={name}
          onChange={(dates) => onChange(dates)}
          showOneCalendar
          placement={"topEnd"}
          disabledDate={(date) => date > new Date()}
        />
      </InputGroup>
    </div>
  );
};

export default Index;
