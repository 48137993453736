import { atom, selector } from "recoil";
import { CREATE_LINK_TYPE } from "../utils/enums";
import { getEndTodayTimeStamp } from "utils/helpers";
import { userAtom } from "./userRecoil";

const derivedFullName = selector({
  key: "derivedFullName",
  get: ({ get }) => {
    const user = get(userAtom);
    return `${user?.firstName} ${user?.lastName}`;
  },
});

export const linksFilterAtom = atom({
  key: "linksFilterAtom",
  default: {
    isCustomPeriod: false,
    from: "",
    to: "",
    status: ["all"],
    period: "anytime",
    search: "",
    custom_period: [],
  },
});

export const statusLinksAtom = atom({
  key: "statusLinksAtom",
  default: {
    all: 0,
    active: 0,
    inactive: 0,
  },
});

export const linksTableSettingsAtom = atom({
  key: "linksTableSettingsAtom",
  default: {
    count: 10,
    page: 1,
    reload: false,
    totalCounts: 0,
    loading: false,
    showMobileFilters: false,
    showCreateLinkDrawer: false,
    show_more_fields: false,
  },
});

export const linksTableDataAtom = atom({
  key: "linksTableDataAtom",
});

export const linksCacheAtom = atom({
  key: "linksCacheAtom",
});

export const createPaymentLinkAtom = atom({
  key: "createPaymentLinkAtom",
  default: {
    expiresAt: getEndTodayTimeStamp(),
    toggleCustomer: false,
    toggleAddDetails: false,
    toggleRedirection: false,
    toggleMetaData: false,
    isCustomLinkValidity: false,
    currency: "QAR",
    validity_date: "days", // this is required
    reusable: false, // we keep this as default value
    //notifyWithSms: false, // we keep this as default value
    // custom fields must contain one row at least don't delete this
    webhookUrl: "",
    redirectUrl: "",
    custom_fields: [],
    oldCustomer: {},
    showAddCustomerModal: false,
    showAddFieldModal: false,
    phone: "",
    metaData: {
      user: "",
    },
    dynamicAmount: false,
  },
});

export const paymentLinkDetailsAtom = atom({
  key: "paymentLinkDetailsAtom",
  default: {
    customer_details: {
      name: "",
      phone: "",
      email: "",
    },
    payment_link: "",
    name: "",
    canResendSms: false,
    createdAt: "",
    status: "",
    amount: "",
    currency: "",
    total_payments: 0,
    metadata: {},
  },
});

export const reloadLinkDetailsAtom = atom({
  key: "reloadLinkDetailsAtom",
  default: {
    reload: false,
  },
});

export const fieldsAtom = atom({
  key: "fieldsAtom",
  default: {
    form: [],
  },
});
