import React, { useEffect } from "react";
import styles from "./liste-table.module.scss";
const Index = ({ data }) => {
  useEffect(() => {}, [data]);

  return (
    <>
      <table>
        <tbody>
          {Object.keys(data).map((index) => {
            return (
              <tr>
                <td>
                  <span className={styles.title}>{index}</span>
                </td>
                <td>
                  <span>:</span>
                </td>
                <td className={styles.listData}>
                  <p className={styles.content}>
                    {data[index] ? data[index] : "-"}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Index;
