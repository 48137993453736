import * as React from "react";

function SvgBalanceIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.988 4.024l2.31-2.31.07-.07-.07-.071-.644-.644-.07-.07-.071.07L.929 4.513l-.07.071.07.07.004.004h0l3.584 3.586.07.07.071-.07.644-.644.07-.07-.07-.071-2.325-2.325h9.459v-1.11H2.988zM17.328 6.953a2.375 2.375 0 100-4.75 2.375 2.375 0 000 4.75zM8.691 16.963h9.332L15.7 19.287l-.07.07.07.071.643.644.07.07.072-.07 3.579-3.58h0l.087-.07-.079-.08-3.583-3.584-.07-.07-.071.07-.644.644-.07.07.07.071 2.31 2.31H8.59v1.11h.1zM3.678 18.782a2.375 2.375 0 110-4.75 2.375 2.375 0 010 4.75z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgBalanceIcon;
