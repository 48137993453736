import React, {useEffect} from 'react';
import {Loader} from 'rsuite';
import {authAtom} from "store/authRecoil";
import {useRecoilValue} from "recoil";
import styles from "./Loader.module.scss"
import {useTranslation} from "react-i18next";

const Index = (props) => {
    const auth = useRecoilValue(authAtom)
    const {t}=useTranslation("components");

    useEffect(() => {

    }, [])
    return (
        <Loader
            className={auth?.token ? styles.loadingAuth : ""}
            center
            size={"md"}
            content={t("loader.content")}
            vertical
            {...props}
        />
    );
};

export default Index;