import React, {Fragment} from 'react';
import styles from "./checkout-form.module.scss"
import {
    CardForm,
    CheckoutFooter,
    CustomerForm,
    CustomerInfo,
    FakeCards,
    MerchantInfoHeader
} from "components";
import {useRecoilValue} from "recoil";
import {checkoutLinkDataAtom, customerFormAtom, feedbackContentAtom} from "store/checkoutRecoil";
import FeedbackContent from "./../feedback-content";
import ApplePayModal from "../../checkout-form/apple-pay-modal";

const Index = () => {

    const {link} = useRecoilValue(checkoutLinkDataAtom);
    const {isVisible: isFeedback} = useRecoilValue(feedbackContentAtom);
    const {isVisible: isCustomerDataExist, isEditVisible} = useRecoilValue(customerFormAtom);


    const loadContent = () => {

        if (isFeedback)
            return <FeedbackContent/>;

        if (isCustomerDataExist)
            return <CustomerForm/>

        return <>
            {isEditVisible && <CustomerInfo/>}
            <CardForm/>
        </>
    }

    return (
        <Fragment>
            <div className={styles.checkoutBox}>
                <MerchantInfoHeader/>
                {loadContent()}
                <CheckoutFooter/>
            </div>
            {
                link?.uid && link?.testMode && !isFeedback && <FakeCards/>
            }
        </Fragment>
    );
};

export default Index;