import React from 'react';
import styles from "./vgs-form.module.scss"
import {Loader} from "rsuite";

const Index = () => {
    return (
        <div className={styles.vgsFormLoader}>
            <Loader size="md" />
        </div>
    );
};

export default Index;