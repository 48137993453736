import React from 'react';
import {Button, Tooltip, Whisper} from "rsuite";
import styles from "./ElementTooltip.module.scss"

function ElementTooltip({children, message}) {
    return (
        <Whisper placement="top" trigger="hover"
                 speaker={<Tooltip>
                     {message}
                 </Tooltip>}>
            <Button
                appearance={"ghost"}
                className={`p-0 non-hover ${styles.container}`}
                style={{
                    marginBottom: 4
                }}
            >
                {children}
            </Button>
        </Whisper>
    );
}

export default ElementTooltip;