import React from 'react';
import styles from "./FilterHeader.module.scss"
import {InsightCardIcon} from "assets/icons";
import {Amount} from "../index";
import {Placeholder} from "rsuite"
import {isUndefined} from "lodash"

const {Graph} = Placeholder

function FilterHeader({headerData}) {

    if (
        isUndefined(headerData.successPayments) ||
        isUndefined(headerData.totalAmount)
    )
        return <div className={styles.container}>
            <div className={styles.inner}>
                <div style={{padding: "12px 0"}}>
                    <Graph
                        active
                        height={50}
                        style={{
                            width: "100%"
                        }}
                    />
                    <Graph
                        active
                        height={34}
                        style={{
                            width: "100%"
                        }}
                    />
                </div>
            </div>
        </div>

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.row}>
                    <div>
                        <p>Total Revenue</p>
                        <div className={styles.revenueCard}><InsightCardIcon/> <p>
                            <span>x</span>{headerData.successPayments}</p></div>
                    </div>
                    <div>
                        <p>
                            <Amount
                                amount={headerData.totalAmount}
                                currency={"QAR"}
                            />
                        </p>
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <p>Total refunds</p>
                    </div>
                    <div>
                        <p>
                            <Amount
                                amount={headerData.totalRefund}
                                currency={"QAR"}
                            />
                        </p>
                    </div>
                </div>
                {/*<div className={styles.row}>
                    <div>
                        <p>Total chargebacks</p>
                    </div>
                    <div>
                        <p>1000 QAR</p>
                    </div>
                </div>*/}
            </div>
        </div>
    );
}

export default FilterHeader;