import styles from "components/checkout-link/checkout-form/checkout-form.module.scss";
import {MerchantPlaceholderHeader} from "components";
import SkeletonItem from "./skeleton-item"

const Index = () => {
    return (
        <div className={styles.checkoutBox}>
            <MerchantPlaceholderHeader/>
            <div>
                {
                    [1,2,3].map(i=><SkeletonItem key={i} />)
                }
            </div>
        </div>
    )
}

export default Index;