import * as React from "react";

function SvgEnvelopeIcon(props) {
  return (
    <svg
      className="envelope_icon_svg__icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style />
      </defs>
      <path d="M874.667 608.427A42.667 42.667 0 00832 651.093V821.76a42.667 42.667 0 01-42.667 42.667H192a42.667 42.667 0 01-42.667-42.667V412.587l250.88 250.88a128 128 0 0090.027 37.546 128 128 0 0092.16-38.826 42.667 42.667 0 000-59.307 42.667 42.667 0 00-61.013 0 42.667 42.667 0 01-59.734 0l-252.16-250.453h195.84a42.667 42.667 0 000-85.334H192a128 128 0 00-128 128V821.76a128 128 0 00128 128h597.333a128 128 0 00128-128V651.093a42.667 42.667 0 00-42.666-42.666zm42.666-469.334a42.667 42.667 0 00-42.666 42.667 213.333 213.333 0 10-128 384 213.333 213.333 0 00140.8-53.76 42.667 42.667 0 00-56.32-64.427 128 128 0 1110.666-180.906h-52.48a42.667 42.667 0 000 85.333h128A42.667 42.667 0 00960 309.333v-128a42.667 42.667 0 00-42.667-42.24z" />
    </svg>
  );
}

export default SvgEnvelopeIcon;
