import {FlexboxGrid, Popover, Icon, Divider} from "rsuite";
import styles from "./ProfilePopover.module.scss"
import {Link} from "react-router-dom";
import {Routes} from "utils/routes";
import {useSignOut} from "services/AuthService";
import {useTranslation} from "react-i18next";

export const ProfilePopover = (avatar, email, fullname) => {
    const signOut = useSignOut();
    const {t}=useTranslation("components")
    const logout=()=>{
        signOut.call()
    }

    return (<Popover className={styles.profilePopover}>
            <FlexboxGrid className={styles.menuItem} align="middle">
                <span className={styles.menuItemIcon}>{avatar}</span>
                <p className={styles.menuItemInfo}>
                    <p>{fullname}</p>
                    <p className={styles.menuItemInfoEmail}>{email}</p>
                </p>
            </FlexboxGrid>
            <Divider/>
            <Link to={Routes.Settings.General}>
                <FlexboxGrid className={`${styles.menuItem} pointer`} align="middle">
                    <Icon icon="gear" className={styles.menuItemIcon}/>
                    <p>{t("profile_popover.account_settings")}</p>
                </FlexboxGrid>
            </Link>
            <FlexboxGrid onClick={logout} className={`${styles.menuItem} pointer mt-2`} align="middle">
                <Icon icon="sign-out" className={styles.menuItemIcon}/>
                <p>{t("profile_popover.logout")}</p>
            </FlexboxGrid>
        </Popover>
    )
}