import {IBAN_REGEX} from "config/constants";

const bank_codes = {
    "QNBA": "QATAR NATIONAL BANK",
    "CBQA": "COMMERCIAL BANK OF QATAR",
    "DOHB": "DOHA BANK",
    "QISB": "QATAR ISLAMIC BANK",
    "ABQQ": "AHLI BANK OF QATAR",
    "QIIB": "QATAR INTL ISLAMIC BANK",
    "ARAB": "ARAB BANK",
    "MSHQ": "MASHREQ BANK",
    "IBOQ": "INTERNATIONAL BANK OF QATAR",
    "BBME": "HSBC",
    "SCBL": "STANDARD CHARTERED BANK",
    "UNIL": "UNITED BANK LTD",
    "BNPA": "BNP PARIBAS",
    "MAFR": "MASRAF BANK",
    "KLJI": "AL KHALIJ COMMERCIAL BANK",
    "BRWA": "DUKHAN BANK",
    "QIDB": "QATAR DEVELOPMENT BANK"
}

const bankAccountPatterns = {
    "QNBA": {slice: -13, pattern: [[0, 10],[11, 13]] },
    "CBQA": {slice: -13, pattern: [[0, 4],'-', [4, 10], '-', [10, 13]] },
    "DOHB": {slice: -14, pattern: [[0, 3],'-', [3, 10], '-', [10, 11],'-', [11, 13], '-', [13, 14]] },
    "QISB": {slice: -13, pattern: [[0, 14]] },
    "ABQQ": {slice: -14, pattern: [[0, 14]] },
    "QIIB": {slice: -14, pattern: [[0, 14]] },
    "ARAB": {slice: -14, pattern: [[0, 14]] },
    "MSHQ": {slice: -12, pattern: [[0, 4],'-', [4, 9], '-', [9, 12]] },
    "IBOQ": {slice: -14, pattern: [[0, 14]] },
    "BBME": {slice: -14, pattern: [[0, 14]] },
    "SCBL": {slice: -14, pattern: [[0, 14]] },
    "UNIL": {slice: -14, pattern: [[0, 14]] },
    "BNPA": {slice: -14, pattern: [[0, 14]] },
    "MAFR": {slice: -14, pattern: [[0, 14]] },
    "KLJI": {slice: -14, pattern: [[0, 14]] },
    "BRWA": {slice: -14, pattern: [[0, 14]] },
    "QIDB": {slice: -14, pattern: [[0, 14]] }
} 

const BankCodeCheck = (iban) => {
    const expression = IBAN_REGEX;
    const iban_number = iban.toUpperCase()
    const bank_code = expression.exec(iban_number)[1]
    return bank_code
}


//function to check if IBAN is valid
function IBANValidator(iban) {
    const iban_number = iban?.toUpperCase()
    const expression = IBAN_REGEX

    return (expression.test(iban_number))
}

//function to check if bank code is valid
function BankValidator(iban) {

    //const expression = IBAN_REGEX;

    if (IBANValidator(iban)) {
        const bc = BankCodeCheck(iban)
        return (bank_codes.hasOwnProperty(bc))

    } else {
        return false
    }

}
// bank name based on iban and bank code null if other two requirements are not met
function GetBankCodeFromIBAN(iban) {
    if (IBANValidator(iban) && BankValidator(iban)) {
        return BankCodeCheck(iban)
    }
    return null
}
// function IBANValidator(iban) {
//
//     return [validateIBAN(iban), bankvalidator(iban), bank(iban)]
// }

const ibanToBank = (iban) => {
    //get bank_code 
    const bank_code = GetBankCodeFromIBAN(iban)
    //get bank name
    const bankName = bank_codes[bank_code]
    //get the digit by which to slice the iban
    const slice = bankAccountPatterns[bank_code]?.slice
    //remove whitespaces and slice
    const sliced =  iban?.replace(/ /g, '')?.slice(slice)
    //get bank account pattern 
    const pattern = bankAccountPatterns[bank_code]?.pattern
    var bankAccount = '';
    pattern?.forEach (item => {
        if (typeof item === 'object') {
            bankAccount = bankAccount.concat(sliced.slice(item[0], item[1]))
        }
        else bankAccount = bankAccount.concat(item)
    })
    return {bankAccount, bankName}
}

const ibanToBankName = (iban) => ibanToBank(iban).bankName
const ibanToBankAccount = (iban) => ibanToBank(iban).bankAccount


export {
    IBANValidator,
    GetBankCodeFromIBAN,
    ibanToBankName,
    ibanToBankAccount
}