import * as React from "react";

function SvgSettingIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 13.077a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        stroke="currentColor"
        strokeOpacity={0.8}
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.166 13.077a1.375 1.375 0 00.275 1.517l.05.05a1.667 1.667 0 11-2.358 2.358l-.05-.05a1.375 1.375 0 00-1.517-.275 1.376 1.376 0 00-.833 1.258v.142a1.667 1.667 0 01-3.333 0v-.075a1.375 1.375 0 00-.9-1.258 1.375 1.375 0 00-1.517.274l-.05.05a1.668 1.668 0 11-2.358-2.358l.05-.05a1.375 1.375 0 00.275-1.517 1.375 1.375 0 00-1.259-.833H2.5a1.667 1.667 0 110-3.333h.075a1.375 1.375 0 001.258-.9 1.375 1.375 0 00-.275-1.517l-.05-.05a1.667 1.667 0 112.358-2.358l.05.05a1.375 1.375 0 001.517.275H7.5a1.375 1.375 0 00.833-1.259v-.141a1.667 1.667 0 113.333 0v.075A1.376 1.376 0 0012.5 4.41a1.375 1.375 0 001.516-.275l.05-.05a1.666 1.666 0 012.72 1.818 1.666 1.666 0 01-.361.54l-.05.05A1.375 1.375 0 0016.1 8.01v.067a1.375 1.375 0 001.258.833h.142a1.667 1.667 0 010 3.333h-.075a1.375 1.375 0 00-1.259.834v0z"
        stroke="currentColor"
        strokeOpacity={0.8}
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgSettingIcon;
