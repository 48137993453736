import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  ControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "rsuite";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Calendar } from "components";
import {
  capitalize,
  dateToISO,
  getFirstDayOfMonth,
  getTodayISODate,
  getYesterdayEndISODate,
  getYesterdayISODate,
  startOfWeek,
} from "utils/helpers";
import styles from "./filter-table.module.scss";
import { useAPI } from "hooks/useAPI";

const Index = ({
  statuses,
  periods,
  methods,
  filterAtom,
  tableSettingsAtom,
  statusesAtom,
  active,
  blacklist,
}) => {
  const { t } = useTranslation("pages");
  const [filters, setFilters] = useRecoilState(filterAtom);
  const statues = useRecoilValue(statusesAtom);
  let {
    isCustomPeriod,
    status,
    method,
    period,
    custom_period,
    from,
    to,
  } = filters;
  const [tableSettings, updateTableSettings] = useRecoilState(
    tableSettingsAtom
  );
  const { cancel_request } = useAPI();

  const filterChange = (value, e) => {
    if (!e) return;

    let { name } = e.target;
    if (name === "period") {
      period = value;
      if (value === "custom") {
        isCustomPeriod = true;
      } else {
        isCustomPeriod = false;
        from = 0;
        to = dateToISO(new Date());
        switch (value) {
          case "today":
            from = getTodayISODate();
            break;
          case "yesterday":
            from = getYesterdayISODate();
            to = getYesterdayEndISODate();
            break;
          case "week":
            from = startOfWeek();
            break;
          case "month":
            from = getFirstDayOfMonth();
            break;
          default:
            break;
        }
      }
      setFilters({ ...filters, from, to, isCustomPeriod, period });

      //
    } else if (name === "status") {
      if (value[value.length - 1] !== "all") {
        status = value.filter((item) => item !== "all");
      } else {
        status = ["all"];
      }

      setFilters({ ...filters, status });
    } else {
      setFilters({ ...filters, [name]: value });
    }
    // we shouldn't reload  in case custom just clicked
    if (name === "period" && value === "custom") {
      return;
    }
    reloadData();
  };

  const handleBlacklistChange = (blacklist) => {
    setFilters({ ...filters, blacklist });
    reloadData();
  };

  const calendarChange = (value) => {
    value[0].setHours(0, 0, 0, 0);
    value[1].setHours(23, 59, 59, 59);
    from = dateToISO(value[0]);
    to = dateToISO(value[1]);
    custom_period = value;
    setFilters({ ...filters, custom_period, from, to });
    reloadData();
  };

  const reloadData = () => {
    cancel_request("Filter changed");
    updateTableSettings({
      ...tableSettings,
      page: 1,
      reload: !tableSettings.reload,
      showMobileFilters: false,
    });
  };

  return (
    <div className={styles.filterWrapper}>
      {statuses ? (
        <FormGroup>
          <ControlLabel className={styles.filterItemTitle}>
            {t("payments.filters.statusLabel")}
          </ControlLabel>
          <CheckboxGroup
            value={status}
            className={styles.filters_checkbox}
            onChange={(value, e) => filterChange(value, e)}
            name="status"
          >
            {statuses.map((item, i) => {
              return (
                <Checkbox key={i} value={item?.value}>
                  {capitalize(item?.label)} ({statues[item?.id] || 0})
                </Checkbox>
              );
            })}
          </CheckboxGroup>
        </FormGroup>
      ) : null}

      {periods ? (
        <FormGroup className={isCustomPeriod && "customPeriod"}>
          <ControlLabel className={styles.filterItemTitle}>
            {t("payments.filters.periodLabel")}
          </ControlLabel>
          <RadioGroup
            className={styles.filters_radios}
            name="period"
            value={period}
            onChange={(value, e) => filterChange(value, e)}
          >
            {periods.map((item, i) => (
              <Radio key={i} disabled={active} value={item.value}>
                {capitalize(item?.label)}
              </Radio>
            ))}
          </RadioGroup>

          {isCustomPeriod ? (
            <Calendar
              onChange={calendarChange}
              value={custom_period}
              name={"custom_period"}
            />
          ) : null}
        </FormGroup>
      ) : null}

      {methods ? (
        <FormGroup>
          <ControlLabel className={styles.filterItemTitle}>
            {t("payments.filters.methodLabel")}
          </ControlLabel>
          <CheckboxGroup
            value={method}
            onChange={(value, e) => filterChange(value, e)}
            className={styles.filters_checkbox}
            name="method"
          >
            {methods.map((item, i) => (
              <Checkbox key={i} value={item.value}>
                {capitalize(item?.label)}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </FormGroup>
      ) : null}
      {blacklist ? (
        <FormGroup>
          <ControlLabel className={styles.filterItemTitle}>
            {t("payments.filters.blacklistLabel")}
          </ControlLabel>
          <Checkbox
            onChange={(value, checked) => handleBlacklistChange(checked)}
          >
            {t("payments.filters.blacklistCheck")}
          </Checkbox>
        </FormGroup>
      ) : null}
    </div>
  );
};

export default Index;
