import React from 'react';
import {useAPI} from "../hooks/useAPI";
import {ENV as Env} from "../config/env";

function PayscreenService() {
    const {call, ...rest} = useAPI();

    return {
        getPayment: (paymentToken) => {
            return call({
                method: 'GET',
                link: `/transactions/hosted/${paymentToken}`
            })
        },
        debitCardPayment: (publicKey, paymentToken) => {
            return call({
                method: 'POST',
                baseURL: Env.CHECKOUT_API_URL,
                link: `/payments/pay/debitcard/${paymentToken}`,
                data: {
                    key: publicKey
                }
            })
        },
        ...rest

    }
}

export default PayscreenService;