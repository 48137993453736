import { useState } from "react";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../store/authRecoil";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import { GUARDS } from "../../config/guards";
import { LOGIN_PATH } from "../../config/constants";
import { currentRoleAtom } from "store/userRecoil";
import { permessions } from "utils/enums";
import { getInterfaceByRole } from "utils/helpers";

export const RouteExtended = ({
  path,
  component,
  render,
  limitedAccess = false,
  exact = false,
  guards = [],
  name = "",
}) => {
  const auth = useRecoilValue(authAtom);
  const { role } = useRecoilValue(currentRoleAtom);

  useEffect(() => {}, [auth]);

  if (guards.includes(auth?.guard)) {
    if (limitedAccess && !permessions[name].access.includes(role)) {
      return (
        <Redirect to={!guards.includes(GUARDS.GUEST) ? LOGIN_PATH : "/"} />
      );
    }
    return (
      <Route
        {...(render && { render })}
        path={path}
        exact={exact}
        component={component}
      />
    );
  }
  return (
    <Redirect
      to={
        !guards.includes(GUARDS.GUEST) ? LOGIN_PATH : getInterfaceByRole(role)
      }
    />
  );
};
