import React, { useContext, useRef } from "react";
import styles from "./SettingsWrapper.module.scss";
import SettingsHeaderContext from "context/SettingsHeaderContext";
import { useLocation } from "react-router";

import FadedDiv from "components/faded-div";

/**
 * @param {{title:string,link:string}} subtitle
 * @param {boolean} showCard
 * @param {boolean} showTestModeToggle
 * @param {string} title
 * @parma {string} className
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

function SettingsWrapper({
  subtitle: subTitleCall,
  title = null,
  showCard = true,
  showTestModeToggle = true,
  className = "",
  ...props
}) {
  const context = useContext(SettingsHeaderContext);
  const location = useLocation();
  const ref = useRef(null);

  React.useEffect(() => {
    context?.setSubtitle(subTitleCall);
  }, [subTitleCall, location]);

  React.useEffect(() => {
    context?.setShowTestModeToggle(showTestModeToggle);
  }, [showTestModeToggle, location]);

  React.useEffect(() => {
    context?.changeTitle(title);
  }, [title]);

  if (!showCard) {
    return props.children;
  }

  return (
    <FadedDiv ref={ref} className={`${styles.container} ${className}`}>
      {props.children}
    </FadedDiv>
  );
}

export default SettingsWrapper;
