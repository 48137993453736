import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.scss";
import mobileStyles from "./MobileNavBar.module.scss";
import {
  BalanceIcon,
  GetStartedIcon,
  InsightIcon,
  LinkIcon,
  LogoutIcon,
  PaymentIcon,
  PlusRoundedIcon,
  QuestionMarkRoundedIcon,
  SettingIcon,
  CustomersIcon,
  TeamIcon,
} from "assets/icons/index";
import { useCanBreakPointMobile } from "hooks/useViewport";
import CompanyAvatar from "./company-avatar/CompnayAvatar";
import { OrganizationsList } from "./organization-list/OrganizationsList";
import { MobileMenuBarIcon } from "assets/icons";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Routes } from "utils/routes";
import i18n from "i18n";
import { LINKS_TRIGGER_ACTIONS } from "./LINKS_TRIGGER_ACTIONS";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { linksTableSettingsAtom } from "store/linksRecoil";
import CreateLinkDrawer from "pages/links/links/create-link-drawer";
import { Drawer, Animation } from "rsuite";
import { settingsAtom } from "store/settingsRecoil";
import { organizationAtom } from "store/organizationRecoil";
import { userAtom } from "store/userRecoil";
import { ONBOARDING_STATUS } from "../../utils/enums";
import { useSignOut } from "../../services/AuthService";
import { sideBarAtom } from "../../store/sideBarRecoil";
import TestMode from "../dashboard-nav/TestMode";
import { currentRoleAtom } from "store/userRecoil";
import { useWindowSize } from "hooks/useWindowSize";
import { permessions } from "utils/enums";

let SIDEBAR_LINKS = [
  // {
  //     id: 1,
  //     title: i18n.t("components:sidebar.getStarted"),
  //     icon: GetStartedIcon,
  //     link: Routes.OBHome,
  // },
  {
    id: 2,
    title: i18n.t("components:sidebar.payment"),
    icon: PaymentIcon,
    link: Routes.PYHome,
    limitedAccess: true,
    index: "payments",
  },
  {
    id: 3,
    title: i18n.t("components:sidebar.links"),
    icon: LinkIcon,
    link: Routes.LinksHome,
  },
  // {
  //   id: 4,
  //   title: i18n.t("components:sidebar.customers"),
  //   icon: CustomersIcon,
  //   link: Routes.Customers,
  //   limitedAccess: true,
  //   index: "customers",
  // },
  // {
  //     id: 4,
  //     title: i18n.t("components:sidebar.insights"),
  //     icon: InsightIcon,
  //     link: Routes.Insights.Base
  // },
  {
    id: 5,
    title: i18n.t("components:sidebar.balance"),
    icon: BalanceIcon,
    link: Routes.Balance,
    limitedAccess: true,
    index: "balance",
  },

  {
    id: 6,
    title: i18n.t("components:sidebar.team"),
    icon: TeamIcon,
    link: Routes.Team,
    limitedAccess: true,
    index: "team",
  },

  // {
  //     id: 6,
  //     title: i18n.t("components:sidebar.developer"),
  //     icon: DeveloperIcon,
  //     link: Routes.DEVELOPERHome
  // },
  {
    id: 7,
    title: i18n.t("components:sidebar.settings"),
    icon: SettingIcon,
    link: Routes.Settings.General,
  },
  {
    id: 8,
    title: i18n.t("components:sidebar.logout"),
    icon: LogoutIcon,
    action: LINKS_TRIGGER_ACTIONS.LOGOUT,
  },
];

const SIDEBAR_BOTTOM_LINKS = [
  // {
  //   id: 1,
  //   title: i18n.t("components:sidebar.createPaymentLink"),
  //   icon: PlusRoundedIcon,
  //   action: LINKS_TRIGGER_ACTIONS.OPEN_CREATE_LINK_MODAL,
  // },
  {
    id: 2,
    title: i18n.t("components:sidebar.visitHelpCenter"),
    icon: QuestionMarkRoundedIcon,
    link: Routes.Help.Base,
    isBlank: true,
  },
];

function SideBarWrapper() {
  const breakPoint = useCanBreakPointMobile();
  let [tableSettings, setTableSettings] = useRecoilState(
    linksTableSettingsAtom
  );
  const signOutService = useSignOut();

  useEffect(() => {}, []);

  const openCreateLinkDrawer = () => {
    setTableSettings({
      ...tableSettings,
      showCreateLinkDrawer: true,
      show_more_fields: false,
    });
  };

  const handleTriggerAction = (action) => {
    switch (action) {
      case LINKS_TRIGGER_ACTIONS.OPEN_CREATE_LINK_MODAL:
        openCreateLinkDrawer();
        break;

      case LINKS_TRIGGER_ACTIONS.LOGOUT:
        signOutService.call();
        break;
    }
  };

  return (
    <>
      {!breakPoint ? (
        <Sidebar handleTriggerAction={handleTriggerAction} />
      ) : (
        <MobileNavBar />
      )}

      <SidebarMobile handleTriggerAction={handleTriggerAction} />
      <CreateLinkDrawer />
    </>
  );
}

function Sidebar({ handleTriggerAction, inDrawer }) {
  const breakPoint = useCanBreakPointMobile();
  const location = useLocation();
  const [sideBarActive, setSideBarActive] = useState(null);
  const [showOrgs, setShowOrgs] = useState(false);
  const [settings, updateSettings] = useRecoilState(settingsAtom);
  const user = useRecoilValue(userAtom);
  const organization = useRecoilValue(organizationAtom);
  const { role } = useRecoilValue(currentRoleAtom);
  const [width, height] = useWindowSize();

  // const orgsList =

  useEffect(() => {
    let path = String(location.pathname);
    //example link /transaction/tr_erdsfdsf
    if (path.indexOf("/", 1) >= 0) {
      path = path.substring(0, path.indexOf("/", 1));
    }
    setSideBarActive(path);
  }, [location.pathname]);

  useEffect(() => {
    organization?.onboarding?.status && checkPushGetStartedPage();
  }, [organization?.onboarding?.status, role]);

  const checkPushGetStartedPage = () => {
    const index = SIDEBAR_LINKS.findIndex(function (i) {
      return i.id === 1;
    });

    if (
      organization?.onboarding?.status !== ONBOARDING_STATUS.COMPLETED &&
      permessions["onboarding"].access.includes(role)
    ) {
      if (index === -1) {
        SIDEBAR_LINKS.unshift({
          id: 1,
          title: i18n.t("components:sidebar.getStarted"),
          icon: GetStartedIcon,
          link: Routes.OBHome,
        });
      }
    } else {
      if (index !== -1) {
        SIDEBAR_LINKS.splice(index, 1);
      }
    }
  };

  const checkMenuIsActive = (link) => {
    if (!link) return false;
    if (sideBarActive) {
      return sideBarActive === link;
    }
    return location.pathname.startsWith(link);
  };
  const handleSideBarClick = (link) => {
    setSideBarActive(link);
    // close drawer in case mobile
    if (settings.sidebarCollapse) {
      updateSettings({ ...settings, sidebarCollapse: false });
    }
  };

  const renderSideBarMenu = () => {
    const orgsList = user.organizations.filter(
      (org) => org?.id !== organization?.id
    );
    if (height < 452 + orgsList.length * 66 && showOrgs) {
      return null;
    }

    if (orgsList.length > 3 && showOrgs) {
      return null;
    }

    return SIDEBAR_LINKS.map((e) => {
      if (!breakPoint && e.id === 8) {
        return;
      }

      if (e.limitedAccess && !permessions[e.index].access.includes(role)) {
        return;
      }

      return (
        <Link
          onClick={() => {
            if (e.link) handleSideBarClick(e.link);
            if (e.action) handleTriggerAction(e.action);
          }}
          to={e.link}
          className={`${styles.sidebarMenuItem} ${
            checkMenuIsActive(e.link) ? styles.sidebarMenuItemActive : ""
          }`}
          key={e.id}
        >
          <div className={styles.sidebarMenuItemIcon}>
            <e.icon />
          </div>
          <div className={styles.sidebarMenuItemContent}>
            <span>{e.title}</span>
          </div>
        </Link>
      );
    });
  };
  return (
    <div className={styles.container}>
      <div
        className={`${styles.innerContainer} ${
          inDrawer && styles.addPaddingTop
        }`}
      >
        <CompanyAvatar
          organization={organization}
          organizationsList={user?.organizations}
          showOrgs={showOrgs}
          setShowOrgs={setShowOrgs}
        />

        <div className={styles.sideBarMenuContainer}>
          <Animation.Collapse in={showOrgs} unmountOnExit>
            {(props, ref) => (
              <OrganizationsList
                {...props}
                ref={ref}
                setShowOrgs={setShowOrgs}
              />
            )}
          </Animation.Collapse>

          <div className={styles.sidebarMenu}>{renderSideBarMenu()}</div>
        </div>
      </div>
    </div>
  );
}

function SidebarMobile({ handleTriggerAction }) {
  const [settings, updateSettings] = useRecoilState(settingsAtom);
  const { Body, Header } = Drawer;

  const closeSidebarMobile = () => {
    updateSettings((old) => ({ ...old, sidebarCollapse: false }));
  };

  return (
    <Drawer
      backdrop={"static"}
      className={styles.drawerSideMobile}
      onHide={closeSidebarMobile}
      placement={"left"}
      show={settings?.sidebarCollapse}
    >
      {/*<Title>ok</Title>*/}
      {/* don't try to remove the ok text (this willn't be displayed but we need text so close can work) */}
      {/*<DrawerCloser className={styles.drawerCloserSidebar}/>*/}
      <Header />
      <Body>
        <Sidebar inDrawer={true} handleTriggerAction={handleTriggerAction} />
      </Body>
    </Drawer>
  );
}

function MobileNavBar() {
  const sideBar = useRecoilValue(sideBarAtom);

  const updateSettings = useSetRecoilState(settingsAtom);

  const openSidebarMobile = () => {
    updateSettings((old) => ({ ...old, sidebarCollapse: true }));
  };

  return (
    <div className={mobileStyles.container}>
      <div className={mobileStyles.innerContainer}>
        <div className="d-flex align-center">
          <MobileMenuBarIcon
            className={mobileStyles.menuIcon}
            onClick={() => openSidebarMobile()}
          />
          <h2 className={mobileStyles.title}>{sideBar.title}</h2>
        </div>
        {sideBar.showTestModeToggle && <TestMode />}
      </div>
    </div>
  );
}

export default SideBarWrapper;
