import React, { useContext, useEffect } from "react";
import { Whisper, Icon, Avatar, Button } from "rsuite";
import styles from "./DashboardNav.module.scss";
import { ProfilePopover } from "./ProfilePopover";
import { useCanBreakPointMobile } from "hooks/useViewport";
import { useRecoilValue } from "recoil";
import { loadingAtom } from "store/loadingRecoil";
import { userAtom } from "store/userRecoil";
import { organizationAtom } from "store/organizationRecoil";
import SettingsHeaderContext from "context/SettingsHeaderContext";
import TestMode from "./TestMode";
import { LeftArrowIcon, RightArrowIcon } from "assets/icons";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { settingsAtom } from "../../store/settingsRecoil";
import { fullNameShort } from "utils/helpers";

/**
 *
 * @param {{title:string,link:string}} subTitle
 * @param showTestModeToggle
 * @returns {JSX.Element}
 * @constructor
 */
function DashboardNav({ subTitle, showTestModeToggle }) {
  const history = useHistory();
  const loading = useRecoilValue(loadingAtom);
  const user = useRecoilValue(userAtom);
  const organization = useRecoilValue(organizationAtom);
  const settings = useRecoilValue(settingsAtom);
  const { title, showHeaderTitle } = useContext(SettingsHeaderContext);
  const { t } = useTranslation(["components"]);
  const breakPoint = useCanBreakPointMobile();
  useEffect(() => {}, [
    loading,
    user,
    organization,
    showTestModeToggle,
    settings,
  ]);

  const goBack = () => {
    history.push(subTitle.link);
  };

  return (
    <div
      className={`${styles.container} ${
        showHeaderTitle ? "" : styles.noLeftSide
      }`}
    >
      {showHeaderTitle && !breakPoint && (
        <div className={styles.leftSection}>
          {!subTitle ? (
            <h1>{title}</h1>
          ) : (
            <div className={styles.subMenu}>
              <h1 onClick={goBack}>{title}</h1> <RightArrowIcon />{" "}
              <h1 className="contains-id">{subTitle.title}</h1>
            </div>
          )}
          {subTitle && !breakPoint && (
            <div onClick={goBack} className={styles.backButton}>
              <LeftArrowIcon /> <span>{t("dashBoardNav.back")}</span>
            </div>
          )}
          {showTestModeToggle && !breakPoint && <TestMode />}
        </div>
      )}
      <div className={styles.userSection}>
        {/*<Button*/}
        {/*    appearance={"ghost"}*/}
        {/*    style={{*/}
        {/*        marginRight: 32*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <BellInnerWrapper>*/}
        {/*        <Badge content={1}>*/}
        {/*            <BellIcon/>*/}
        {/*        </Badge>*/}
        {/*    </BellInnerWrapper>*/}
        {/*</Button>*/}

        {user && (
          <Whisper
            trigger="click"
            placement={"bottomEnd"}
            speaker={ProfilePopover(
              <Avatar size={"sm"} circle>
                {fullNameShort(`${user?.firstName} ${user?.lastName}`)}
              </Avatar>,
              user?.email,
              `${user?.firstName} ${user?.lastName}`
            )}
          >
            <Button
              className={styles.profileBtn}
              appearance={"link"}
              //loading={loading[init_loader.fetch_information]}
            >
              <Avatar size={"sm"} circle>
                {fullNameShort(`${user?.firstName} ${user?.lastName}`) || "?"}
              </Avatar>
              &nbsp;&nbsp;
              <span>{`${user?.firstName || ""} ${user?.lastName || ""}`}</span>
              &nbsp;&nbsp;
              <Icon className={"pr-0"} icon={"angle-down"} />
            </Button>
          </Whisper>
        )}
      </div>
    </div>
  );
}

export default DashboardNav;
