import React from 'react';
import {Placeholder} from "rsuite";

const Index = ({rowHeight, rows, className}) => {
    return (
        <Placeholder.Paragraph active rowHeight={rowHeight ? rowHeight : 25} rows={rows ? rows : 1}
                               className={`${className} custom-placeholder`}/>
    );
};

export default Index;