import { atom } from "recoil";

export const userAtom = atom({
  key: "userAtom",
  default: {
    email: "",
    fullName: "",
    id: "",
    locale: "",
    phone: "",
    organizations: [],
  },
});

export const currentRoleAtom = atom({
  key: "currentRoleAtom",
  default: {
    role: "admin",
  },
});
