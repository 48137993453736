import * as React from "react";

function SvgLeftArrowIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.163 4.61L4.708.16A.56.56 0 015.103 0c.15 0 .29.057.395.16l.335.327a.54.54 0 010 .774L2.016 4.998l3.82 3.741a.538.538 0 010 .774l-.334.327a.56.56 0 01-.395.16.56.56 0 01-.395-.16L.162 5.386A.538.538 0 010 4.998c0-.147.058-.285.163-.388z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgLeftArrowIcon;
