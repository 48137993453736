import React from "react";
import { ControlLabel, FormControl, FormGroup } from "rsuite";
import { FormErrorMessage, Image } from "../index";
import styles from "./form-field.module.scss";

const Index = ({
  data,
  type,
  label,
  placeholder,
  accepter,
  error,
  name,
  className,
  inputClassName,
  labelClassName,
  leftImg,
  onChange,
  readOnly,
  ...props
}) => {
  return (
    <FormGroup className={`${className} ${styles.formItem}`}>
      {label ? (
        <ControlLabel className={labelClassName}>{label}</ControlLabel>
      ) : null}
      <FormControl
        type={type}
        className={`${inputClassName} ${error ? "input-error" : ""}`}
        errorMessage={false}
        name={name}
        accepter={accepter}
        placeholder={placeholder}
        data={data}
        onChange={onChange}
        readOnly={readOnly}
        {...props}
      />
      {leftImg ? (
        <Image className={styles.leftImg} src={leftImg} alt={""} />
      ) : null}
      <FormErrorMessage error={error} />
    </FormGroup>
  );
};

export default Index;
