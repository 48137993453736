import { atom } from "recoil";

export const settingsAtom = atom({
  key: "settingsAtom",
  default: {
    sidebarCollapse: false,
    alertVisible: false,
    resetApiTestModalVisible: false,
    requestRefundModalVisible: false,
    copyNotifyVisible: false,
    alertText: "Critical Issue",
    alertStyle: "primary",
    isPageLoading: false,
    getStartedActivePage: null,
    testMode: true,
    serverDown: false,
  },
});

export const emptyAtom = atom({
  key: "emptyAtom",
});

export const mfaAtom = atom({
  key: "mfaAtom",
  default: {
    enableMfaModalIsVisible: false,
    disableMfaModalIsVisible: false,
    mfaEnabled: false,
    mfaEnabled: false,
    code: "",
    buttonLoading: false,
  },
});

export const newEmailAtom = atom({
  key: "newEmailAtom",
  default: {
    email: "",
    password: "",
    isCodeModalVisible:false
  },
});
