import React from 'react';
import {Placeholder} from "rsuite";
const {Graph} = Placeholder;
import styles from "./skeleton-item.module.scss"

const Index = () => {
    return (
        <div className={`px-4 ${styles.skeletonItem}`}>
            <Graph height={18} width={18} className={"circle-graph"}/>
            <Graph height={25} width={40} className={"mx-3"}/>
            <Graph height={15} width={150} />
        </div>
    );
};

export default Index;