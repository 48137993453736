import React from 'react';
import {Icon} from "rsuite";
import styles from "./filter-button.module.scss"

const Index = ({toggleFilters,className,nbFilters,...props}) => {

    return (
        <div className={`${className} ${styles.filter_btn_wrapper}`} onClick={toggleFilters}>
            <Icon className={styles.filterIcon} icon={"sliders"}/>
            <span className={styles.btnText}>Filter</span>
             <Icon className={`${nbFilters>0 && styles.filterActive}  ${styles.arrowIcon}`} icon={"arrow-down-line"}/>
            {
                nbFilters>0 ? <span className={`${styles.btnText} ${styles.nbFilters}`}>{nbFilters}</span>: null
            }
        </div>
    );
};

export default Index;