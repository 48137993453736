import * as React from "react";

function SvgPaymentIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.75 7.984h-.1V9.851h16.033V7.984H4.75zm2.917 4.167h-.1v1.866H11.1v-1.866H7.667z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
      <path
        d="M18.917 4.65h-1.15V2.668A2.163 2.163 0 0015.583.484h-12.5A2.163 2.163 0 00.9 2.667v8.334c0 1.222.961 2.183 2.183 2.183h1.15v1.983c0 1.222.962 2.184 2.184 2.184h12.5a2.163 2.163 0 002.183-2.184V6.834a2.163 2.163 0 00-2.183-2.183zM4.233 6.835v5.317h-1.15c-.611 0-1.15-.539-1.15-1.15V2.667c0-.611.539-1.15 1.15-1.15h12.5c.612 0 1.15.539 1.15 1.15v1.984H6.417a2.163 2.163 0 00-2.184 2.183zm15.834 8.333c0 .612-.539 1.15-1.15 1.15h-12.5c-.612 0-1.15-.538-1.15-1.15V6.834c0-.611.538-1.15 1.15-1.15h12.5c.611 0 1.15.539 1.15 1.15v8.333z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgPaymentIcon;
