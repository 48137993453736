import React from "react";
import styles from "./TestMode.module.scss";
import { TestModeToggle } from "../index";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { settingsAtom } from "store/settingsRecoil";
import { Loader, Tooltip, Whisper } from "rsuite";
import { organizationAtom } from "store/organizationRecoil";
import { ONBOARDING_STATUS } from "utils/enums";
import {
  paymentsFilterAtom,
  paymentTableSettingsAtom,
} from "store/paymentsRecoil";
import { linksFilterAtom, linksTableSettingsAtom } from "store/linksRecoil";
import { insightsFilterAtom } from "store/insightsRecoil";
import { useLocation } from "react-router";
import { Routes } from "utils/routes";
import {
  balanceFilterAtom,
  balanceTableSettingsAtom,
} from "store/balanceRecoil";
import { useAppInitiateService } from "../../services/AppInitiateService";
import { loadingSelector } from "../../store/loadingRecoil";

function TestMode() {
  const { t } = useTranslation(["components"]);
  const settings = useRecoilValue(settingsAtom);
  const { testMode } = settings;
  const { onboarding } = useRecoilValue(organizationAtom);
  const resetPaymentsFilter = useResetRecoilState(paymentsFilterAtom);
  const resetLinksFilter = useResetRecoilState(linksFilterAtom);
  const resetInsightsFilter = useResetRecoilState(insightsFilterAtom);
  const resetBalanceFilter = useResetRecoilState(balanceFilterAtom);
  const resetLinksSettings = useResetRecoilState(linksTableSettingsAtom);
  const resetPaymentSettings = useResetRecoilState(paymentTableSettingsAtom);
  const resetBalanceSettings = useResetRecoilState(balanceTableSettingsAtom);
  const tooltipRef = React.useRef();
  const { pathname } = useLocation();
  const appInitService = useAppInitiateService();
  const getLoading = useRecoilValue(loadingSelector);

  const loading = getLoading("app_toggle_test_mode");

  React.useEffect(() => {}, []);

  const closeToolTip = () => {
    setTimeout(() => {
      tooltipRef.current?.close();
    }, 4000);
  };
  const switchActive = () => {
    if (loading) return;

    if (onboarding?.status === ONBOARDING_STATUS.COMPLETED) {
      // toggle the test mode now
      appInitService.toggleTestMode();

      tooltipRef.current.close();
      // reset all filters
      resetPaymentsFilter();
      resetLinksFilter();
      resetInsightsFilter();
      resetBalanceFilter();

      // reset tables settings
      switch (pathname) {
        case Routes.LinksHome:
          resetLinksSettings();
          break;
        case Routes.PYHome:
          resetPaymentSettings();
          break;
        case Routes.Balance:
          resetBalanceSettings();
          break;
      }
    } else {
      tooltipRef.current?.open();
      closeToolTip();
    }
  };

  const tooltipWarning = <Tooltip>{t("testMode.tooltipWarning")}</Tooltip>;

  return (
    <Whisper
      ref={tooltipRef}
      placement="bottom"
      trigger="none"
      speaker={tooltipWarning}
    >
      <div className="d-flex align-center">
        {loading && (
          <>
            <Loader />
            &nbsp;
          </>
        )}
        <label onClick={switchActive} className={styles.container}>
          <TestModeToggle loading={loading} value={settings?.testMode} />
          <p>{t("testMode.testMode")}</p>
        </label>
      </div>
    </Whisper>
  );
}

export default TestMode;
