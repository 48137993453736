import React, {useRef, useEffect, useState, useCallback} from 'react';
import {Radio} from "rsuite";
import {useTranslation} from "react-i18next";
import styles from "./methods.module.scss";
import {CHARGE_METHOD} from "../../../config/constants";
import {Image} from "../../index";
import "./apple_pay.scss";
import {useApplePayService} from "../../../services/ApplePayService";
import ApplePayButton from "../apple-pay-button/ApplePayButton";

/**
 *
 * @param isChecked
 * @param handleMethodChange
 * @param paymentToken
 * @param publicKey
 * @param label
 * @param amount
 * @param setServerResponse
 * @returns {JSX.Element}
 * @constructor
 */
function ApplePayMethod({isChecked, handleMethodChange,paymentToken,publicKey,label,amount,setServerResponse}) {
    const {t} = useTranslation("pages");
    const applePayService= useApplePayService();

    const onRequestApplePay = useCallback(
        () => {
            applePayService.startPaymentProcessing({paymentToken,publicKey,label,amount,setServerResponse});

        },
        [paymentToken],
    );


    return (
        <div className={`${styles.paymentMethodTab} ${isChecked && styles.checked}`}>
            <label className={styles.cardTitleText}>
                <span onClick={e => handleMethodChange(CHARGE_METHOD.APPEL_PAY)}>
                    <Radio checked={isChecked}
                           className={styles.cardTitleText}>
                    {t("link_checkout.apple_pay_title")}
                </Radio>
                </span>
                <span className={styles.validCards}>
                      <Image src={"ap-logo.svg"} alt="apple_pay"/>
                </span>
            </label>

            <div className={`${styles.paymentMethodContentDebit} ${isChecked && styles.checked}`}>
                <ApplePayButton title={"Pay with"} onClick={onRequestApplePay} />
            </div>
        </div>
    );
}

export default ApplePayMethod;