import React from "react";
import styles from "./empty-table.module.scss";
import { Message } from "../index";

const Index = ({ text, className }) => {
  return (
    <div className={`${styles.emptyTable} ${className}`}>
      <Message
        noBorder={true}
        className={`${styles.message} ${className}`}
        type="error"
        content={text}
      />
    </div>
  );
};

export default Index;
