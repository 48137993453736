import * as React from "react";

function SvgInsightCardIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 13.5c0 .417.331.75.743.75h13.515a.748.748 0 00.742-.75V3.75a.744.744 0 00-.743-.75H2.243a.746.746 0 00-.743.75v9.75zm.743-11.25h13.515c.824 0 1.492.667 1.492 1.5v9.75c0 .829-.668 1.5-1.493 1.5H2.243A1.494 1.494 0 01.75 13.5V3.75c0-.829.667-1.5 1.493-1.5z"
        fill="currentColor"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 6h15v2.25h-15V6zm2.25 4.5h3V12h-3v-1.5z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgInsightCardIcon;
