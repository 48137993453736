import React from "react";
import styles from "./image-call.module.scss";
import { format_datetime } from "utils/helpers";
import { Image } from "components";

const ImageCell = ({ rowData, ...props }) => (
  <div {...props} className={styles.balanceCellImgWrraper}>
    <div>
      <p className={styles.reference}>{rowData?.id}</p>
      <p className={styles.date}>{format_datetime(rowData?.settledAt)}</p>
    </div>
  </div>
);

export default ImageCell;
