import React from 'react';
import styles from "./InsightsStatisticsCard.module.scss"
import {Amount} from "../../index";
import {Placeholder} from "rsuite"

const Graph = Placeholder.Graph

/**
 *
 * @param title
 * @param amount
 * @param currency
 * @param percentage
 * @returns {JSX.Element}
 * @constructor
 */
function InsightsStatisticsCard({
                                    title,
                                    amount,
                                    currency,
                                    percentage,
                                    loading
                                }) {

    if (loading)
        return <div className={styles.container}><Graph
            active
            height={46}
            style={{
                width: "100%"
            }}
        /></div>
    return (
        <div className={styles.container}>
            <div className={"text-center"}>
                <div>
                    <span className={styles.title}>{title}</span>
                </div>
                <div>
                    <h2>
                        {
                            percentage ? <span>{`${percentage}%`}</span> :
                                <Amount
                                    currency={currency}
                                    amount={amount}
                                />
                        }

                    </h2>
                </div>
            </div>
        </div>
    );
}

export default InsightsStatisticsCard;