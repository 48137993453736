import React, {useCallback} from 'react';
import {Button, Drawer} from "rsuite";
import styles from "./style.module.scss";
import {Image} from "../../index";
import {useTranslation} from "react-i18next";
import ApplePayButton from "../apple-pay-button/ApplePayButton";
import  {useApplePayService} from "../../../services/ApplePayService";


/**
 *
 * @param visible
 * @param setVisible
 * @param paymentToken
 * @param publicKey
 * @param label
 * @param amount
 * @param setServerResponse
 * @returns {JSX.Element}
 * @constructor
 */
const Index = ({visible, setVisible,paymentToken,publicKey,label,amount,setServerResponse}) => {

    const {t} = useTranslation("pages");
    const applePayService= useApplePayService();

    const onRequestApplePay = useCallback(
        () => {
            applePayService.startPaymentProcessing({paymentToken,publicKey,label,amount,setServerResponse});

        },
            [paymentToken],
    );


    return (
        <Drawer className={styles.applePayModal} size={"sm"} placement={"bottom"} show={visible}>
            <Drawer.Body>
                <div className={styles.applePayModalWrapper}>
                    <Image className={styles.applePayImg} src={"ap-logo.svg"} alt={"Apple pay"}/>
                    <p className={"my-3"}>{t("link_checkout.APModal.description")}</p>
                    <ApplePayButton title={"Pay with"} onClick={onRequestApplePay} />
                    <Button className={styles.moreOptionsBtn} appearance={"link"} onClick={() => setVisible(false)}> {t("link_checkout.APModal.moreOptionsBtn")} </Button>
                    <div className={styles.applePayModalFooter}>
                        <Image src={"checkout_footer.svg"} alt={"checkout Dibsy"}/>
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>
    );
};

export default Index;