import React from 'react';
import {useRecoilValue} from "recoil";
import {checkoutLinkDataAtom, customerFormAtom, feedbackContentAtom} from "store/checkoutRecoil";
import {useTranslation} from "react-i18next";
import MerchantInfo from "./MerchantInfo";

const Index = () => {
    const linkData = useRecoilValue(checkoutLinkDataAtom);
    const {isVisible: isFeedback} = useRecoilValue(feedbackContentAtom);
    const {checkoutLogo, link} = linkData;
    const {t} = useTranslation("pages");


    return (
        <MerchantInfo
            currency={link?.currency}
            amount={link?.amount}
            merchant={link?.merchant}
            description={link?.description}
            checkoutLogo={checkoutLogo}
            name={link?.name}
            isFeedbackVisible={isFeedback}
        />
    );
};

export default Index;