import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  ControlLabel,
  Divider,
  Drawer,
  Form,
  FormGroup,
  Icon,
  Input,
  FormControl,
  InputGroup,
  Notification,
  Radio,
  RadioGroup,
  SelectPicker,
  Toggle,
  Schema,
} from "rsuite";
import {
  createPaymentLinkAtom,
  linksFilterAtom,
  linksTableSettingsAtom,
  linksTableDataAtom,
  linksCacheAtom,
} from "store/linksRecoil";
import styles from "./create-link.module.scss";
import { useTranslation } from "react-i18next";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { CREATE_LINK_VALIDITY, LINK_DURATION } from "utils/enums";
import { isValidPhoneNumber } from "libphonenumber-js";

import {
  AmountField,
  DrawerCloser,
  FormErrorMessage,
  FormField,
  Message,
  PhoneInput,
  RadioComponent,
  EmptyTable,
  Image,
} from "components";
import {
  CreatePaymentLinkSchema,
  CreateCustomerSchema,
  AmountSchema,
  MaxAmountSchema,
} from "schemas";
import {
  calculateExpiresAt,
  format_datetime,
  formatAmountInput,
  generateString,
  getEndTodayTimeStamp,
  getTomorrowTimestamp,
  parseErrors,
  replaceSpacesUnderscore,
} from "utils/helpers";
import { useLinksService } from "services/LinksService";
import { useHistory, useLocation } from "react-router";
import { Routes } from "utils/routes";
import { useScrollTop } from "hooks/useScrollTop";
import { ReactSortable } from "react-sortablejs";
import RemovableField from "./removable-field";
import { useCanBreakPointMobile } from "hooks/useViewport";
import { settingsAtom } from "store/settingsRecoil";
import CreateCustomerModal from "../add-customer-modal";
import NewCustomerForm from "../new-customer-form";

import AnimateHeight from "react-animate-height";
import CreateFieldModal from "../add-field-modal";
import { errorParser } from "utils/helpers";

import { fieldsAtom } from "store/linksRecoil";
import ReactJson from "react-json-view";
import { userAtom } from "store/userRecoil";
import { fullNameShort } from "utils/helpers";
import { currentRoleAtom } from "store/userRecoil";
import DynamicTooltip from "../../dynamic-tooltip";

import "./create-link.scss";

const { Body } = Drawer;

const Index = () => {
  let [tableSettings, setTableSettings] = useRecoilState(
    linksTableSettingsAtom
  );
  let [paymentLink, setPaymentLink] = useRecoilState(createPaymentLinkAtom);
  let [linksData, setLinksData] = useRecoilState(linksTableDataAtom);
  let [linkCache, setLinkCache] = useRecoilState(linksCacheAtom);

  const { t } = useTranslation("pages");
  const [formError, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { custom, today, tomorrow } = CREATE_LINK_VALIDITY;
  const linksService = useLinksService();
  const resetPaymentLink = useResetRecoilState(createPaymentLinkAtom);
  const { scrollTop } = useScrollTop();
  const resetLinksFilters = useResetRecoilState(linksFilterAtom);
  const [settings, updateSettings] = useRecoilState(settingsAtom);
  const { pathname } = useLocation();
  const history = useHistory();
  let containerRef = useRef();
  const breakpoint = useCanBreakPointMobile();
  const [isSmsDisabed, setIsSmsDisabed] = useState(true);

  const [fields, setFields] = useRecoilState(fieldsAtom);

  const [isSupport, setIsSupport] = useState(false);

  const user = useRecoilValue(userAtom);

  const { role } = useRecoilValue(currentRoleAtom);

  let {
    isCustomLinkValidity,
    reusable,
    linkValidity,
    expiresAt,
    customer,
    validity_date,
    validity_duration,
    toggleCustomer,
    amount,
    toggleAddDetails,
    toggleRedirection,
    toggleMetaData,
    custom_fields,
    notifyWithSms,
    dynamicAmount,
  } = paymentLink;

  useEffect(() => {
    if (Object.keys(formError).length > 0) {
      let _errors = parseErrors(CreatePaymentLinkSchema.check(paymentLink));
      if (!dynamicAmount) {
        _errors = {
          ..._errors,
          ...parseErrors(AmountSchema.check({ amount: paymentLink?.amount })),
        };
      }
      if (dynamicAmount && paymentLink.allowMaxAmount) {
        _errors = {
          ..._errors,
          ...parseErrors(
            MaxAmountSchema.check({ maxAmount: paymentLink?.maxAmount })
          ),
        };
      }
      setFormErrors(_errors);
    }
  }, [paymentLink]);

  useEffect(() => {
    const storedState = localStorage.getItem("links-fields");
    if (storedState) {
      setPaymentLink((old) => ({
        ...old,
        custom_fields: JSON.parse(storedState),
      }));
    }
  }, [tableSettings.showCreateLinkDrawer]);

  useEffect(() => {
    if (!paymentLink?.custom_fields.length) {
      return;
    }
    localStorage.setItem("links-fields", JSON.stringify(custom_fields));
  }, [paymentLink?.custom_fields]);

  const closeDrawer = () => {
    setTableSettings({ ...tableSettings, showCreateLinkDrawer: false });
    resetPaymentLink();
    setFormErrors({});
  };

  const [currentIndex, setCurrentIndex] = useState(null);

  const generateLinkName = () => {
    let orderId = "#Order";
    for (let i = 0; i < 7; i++) {
      orderId += Math.floor(Math.random() * 10); //generates a random number between 0 and 9
    }
    return orderId;
  };

  const handleFormChange = (_, element) => {
    if (!element) return;
    let { name, value } = element.target;
    
    if (name === "amount" || name === "maxAmount") {
      value = formatAmountInput(value);
    }

    if (name === "email" || name === "phone" || name === "customer_name") {
      let _name = name === "customer_name" ? "name" : name;
      setPaymentLink({
        ...paymentLink,
        customer: { ...customer, [_name]: value },
      });
    } else {
      setPaymentLink({ ...paymentLink, [name]: value });
    }
  };

  const handleCustomerFormhange = (key, val) => {
    setPaymentLink({
      ...paymentLink,
      customer: { ...customer, [key]: val },
    });
  };

  const toggleShowCustomer = () => {
    setPaymentLink({ ...paymentLink, toggleCustomer: !toggleCustomer });
  };

  const toggleShowRedirection = () => {
    setPaymentLink({ ...paymentLink, toggleRedirection: !toggleRedirection });
  };

  const toggleAdditionalDetails = () => {
    setPaymentLink({ ...paymentLink, toggleAddDetails: !toggleAddDetails });
  };
  const toggleAddMetaData = () => {
    setPaymentLink({ ...paymentLink, toggleMetaData: !toggleMetaData });
  };
  const handleAddCustomFeild = (field) => {
    setCustomFields([...custom_fields, field]);
  };

  const handleUpdateCustomFeild = (val, index) => {
    setCustomFields(
      custom_fields.map((field, i) => {
        if (i === parseInt(index)) {
          return { ...field, ...val };
        }
        return field;
      })
    );
  };

  const removeField = (index) => {
    let _custom_fields = JSON.parse(JSON.stringify(custom_fields));
    _custom_fields.splice(index, 1);
    setCustomFields(_custom_fields);
  };

  const handleChange = (name, value) => {
    if (name === "linkValidity") {
      isCustomLinkValidity = value === custom;
      // value is today or tomorrow for sure
      if (value === today) {
        expiresAt = getEndTodayTimeStamp();
      }
      if (value === tomorrow) {
        expiresAt = getTomorrowTimestamp();
      }
      if (value === custom) {
        expiresAt = 0;
      }
    }

    if (name === "validity_duration") {
      expiresAt = calculateExpiresAt(
        Number(value),
        validity_date
      )?.toISOString();
    }
    if (name === "validity_date" && !!validity_duration) {
      expiresAt = calculateExpiresAt(
        Number(validity_duration),
        value
      )?.toISOString();
    }

    if (name === "notifyWithSms") {
      setPaymentLink((old) => ({
        ...old,
        notifyWithSms: value,
      }));
    }

    setPaymentLink({
      ...paymentLink,
      [name]: value,
      isCustomLinkValidity,
      customer,
      expiresAt,
    });

    // if (name === "phone") {
    //   setPaymentLink({
    //     ...paymentLink,
    //     customer: { ...customer, phone: value },
    //   });
    // } else {

    // }
  };

  const setCustomFields = (custom_fields) => {
    setPaymentLink({
      ...paymentLink,
      custom_fields,
    });
  };

  const removableFieldChange = (index, val) => {
    setCustomFields(
      custom_fields.map((field, i) => {
        if (i === index) {
          return { ...field, ...val };
        }
        return field;
      })
    );
  };

  const closeSidebar = () => {
    updateSettings((old) => ({ ...old, sidebarCollapse: false }));
  };

  function handleChangeCustomerID(value) {
    setPaymentLink((old) => ({
      ...old,
      customer: {
        name: "",
        phone: "",
        email: "",
      },
      oldCustomer: value,
    }));
  }

  const submit = () => {
    let _errors = parseErrors(CreatePaymentLinkSchema.check(paymentLink));
    if (!dynamicAmount) {
      _errors = {
        ..._errors,
        ...parseErrors(AmountSchema.check({ amount: paymentLink?.amount })),
      };
    }
    if (dynamicAmount && paymentLink.allowMaxAmount) {
      _errors = {
        ..._errors,
        ...parseErrors(
          MaxAmountSchema.check({ maxAmount: paymentLink?.maxAmount })
        ),
      };
    }

    // if (!Object.keys(paymentLink?.oldCustomer).length) {
    //   let _customerErrors = {};
    //   if (
    //     paymentLink.customer?.email !== "" ||
    //     paymentLink.customer?.name !== "" ||
    //     paymentLink.customer?.phone !== ""
    //   ) {
    //     _customerErrors = parseErrors(
    //       CreateCustomerSchema.check(paymentLink.customer)
    //     );
    //   } else {
    //     _customerErrors = {};
    //   }
    //   _errors = { ..._errors, customer: _customerErrors };
    // } else {
    //   _errors = _.omit(_errors, ["customer"]);
    // }

    // if (!paymentLink?.custom_fields?.length) {
    //   _errors = {
    //     ..._errors,
    //     form: "You must add at least one field to create a payment link.",
    //   };
    // }

    // if (
    //   _errors?.hasOwnProperty("customer") &&
    //   Object.keys(_errors?.customer)?.length === 0
    // ) {
    //   _errors = _.omit(_errors, ["customer"]);
    // }

    if (Object.keys(_errors).length > 0) {
      setFormErrors(_errors);
      if (
        (_errors?.hasOwnProperty("customer") &&
          !Object.keys(paymentLink?.oldCustomer)?.length) ||
        _errors?.hasOwnProperty("customer") ||
        _errors?.hasOwnProperty("redirectUrl") ||
        _errors?.hasOwnProperty("webhookUrl") ||
        paymentLink.custom_fields?.length < 1
      ) {
        setTableSettings({
          ...tableSettings,
          show_more_fields: true,
        });
      }
      if (
        _errors?.hasOwnProperty("customer") &&
        !Object.keys(paymentLink?.oldCustomer)?.length
      ) {
        setPaymentLink({ ...paymentLink, toggleCustomer: true });
      }
      if (_errors?.hasOwnProperty("form")) {
        setPaymentLink({ ...paymentLink, toggleAddDetails: true });
      }
      if (
        _errors?.hasOwnProperty("redirectUrl") ||
        _errors?.hasOwnProperty("webhookUrl")
      ) {
        setPaymentLink({ ...paymentLink, toggleRedirection: true });
      }
    } else {
      setLoading(true);
      linksService
        .createPaymentLink()
        .then((res) => {
          if (res?.success) {
            setTableSettings({
              ...tableSettings,
              showCreateLinkDrawer: false,
              page: 1,
            });
            setLinksData([res.data, ...linksData]);
            setLinkCache(res.data);
            resetLinksFilters();
            setFormErrors({});
            resetPaymentLink();

            Notification.success({
              title: "Payment links",
              description: "The payment link have been created successfully",
            });
          } else {
            const error = errorParser(
              res,
              "There was an error while trying to create the payment link, please try again later."
            );
            error &&
              Notification.error({
                title: "Payment links",
                description: error,
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const toggleShowMoreOptions = () => {
    setTableSettings({
      ...tableSettings,
      show_more_fields: !tableSettings.show_more_fields,
    });
  };

  function onSwitch() {
    setPaymentLink((prev) => ({
      ...prev,
      showAddCustomerModal: true,
    }));
  }

  function showAddFieldModal() {
    setPaymentLink((prev) => ({
      ...prev,
      showAddFieldModal: true,
    }));
  }

  function handleChangeCustomField(index) {
    setCurrentIndex(new String("" + index));
  }

  function handleAddMetaData(src) {
    if (isSupport && src?.existing_src["user"] !== src.updated_src["user"]) {
      return false;
    }
    setPaymentLink({
      ...paymentLink,
      metaData: src.updated_src,
    });
    return true;
  }

  function canSendSms() {
    return !isValidPhoneNumber(paymentLink?.phone || "");
  }

  // useEffect(() => {
  //   setIsSmsDisabed(canSendSms());
  //   setPaymentLink((old) => ({
  //     ...old,
  //     notifyWithSms: !canSendSms(),
  //   }));
  // }, [paymentLink?.phone, paymentLink?.phone]);

  useEffect(() => {
    if (currentIndex) {
      setPaymentLink((prev) => ({
        ...prev,
        showAddFieldModal: true,
      }));
    }
  }, [currentIndex]);

  useEffect(() => {
    if (role === "support") {
      setIsSupport(true);
    }
  }, [role]);

  return (
    <Drawer backdrop={"static"} show={tableSettings.showCreateLinkDrawer}>
      <DrawerCloser onClick={(e) => closeDrawer()} />
      <Body ref={containerRef} className={styles.drawer}>
        <h1 className={styles.drawerTitle}>
          {t("links.create_link_drawer.title")}
        </h1>
        <Form
          className={"mt-4"}
          onChange={handleFormChange}
          formDefaultValue={paymentLink}
          fluid
        >
          <FormField
            label={"Name"}
            error={formError.name}
            placeholder={"Payment from John"}
            name={"name"}
          />
          <AnimateHeight
            id="password-panel"
            duration={500}
            height={dynamicAmount ? 0 : "auto"}
          >
            <AmountField
              disabled={dynamicAmount}
              disableCurrency={true}
              label={t("links.create_link_drawer.amountLabel")}
              error={formError.amount}
              placeholder={t("100.00")}
              name={"amount"}
              amountValue={amount}
              className={"mb-4"}
            />
          </AnimateHeight>
          <FormGroup className={styles.flexAmountContainer}>
            <Toggle
              onChange={(value) =>
                setPaymentLink({ ...paymentLink, dynamicAmount: value })
              }
              checked={dynamicAmount}
            />
            <label className={styles.flexibleAmount}>
              <DynamicTooltip />
            </label>
            <AnimateHeight
              id="password-panel"
              duration={500}
              height={paymentLink.allowMaxAmount && dynamicAmount ? "auto" : 0}
              className="mt-4"
            >
              <AmountField
                disableCurrency={true}
                label={"Maximum Amount (optional)"}
                error={formError.maxAmount}
                placeholder={t("100.00")}
                name={"maxAmount"}
                amountValue={paymentLink.maxAmount}
                className={"mb-4"}
              />
            </AnimateHeight>
          </FormGroup>
          {/* <FormField
            label={"Redirect Url"}
            error={formError?.redirectUrl}
            placeholder={"Payment link redirect url"}
            name={"redirectUrl"}
          /> */}

          <FormField
            componentClass="textarea"
            rows={3}
            label={
              <span>{t("links.create_link_drawer.descriptionLabel")}</span>
            }
            error={formError?.description}
            placeholder={t("Order #10124335")}
            name={"description"}
          />

          <FormGroup>
            <ControlLabel>{"Phone Number"}</ControlLabel>
            <PhoneInput
              // disabled={disabled}
              className={formError?.phone && "input-error"}
              name="phone"
              onChange={(val) => {
                handleChange("phone", val);
              }}
            />
            <FormErrorMessage error={formError?.phone} />
          </FormGroup>

          {/* <div className={`mt-4 mb-5 ${styles.description}`}>
            <Toggle
              onChange={(value) => handleChange("notifyWithSms", value)}
              defaultChecked={notifyWithSms}
              disabled={isSmsDisabed}
            />
            <span className={"ml-3"}>
              {t("links.create_link_drawer.sendSmsCustomer")}
            </span>
          </div> */}

          <div className={"text-center mb-4 mt-4"}>
            <p
              onClick={(e) => toggleShowMoreOptions()}
              className={`pointer text-primary ${styles.showMore}`}
            >
              {tableSettings.show_more_fields ? (
                <>
                  Hide more options
                  <Icon icon={"arrow-up"} className={"text-primary ml-3"} />
                </>
              ) : (
                <>
                  Show more options
                  <Icon icon={"arrow-down"} className={"text-primary ml-3"} />
                </>
              )}
            </p>
          </div>

          {tableSettings.show_more_fields && (
            <>
              <FormGroup className="mb-5">
                <ControlLabel>
                  {t("links.create_link_drawer.linkTypeLabel")}
                </ControlLabel>
                <RadioComponent
                  onChange={(value) => {
                    handleChange("reusable", value);
                  }}
                  errorMessage={formError.reusable}
                  value={reusable}
                  items={[
                    {
                      value: false,
                      label: t("links.create_link_drawer.linkTypeSingle"),
                    },
                    {
                      value: true,
                      label: t("links.create_link_drawer.linkTypeOpen"),
                    },
                  ]}
                />
              </FormGroup>
              <FormGroup
                className={
                  isCustomLinkValidity && styles.custom_validity_wrapper
                }
              >
                <ControlLabel>
                  <span>
                    {t("links.create_link_drawer.linkValidityLabel")}
                    <p className={"tiny-text mb-3"}>
                      {t("links.create_link_drawer.linkValidityText")}
                    </p>
                  </span>
                </ControlLabel>
                <RadioGroup
                  className={styles.linkValidity}
                  name="linkValidity"
                  value={linkValidity}
                  defaultValue={today}
                  onChange={(value, e) => handleChange(e.target.name, value)}
                >
                  <Radio value={today} defaultChecked>
                    {t("links.create_link_drawer.checkbox.today")}
                  </Radio>
                  <Radio value={tomorrow}>
                    {t("links.create_link_drawer.checkbox.tomorrow")}
                  </Radio>
                  <Radio value={custom}>
                    {t("links.create_link_drawer.checkbox.custom")}
                  </Radio>
                </RadioGroup>
                {isCustomLinkValidity && (
                  <>
                    <FormGroup className={styles.custom_link_validity}>
                      <div>
                        <Input
                          type={"number"}
                          placeholder={t(
                            "links.create_link_drawer.validity_duration"
                          )}
                          onChange={(value, e) =>
                            handleChange("validity_duration", value)
                          }
                          name={"validity_duration"}
                          className={
                            formError.validity_duration && "input-error"
                          }
                        />
                      </div>

                      <SelectPicker
                        cleanable={false}
                        searchable={false}
                        error={formError.validity_date}
                        placeholder={t(
                          "links.create_link_drawer.validity_date"
                        )}
                        onChange={(value, e) =>
                          handleChange("validity_date", value)
                        }
                        defaultValue={"days"}
                        data={LINK_DURATION}
                      />
                    </FormGroup>
                  </>
                )}
                {expiresAt ? (
                  <p className={styles.linkValidityExpireTxt}>
                    Expires at : {format_datetime(expiresAt)}
                  </p>
                ) : null}
                <FormErrorMessage
                  error={formError.expiresAt || formError.validity_duration}
                />
              </FormGroup>
              {/* <Divider className={"mt-5 mb-5"} />
              <div className={styles.addCustomer}>
                <h2>{t("links.create_link_drawer.addCustomerToggle")}</h2>
                <Icon
                  onClick={toggleShowCustomer}
                  className={styles.toggleIcon}
                  icon={toggleCustomer ? "minus" : "plus"}
                />
              </div> */}
              {/* {toggleCustomer && (
                <>
                  <AnimateHeight
                    duration={500}
                    height={
                      !Object.keys(paymentLink?.oldCustomer).length ? "auto" : 0
                    }
                  >
                    <NewCustomerForm
                      formValue={paymentLink.customer}
                      formErrors={formError.customer}
                      handleChange={handleCustomerFormhange}
                    />
                  </AnimateHeight>
                  <AnimateHeight
                    duration={500}
                    height={
                      Object.keys(paymentLink?.oldCustomer).length ? "auto" : 0
                    }
                  >
                    <div onClick={onSwitch}>
                      <FormField
                        className="mt-4 clickable-input"
                        label={"Customer"}
                        name={"oldCustomer"}
                        readOnly={true}
                        value={
                          paymentLink.oldCustomer?.name ||
                          paymentLink.oldCustomer?.phone ||
                          paymentLink.oldCustomer?.email
                        }
                      />
                    </div>
                  </AnimateHeight>
                  <div className={`mt-4 ${styles.description}`}>
                    <Toggle
                      onChange={(value) => handleChange("notifyWithSms", value)}
                      defaultChecked={notifyWithSms}
                      disabled={isSmsDisabed}
                    />
                    <span className={"ml-3"}>
                      {t("links.create_link_drawer.sendSmsCustomer")}
                    </span>
                  </div>
                  {!Object.keys(paymentLink?.oldCustomer).length ? (
                    <div className={styles.switchToCustomerIdForm}>
                      <p className="mt-4 mb-2" onClick={onSwitch}>
                        <Icon icon={"plus-circle"} className={"mr-2"} />
                        {"Find customer"}
                      </p>
                    </div>
                  ) : null}
                </>s
              )} */}

              <Divider className={"mt-5 mb-5"} />
              <div className={styles.addCustomer}>
                <h2>{"Form"}</h2>
                <Icon
                  onClick={toggleAdditionalDetails}
                  className={styles.toggleIcon}
                  icon={toggleAddDetails ? "minus" : "plus"}
                />
              </div>
              {toggleAddDetails && (
                <div className={"mt-3"}>
                  {formError?.form && (
                    <p className={"input-error-text"}>{formError?.form}</p>
                  )}
                  {custom_fields?.length > 0 ? (
                    <ReactSortable
                      list={custom_fields}
                      handle=".handle"
                      setList={setCustomFields}
                    >
                      {custom_fields.map((item, index) => (
                        <RemovableField
                          onChange={(index, val) =>
                            removableFieldChange(index, val)
                          }
                          index={index}
                          key={index}
                          data={item}
                          onClick={() => {
                            handleChangeCustomField(index);
                          }}
                          removeField={(e) => removeField(index)}
                        />
                      ))}
                    </ReactSortable>
                  ) : (
                    <></>
                  )}
                  <div className={`${styles.switchToCustomerIdForm} mt-1`}>
                    <p
                      className="mt-3 mb-2"
                      onClick={(e) => showAddFieldModal()}
                    >
                      <Icon icon={"plus-circle"} className={"mr-2"} />
                      {"Add custom field"}
                    </p>
                  </div>
                </div>
              )}
              <Divider className={"mt-5 mb-5"} />
              <div className={styles.addMetaData}>
                <h2>{"Metadata"}</h2>
                <Icon
                  onClick={toggleAddMetaData}
                  className={styles.toggleIcon}
                  icon={toggleMetaData ? "minus" : "plus"}
                />
              </div>
              {toggleMetaData && (
                <div className={`mt-3 ${isSupport && "for-support"}`}>
                  <div className={`${styles.metaDataEditor} mt-1`}>
                    <ReactJson
                      src={paymentLink.metaData}
                      name={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      enableClipboard={false}
                      enableEdit={true}
                      onEdit={(item) => {
                        return handleAddMetaData(item);
                      }}
                      onAdd={(item) => {
                        return handleAddMetaData(item);
                      }}
                      onDelete={(item) => {
                        return handleAddMetaData(item);
                      }}
                    />
                  </div>
                </div>
              )}
              <Divider className={"mt-5 mb-5"} />
              <div className={styles.addCustomer}>
                <h2>{"Redirection"}</h2>
                <Icon
                  onClick={toggleShowRedirection}
                  className={styles.toggleIcon}
                  icon={toggleRedirection ? "minus" : "plus"}
                />
              </div>
              {toggleRedirection && (
                <div className="mt-4">
                  <FormField
                    label={"Success URL"}
                    error={formError?.redirectUrl}
                    placeholder={"https://webshop.com/redirect/success"}
                    name={"redirectUrl"}
                  />
                  <FormField
                    label={"Webhook URL"}
                    error={formError?.webhookUrl}
                    placeholder={"https://webshop.com/webhook"}
                    name={"webhookUrl"}
                  />
                </div>
              )}
              <Divider className={"mt-5 mb-5"} />
            </>
          )}

          <Message
            type={"error"}
            content={t("links.create_link_drawer.noticeMsg")}
          />
          <FormGroup>
            <Button
              loading={loading}
              type={"submit"}
              onClick={submit}
              appearance={"primary"}
              className={"w-100 mt-4"}
            >
              {t("links.create_link_drawer.submitBtn")}
            </Button>
          </FormGroup>
        </Form>
      </Body>
      {paymentLink.showAddCustomerModal && (
        <CreateCustomerModal
          visible={paymentLink.showAddCustomerModal}
          defaultValue={paymentLink.oldCustomer}
          closeModal={() => {
            setPaymentLink((prev) => ({
              ...prev,
              showAddCustomerModal: false,
            }));
          }}
          handleChange={handleChangeCustomerID}
        />
      )}
      {paymentLink.showAddFieldModal && (
        <CreateFieldModal
          visible={paymentLink.showAddFieldModal}
          closeModal={() => {
            setPaymentLink((prev) => ({
              ...prev,
              showAddFieldModal: false,
            }));
            setCurrentIndex(null);
          }}
          handleAddCustomFeild={handleAddCustomFeild}
          handleUpdateCustomFeild={handleUpdateCustomFeild}
          currentIndex={currentIndex}
          custom_fields={custom_fields}
          removeField={removeField}
        />
      )}
    </Drawer>
  );
};
export default Index;
