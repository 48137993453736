import { useAPI } from "../hooks/useAPI";
import { useRecoilValue } from "recoil";
import { authAtom } from "store/authRecoil";
import {
  createPaymentLinkAtom,
  linksFilterAtom,
  linksTableSettingsAtom,
} from "store/linksRecoil";
import { organizationAtom } from "store/organizationRecoil";
import { settingsAtom } from "../store/settingsRecoil";
import { omit } from "lodash";
import { stringify as QSStringify } from "qs";
import {
  cleanCustomFields,
  filterNonNullForObject,
  getMinDate,
  api_date,
  formatAmountInput,
} from "../utils/helpers";
import { ENV } from "config/env";
import { isValidPhoneNumber } from "libphonenumber-js";

function useLinksService() {
  const { call, cancel_request, ...rest } = useAPI();
  const auth = useRecoilValue(authAtom);
  const { search, status, from, to } = useRecoilValue(linksFilterAtom);
  const { page, count } = useRecoilValue(linksTableSettingsAtom);
  const { testMode } = useRecoilValue(settingsAtom);
  let paymentLink = useRecoilValue(createPaymentLinkAtom);
  const orgInformation = useRecoilValue(organizationAtom);

  function checkStatus(array) {
    if (array.length === 0) {
      return null; // Array is empty
    }

    let hasActive = false;
    let hasInactive = false;
    let hasAll = false;

    for (let i = 0; i < array.length; i++) {
      if (array[i] === "active") {
        hasActive = true;
      } else if (array[i] === "inactive") {
        hasInactive = true;
      } else if (array[i] === "all") {
        hasAll = true;
      }

      // Check if both active and inactive are present, or if 'all' is present
      if ((hasActive && hasInactive) || hasAll) {
        return null;
      }
    }

    // Return true if array contains "active", false if it contains "inactive"
    return hasActive ? true : false;
  }

  return {
    load_links_data: () => {
      let dataToSend = QSStringify(
        filterNonNullForObject({
          testMode,
          organizationId: orgInformation?.id,
          page,
          count,
          query: search,
          active: checkStatus(status),
          from: from ? api_date(from, true) : null,
          to: to ? api_date(to) : null,
        })
      );
      return call({
        link: `/search/payment-links?${dataToSend}`,
      });
    },
    markLinkAsExpired: (id) => {
      let dataToSend = QSStringify(
        filterNonNullForObject({
          testMode,
          organizationId: orgInformation?.id,
        })
      );
      return call({
        link: `/payment-links/${id}/expire?${dataToSend}`,
        method: "DELETE",
      });
    },
    resendSmsMessage: (linkUID) => {
      return call({
        link: `/links/${linkUID}/resend_sms`,
        method: "PUT",
      });
    },
    createPaymentLink: () => {
      let canSendCustomer = false;
      const { name, email, phone } = paymentLink.customer || {};

      // set default value to min date
      const _expiresAt = paymentLink.expiresAt || getMinDate();

      // to remove empty custom fields create by user
      // we need to delete chosen and selected  add by sortable
      const _custom_fields = cleanCustomFields(paymentLink?.custom_fields);

      // if flex payment add amount custom field
      if (paymentLink.dynamicAmount) {
        _custom_fields.unshift({
          name: "amount",
          type: "amount",
        });
      }

      // check if we can send customer object
      if (name?.length > 0 || email?.length > 0 || phone?.length > 0) {
        canSendCustomer = true;
      }
      // omit toggles/loading/isCustom unwanted fields
      const data = {
        expiresAt: _expiresAt,
        ...omit(paymentLink, [
          "toggleCustomer",
          "toggleAddDetails",
          "linkValidity",
          "loading",
          "currency",
          "isCustomLinkValidity",
          "validity_date",
          "validity_duration",
          "expiresAt",
          "showAddCustomerModal",
          "showAddFieldModal",
          "amount",
          "oldCustomer",
          "customer",
          "custom_fields",
          "webhookUrl",
          "redirectUrl",
          "notifyWithSms",
          "toggleRedirection",
          "toggleMetaData",
          "metaData",
          "phone",
          "maxAmount",
          "allowMaxAmount",
        ]),
        amount: {
          value: paymentLink.dynamicAmount
            ? parseFloat("0.0").toFixed(2)
            : parseFloat(paymentLink.amount).toFixed(2),
          currency: paymentLink.currency,
        },
        ...(paymentLink.webhookUrl !== ""
          ? { webhookUrl: paymentLink?.webhookUrl }
          : {}),
        ...(paymentLink.redirectUrl !== ""
          ? { redirectUrl: paymentLink?.redirectUrl }
          : { redirectUrl: ENV.PAYLINK_PREFIX + "/success" }),

        notifyWithSms: isValidPhoneNumber(paymentLink.phone),

        ...(isValidPhoneNumber(paymentLink.phone)
          ? { phone: paymentLink?.phone }
          : {}),
        ...(Object.keys(paymentLink.metaData).length
          ? { metadata: paymentLink.metaData }
          : {}),

        customizationOptions: [
          {
            allowDynamicAmount: paymentLink.dynamicAmount,
            maxAmount:
              paymentLink.dynamicAmount &&
              paymentLink.allowMaxAmount &&
              paymentLink.maxAmount != null &&
              paymentLink.maxAmount > 0
                ? parseFloat(paymentLink.maxAmount).toFixed(2)
                : null,
          },
        ],
      };

      let dataToSend = QSStringify(
        filterNonNullForObject({
          testMode,
          organizationId: orgInformation?.id,
        })
      );

      return call({
        link: `/payment-links?${dataToSend}`,
        method: "POST",
        data: {
          ...(canSendCustomer
            ? { ...data, fields: _custom_fields }
            : {
                ...omit({ ...data, fields: _custom_fields }, ["customer"]),
              }),
        },
      });
    },
    load_payment_link_info: (id) => {
      let dataToSend = QSStringify(
        filterNonNullForObject({
          testMode,
          organizationId: orgInformation?.id,
        })
      );
      return call({
        link: `/search/payment-links/${id}?${dataToSend}`,
      });
    },
    ...rest,
  };
}

export { useLinksService };
