import {useAPI} from "../hooks/useAPI";
import Cookies from "js-cookie"
import {useRecoilValue, useSetRecoilState} from "recoil";
import {checkoutLinkDataAtom, customerFormAtom, feedbackContentAtom} from "../store/checkoutRecoil";
import {useTranslation} from "react-i18next";
import {ENV} from "../config/env";
import {omit} from "lodash";

function useCheckoutService() {
    const {call, ...rest} = useAPI();


    return {
        createPaymentForPaymentLink: (linkUID, customer) => call({
            link: `/links/${linkUID}/payment`,
            method: 'POST',
            data: {
                ...customer
            }
        }),
        submitCheckout: (data) => call({
            link: "/live/checkout",
            baseURL: ENV.CHECKOUT_API_URL,
            data: {
                ...omit(data, ["expiryDate", "continueFormData"])
            },
            method: "POST"
        }),

        check_mastercard_status: (testMode = true) => call({
            link: `/payments/status`,
            baseURL: ENV.CHECKOUT_API_URL,
            method: "GET"
        }),

        check_transaction_result: (_tid) => call({
            link: `/payments/checkResult/${_tid}`,
            baseURL: ENV.CHECKOUT_API_URL,
            method: "GET"
        }),
        load_data: (uid) => call({
            link: `/links/${uid}/preview`,
        }),

        ...rest
    }
}


export {
    useCheckoutService,
};