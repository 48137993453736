import {animateScroll} from "react-scroll";

export function useScrollTop() {
    /**
     *
     * @param {Document} element
     */
    function scrollTop(element = null) {
        if (element) {
            element.scroll(0, 0)
        } else {
            animateScroll?.scrollToTop({
                duration: 500,
                smooth: true,
            })
        }
    }

    return {scrollTop}
}