import React, {useEffect, useRef } from 'react';
import {Placeholder} from "rsuite";

const {Grid} = Placeholder

function WaitingField({wait, height = 36, ...props}) {
    const ref = useRef(null);
    useEffect(() => {

    }, [wait])
    if (wait)
        return (
            <Grid
                ref={ref}
                columns={1}
                active
                rowHeight={height}
                rows={props.children.length || 1}/>
        );
    return props.children
}

export default WaitingField;