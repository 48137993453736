import { atom } from "recoil";

export const companyAtom = atom({
  key: "companyAtom",
  default: {
    fullName: "",
    legalName: "",
    nationalityCode: "",
    idNumber: "",
    isUbo: true, // keep this for default value
    agree: false,
  },
});
export const profileAtom = atom({
  key: "profileAtom",
  default: {
    url: "",
    displayName: "",
    email: "",
    phone: "",
    categoryCode: "",
    productsAndServices: "",
    targetCustomer: "",
    volumeEstimate: "",
  },
});
export const payoutAccountAtom = atom({
  key: "payoutAccountAtom",
  default: {
    bankName: "",
    accountName: "",
    iban: "",
  },
});
export const documentsCrFileAtom = atom({
  key: "documentsCrFileAtom",
  default: [],
});
export const documentsQidDocumentsAtom = atom({
  key: "documentsQidDocumentsAtom",
  default: [],
});

export const documentsBankAccountStatementAtom = atom({
  key: "documentsBankAccountStatementAtom",
  default: [],
});
export const computerCardDocumentAtom = atom({
  key: "computerCardDocumentAtom",
  default: [],
});
export const documentsOtherDocumentsAtom = atom({
  key: "documentsOtherDocumentsAtom",
  default: [],
});

export const documentsUploadedAtom = atom({
  key: "documentsUploadedAtom",
  default: false,
});

export const loadingStateAtom = atom({
  key: "loadingStateAtom",
  default: true,
});
