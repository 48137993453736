import React from 'react';
import styles from "pages/checkout/checkout.module.scss";
import {useTranslation} from "react-i18next";
import {Image} from "components";

const Index = ({className}) => {

    const {t} = useTranslation("pages");

    return (
        <div className={`${className} ${styles.infoSectionFooter}`}>
            <Image src={"checkout_footer.svg"} alt="Dibsy"/>
        </div>
    );
};

export default Index;