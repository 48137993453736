import {Schema} from "rsuite";
import i18n from "../i18n";

const {StringType} = Schema.Types;

const model = Schema.Model({
    email: StringType()
        .isEmail(i18n.t("pages:forgot_password.schema.invalidEmail"))
        .isRequired(i18n.t("pages:forgot_password.schema.requiredEmail")),
});


export default  model