import { atom } from "recoil";

export const organizationAtom = atom({
  key: "organisationAtom",
  default: {
    displayName: "",
    id: "",
    legalIdentity: {},
    displayName: "",
    legalRepresentative: {},
    onboarding: {},
    payout: {},
    profile: {},
    reseller: false,
    statementDescriptor: "",
    testMode: false,
  },
});
