import * as React from "react";

function SvgUnauthorizeIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.708 14.417A6.709 6.709 0 107.708 1a6.709 6.709 0 000 13.417zM2.967 2.965l9.486 9.486"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgUnauthorizeIcon;
