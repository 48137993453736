import React, { useEffect, useState} from 'react';
import styles from "./CheckoutForm.module.scss";
import {Message} from "rsuite";
import {CHARGE_METHOD} from "../../config/constants";
import {useTranslation} from "react-i18next";
import CreditAmexMethod from "./methods/CreditAmexMethod";
import DebitCardMethod from "./methods/DebitCardMethod";
import ApplePayMethod from "./methods/ApplePayMethod";
import {useParamsRoute} from "../../hooks/useParamsRoute";
import ApplePayModal from "./apple-pay-modal";
import loadjs from "xp-loadjs";
import {STATUSES} from "../../utils/enums";
import {isIOS} from "../../utils/helpers";
import {useSetRecoilState} from "recoil";
import {feedbackContentAtom} from "../../store/checkoutRecoil";

/**
 *
 * @param amount
 * @param currency
 * @param linkUID
 * @param checkoutButtonColor
 * @param publicKey
 * @param methods
 * @param paymentToken
 * @param serverResponse
 * @param callback
 * @param beforeSubmit
 * @param testMode
 * @param label
 * @param setServerResponse
 * @returns {JSX.Element|null}
 * @constructor
 */
function CheckoutForm({
                          amount,
                          currency,
                          linkUID,
                          checkoutButtonColor,
                          publicKey,
                          methods,
                          paymentToken,
                          serverResponse,
                          callback,
                          beforeSubmit,
                          testMode,
                          label,
                          setServerResponse
                      }) {
    const [defaultChargeMethod] = useParamsRoute('method', null)
    const [checkedMethod, setCheckedMethod] = useState(defaultChargeMethod);
    const [APModalVisible,setAPModalVisible]=useState(false);
    const updateFeedbackContent = useSetRecoilState(feedbackContentAtom)

    const {t} = useTranslation("pages");
    const canRenderCardForm = () => {
        return methods[CHARGE_METHOD.CREDIT_CARD]
    }
    useEffect(() => {
        // init the apple pay
        initApplePay().then();
        // check a default payment method
        //when setting default payment and payment method exist no need reset it
        if (!checkedMethod || Object.entries(CHARGE_METHOD).findIndex((value) => String(defaultChargeMethod) === value[1]) < 0) {

            // if there is applepay and in mobile show modal first
            if(methods[CHARGE_METHOD.APPEL_PAY] && isIOS()){
            //if(methods[CHARGE_METHOD.APPEL_PAY]){
                // show modal
                setAPModalVisible(true);
            }else if (canRenderCardForm()) {
                setCheckedMethod(CHARGE_METHOD.CREDIT_CARD)
            } else {
                if (methods[CHARGE_METHOD.DEBIT_CARD])
                    setCheckedMethod(CHARGE_METHOD.DEBIT_CARD)
            }
        }

    }, [paymentToken])

    if (!methods)
        return null;


    const isNoMethodActive = React.useMemo(() => {
        return Object.keys(methods).length === 0
    }, [methods])

    const NoActiveMethodComponent = <Message showIcon type="error" className={"mt-3"}
                                             description={t('link_checkout.not_active_methods')}/>

    const handleMethodChange = (method) => {
        setCheckedMethod(method)
    }

    async function initApplePay() {
        try {
            await loadjs("https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js");
        } catch (ex) {
            console.log("Error while loading Apple Pay JavaScript SDK.")
        }
    }

 /*   const setFeedbackContent = (isVisible = false, success = false, feedbackText = "", feedbackTitle = "") => {
        updateFeedbackContent({isVisible, feedbackText, success, feedbackTitle})
    }*/

  /*  const onApplePayButtonClicked = () =>  {

        if (!window.ApplePaySession) {
            return;
        }

        const request = {
            countryCode: "QA",
            currencyCode: "QAR",
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: ["visa", "masterCard"],
            total: {
                label,
                type: "final",
                amount,
            },
        };

        const session = new ApplePaySession(3, request);
        let dibsySessionId = null;

        session.onvalidatemerchant = (event) => {
            applePayService.validateMerchant(publicKey).then(res => {
                session.completeMerchantValidation(res?.data?.merchantSession);
                dibsySessionId = res?.data?.sessionId;
            }).catch(err => {
                setServerResponse("Error fetching merchant session")
            })
        };

        session.onpaymentauthorized = (event) => {
            applePayService.processPayment(publicKey, JSON.stringify(event.payment.token), dibsySessionId,linkUID,paymentToken).then(res => {

                let data = res?.data;
                let paymentStatus = {
                    status: 1,
                };
                if (!data?.error && data?.payment?.status === STATUSES.SUCCEEDED) {
                    // show success feedback
                    setFeedbackContent(true, true,
                        t("link_checkout.feedbacks.success_payment_text"), t("link_checkout.feedbacks.link_success_title"))
                    paymentStatus.status = 0;
                } else {
                    setFeedbackContent(true, false,
                        t("link_checkout.feedbacks.failed_payment_text"), t("link_checkout.feedbacks.link_failed_title"))
                }
                session.completePayment(paymentStatus);
            }).catch(err => {

                setFeedbackContent(true, false,
                    t("link_checkout.feedbacks.failed_payment_text"), t("link_checkout.feedbacks.link_failed_title"));
                session.completePayment({
                    status: 1
                });
            })
        };

        session.oncancel = (event) => {
            setServerResponse("Payment has been cancelled")
        };

        session.begin();
    }
*/
    /**
     *
     * @return {[any]}
     */
    const renderActiveMethods = () => {
        if (!methods) return []
        if (isNoMethodActive) {
            return [NoActiveMethodComponent]
        }
        const activeMethods = []
        if (canRenderCardForm()) {
            activeMethods.push(
                <CreditAmexMethod
                    key={CHARGE_METHOD.CREDIT_CARD}
                    isChecked={checkedMethod === CHARGE_METHOD.CREDIT_CARD}
                    handleMethodChange={handleMethodChange}
                    amount={amount}
                    currency={currency}
                    checkoutButtonColor={checkoutButtonColor}
                    publicKey={publicKey}
                    methods={methods}
                    paymentToken={paymentToken}
                    callback={callback}
                    beforeSubmit={beforeSubmit}
                    testMode={testMode}
                />
            )
        }

        if (methods[CHARGE_METHOD.DEBIT_CARD] && !testMode) {
            activeMethods.push(
                <DebitCardMethod
                    key={CHARGE_METHOD.DEBIT_CARD}
                    checkoutButtonColor={checkoutButtonColor}
                    isChecked={checkedMethod === CHARGE_METHOD.DEBIT_CARD}
                    handleMethodChange={handleMethodChange}
                    paymentToken={paymentToken}
                    publicKey={publicKey}
                />
            )
        }

        if (methods[CHARGE_METHOD.APPEL_PAY] && !testMode && window?.ApplePaySession) {
        //if (methods[CHARGE_METHOD.APPEL_PAY] && !testMode) {
            activeMethods.push(
                <ApplePayMethod
                    key={CHARGE_METHOD.APPEL_PAY}
                    isChecked={checkedMethod === CHARGE_METHOD.APPEL_PAY}
                    handleMethodChange={handleMethodChange}
                    paymentToken={paymentToken}
                    publicKey={publicKey}
                    amount={amount}
                    label={label}
                    setServerResponse={setServerResponse}
                />
            )
        }
        return activeMethods
    }


    return <div id={"checkout-wrapper"} className={styles.cardWrapper}>
        {
            serverResponse?.message ?
                <div className={styles.messageContainer}>
                    <Message showIcon type="error" className={"mt-3"} description={serverResponse?.message}/>
                </div> : null
        }
        {renderActiveMethods()}
       <ApplePayModal
           amount={amount}
           label={label}
           setServerResponse={setServerResponse}
           paymentToken={paymentToken}
           publicKey={publicKey}
           visible={APModalVisible}
           setVisible={setAPModalVisible}  />
    </div>
}

export default CheckoutForm
