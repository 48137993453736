import React, {useEffect} from 'react';
import styles from "./Message.module.scss"
import {useTranslation} from "react-i18next";
import {Image} from "../index";


/**
 *
 * @param content
 * @param image
 * @param type
 * @param className
 * @param withBorder
 * @returns {JSX.Element}
 * @constructor
 */
function Message({content, image="payout_message.svg", type,className,noBorder=false}) {

    const {t}=useTranslation("components");

    useEffect(() => {

    }, [])



    return (
        <div datatype={type} className={`${className} ${styles.container} ${noBorder && styles.removeBorder }`}>
            {
                image && <Image src={image} alt={t("message.altImg")}/>
            }
            <span>
                {content}
            </span>
        </div>
    );
}

export default Message;