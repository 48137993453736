import React, { useEffect } from "react";
import styles from "./CompanyAvatar.module.scss";
import { Avatar, Icon } from "rsuite";
import { useTranslation } from "react-i18next";
import { capitalize } from "utils/helpers";

function CompanyAvatar(props) {
  const { organization, showOrgs, setShowOrgs, organizationsList } = props;
  const { t } = useTranslation("components");
  useEffect(() => {}, [organization]);
  return (
    <div className={styles.companyInformation}>
      <div className={styles.companyLogo}>
        <Avatar className={styles.companyLogoChild}>
          {capitalize(organization?.displayName?.charAt(0)) || "U"}
        </Avatar>
        <div className={styles.companyLogoInformation}>
          <p>{organization?.displayName || "Unnamed"}</p>
          <span>
            {t("companyAvatar.id")} #{organization?.id}
          </span>
        </div>
      </div>
      <div
        className={styles.arrowsContainer}
        onClick={() => {
          setShowOrgs(!showOrgs);
        }}
      >
        {organizationsList?.length &&
          (showOrgs ? <Icon icon="angle-up" /> : <Icon icon="angle-down" />)}
      </div>
    </div>
  );
}

export default CompanyAvatar;
