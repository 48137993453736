import React from "react";
import styles from "./mobile-filter.module.scss";
import { FlexboxGrid, SelectPicker } from "rsuite";
import { useRecoilState } from "recoil";
import {
  dateToISO,
  getFirstDayOfMonth,
  getTodayISODate,
  getYesterdayISODate,
  getYesterdayEndISODate,
  startOfWeek,
} from "utils/helpers";
import { useTranslation } from "react-i18next";
import { useAPI } from "hooks/useAPI";
import { Calendar } from "../../index";

const Index = ({ filterAtom, tableSettingsAtom, periods }) => {
  const { t } = useTranslation("pages");
  const { cancel_request } = useAPI();
  const [filters, setFilters] = useRecoilState(filterAtom);
  const [tableSettings, updateTableSettings] = useRecoilState(
    tableSettingsAtom
  );
  let { from, to, period, isCustomPeriod, custom_period } = filters;

  const selectChange = (value) => {
    period = value;
    if (value === "custom") {
      isCustomPeriod = true;
      setFilters({ ...filters, period, isCustomPeriod });
    } else {
      isCustomPeriod = false;
      from = 0;
      to = dateToISO(new Date());
      switch (value) {
        case "today":
          from = getTodayISODate();
          break;
        case "yesterday":
          from = getYesterdayISODate();
          to = getYesterdayEndISODate();
          break;
        case "week":
          from = startOfWeek();
          break;
        case "month":
          from = getFirstDayOfMonth();
          break;
        default:
          break;
      }
      setFilters({ ...filters, from, to, period, isCustomPeriod });
      reloadData();
    }
  };

  const calendarChange = (value) => {
    value[0].setHours(0, 0, 0, 0);
    value[1].setHours(23, 59, 59, 59);
    from = dateToISO(value[0]);
    to = dateToISO(value[1]);
    custom_period = value;
    setFilters({ ...filters, custom_period, from, to });
    reloadData();
  };

  const reloadData = () => {
    cancel_request("Filter changed");
    updateTableSettings({
      ...tableSettings,
      reload: !tableSettings.reload,
      page: 1,
    });
  };

  return (
    <FlexboxGrid align="middle" className={styles.selectFilter}>
      <p className={`mb-2 ${styles.period}`}>
        {t("balance.filter_mobile.periodLabel")}:
      </p>
      <SelectPicker
        onChange={(value) => {
          selectChange(value);
        }}
        className={"w-100"}
        searchable={false}
        cleanable={false}
        placeholder={t("balance.filter_mobile.periodPlaceholder")}
        data={periods}
      />
      {isCustomPeriod ? (
        <Calendar
          onChange={calendarChange}
          className={"mobile"}
          value={custom_period}
          name={"custom_period"}
        />
      ) : null}
    </FlexboxGrid>
  );
};
export default Index;
