import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "./CostumePhoneInput.scss";

/**
 *
 * @param value
 * @param onChange
 * @param className
 * @param name
 * @returns {JSX.Element}
 * @constructor
 */
function CostumePhoneInput({
  value,
  onChange,
  className,
  name,
  disabled = false,
}) {
  useEffect(() => {}, []);
  const _onChange = (value, _, e) => {
    onChange && onChange(`+${value}`, e);
  };
  return (
    <div className={`${className} phone-number-input-container`}>
      <PhoneInput
        disabled={disabled}
        preferredCountries={["qa"]}
        masks={{ qa: ".... ...." }}
        inputProps={{ name }}
        country={"qa"}
        value={value}
        onChange={_onChange}
      />
    </div>
  );
}

export default CostumePhoneInput;
