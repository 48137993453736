import {Schema} from "rsuite";
import i18n from "i18n";
import Payment from "payment"
import * as creditCardType from "credit-card-type";
import {types} from "credit-card-type";
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "../utils/helpers";

const {StringType} = Schema.Types;

const model = Schema.Model({
    cardHolder: StringType().isRequired(i18n.t("validation:cardHolderRequired")),
    cardCode: StringType().isRequired(i18n.t("validation:cvcRequired"))
        .addRule((val, data) => {
            val = formatCVC(val)
            return Payment.fns.validateCardCVC(val)
        }, i18n.t("validation:cvcInvalid")),
    expiryDate: StringType().isRequired(i18n.t("validation:expiryDateRequired"))
        .addRule((val, data) => {
            val = formatExpirationDate(val)
            return Payment.fns.validateCardExpiry(val)
        }, i18n.t("validation:expiryDateInvalid")),
    cardNumber: StringType().isRequired(i18n.t("validation:cardNumberRequired"))
        .addRule((val, data) => {
            val = formatCreditCardNumber(val)
            let card = creditCardType(val);
            let type = card[0]?.type;
            return type === types.VISA || type === types.MASTERCARD || type === types.AMERICAN_EXPRESS;
        }, i18n.t("validation:cardNumberNotSupported"))
        .addRule((val, data) => {
            val = formatCreditCardNumber(val)
            return Payment.fns.validateCardNumber(val);
        }, i18n.t("validation:cardNumberInvalid"))

})
export default model