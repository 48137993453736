import React, { Fragment, useEffect, useState } from "react";
import { Uploader, Button, Progress, Icon, Notification } from "rsuite";
import "react-circular-progressbar/dist/styles.css";
import styles from "./Upload.module.scss";
import { DOCUMENTS_TYPE, IMAGES_TYPE } from "config/constants";
import { useCanBreakPointMobile } from "hooks/useViewport";
import { filenameToExtension } from "utils/helpers";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { fileNameFromKey } from "utils/helpers";

//
import { useDocumentsService } from "services/OnBoardingService";

const UploadWrapper = styled.div``;
UploadWrapper.defaultProps = {
  percentage: 0,
};

/**
 *
 * @param {string} url
 * @param className
 * @param {number} maxFiles
 * @param {boolean} multiple
 * @param {"any"|"document"|"image"} type
 * @param {boolean} disabled
 * @param percentage
 * @param defaultValue
 * @param {number} maxSizeLimit Max allowed file size in MB
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
function Upload({
  url = "",
  headers = {},
  data = {},
  className = "",
  maxFiles = -1,
  multiple = false,
  type = "any",
  disabled = false,
  percentage = 0,
  defaultValue = null,
  maxSizeLimit = 10,
  onUpload = () => {},
  onChange = () => {},
  onError = () => {},
  onSuccess = () => {},
  isCheckout = false,
  shouldUpload = false,
  fileListVisible = true,
  shouldQueueUpdate = false,
  onRemove = () => {},
}) {
  const [fileList, setFileList] = useState([]);
  const breakPoint = useCanBreakPointMobile();
  const { t } = useTranslation(["components", "pages"]);

  const docService = useDocumentsService();

  useEffect(() => {
    if (defaultValue) setFileList(defaultValue);
  }, []);

  /**
   *
   * @param {[FileType]} list
   */
  const checkIfRespectMaxFileSize = (list) => {
    for (const file of list) {
      const sizeInMB = file?.blobFile?.size / (1000 * 1000);
      if (sizeInMB > maxSizeLimit) {
        return false;
      }
    }
    return true;
  };

  /**
   *
   * @param {[FileType]} list
   */
  const onChangeHandler = (list) => {
    if (isCheckout) {
      list = [list[list.length - 1]];
      sendChange(list);
    }
    setFileList(list);
  };

  const shouldUploadHandler = (list) => {
    if (isCheckout) {
      list = [list[list.length - 1]];
    }
    if (disabled) return false;
    if (!checkIfRespectMaxFileSize(list)) {
      Notification.error({
        title: t("upload.notificationTitle"),
        description: `${t("upload.maxFileSize")} ${maxSizeLimit}MB`,
      });

      return false;
    }
    if (maxFiles >= 0) {
      if (list.length <= maxFiles) {
        if (!isFileTypeAccepted(list)) {
          Notification.error({
            title: t("upload.notificationTitle"),
            description: t("upload.fileNotMatch"),
          });

          return false;
        }
      } else {
        return false;
      }
    }
  };

  const sendChange = (list) => {
    if (list?.length > 0) {
      if (onChange) {
        onChange(
          maxFiles === 1 ? list[0].blobFile : list.map((e) => e.blobFile)
        );
      }
    } else {
      onChange && onChange(null);
    }
  };

  const viewFile = (file) => {
    if (!file?.status === "finished") return;
    const path = getFilePath(file);
    if (path) {
      window.open("https://" + path);
    } else {
      onError(
        "there was an error while trying to open the file, please try again later."
      );
    }
  };

  const getFilePath = (file) => {
    if (file?.path) {
      return file?.path;
    }

    let tempList = fileList;
    let path = null;
    tempList.forEach((f, index) => {
      if (f?.fileKey === file?.fileKey) {
        path = f?.path;
      }
    });

    if (path) {
      return path;
    }

    return null;
  };

  /**
   *
   * @param {FileType} file
   */

  const onUploadHanler = (file) => {
    if (onUpload) {
      onUpload(file?.fileKey);
    }
  };

  const onRemoveHandler = (file) => {
    let _file = file;
    setFileList(
      fileList.filter((f) => {
        file?.servKey !== f?.servKey;
      })
    );
    if (onRemove) {
      onRemove(file);
    }
    if (file?.status !== "finished") {
      return;
    }
    if (!file?.servKey)
      _file = fileList.filter((f) => file?.fileKey === f?.fileKey)[0] || [];

    docService
      .deleteDocument({
        name: data?.name,
        key: _file?.servKey,
      })
      .then((res) => {});
  };

  const onErrorHandler = (error, file) => {
    if (onError) {
      onError(
        "there was an error while trying to upload the file, please try again later.",
        file
      );
    }
  };

  const onSuccessHandler = (res, file) => {
    let tempList = fileList;
    tempList.forEach((f, index) => {
      if (f?.fileKey === file?.fileKey) {
        const _file = res;
        tempList[index] = {
          ...file,
          name: fileNameFromKey(_file?.key),
          progress: 100,
          status: "finished",
          servKey: _file?.key,
          path: _file?.path,
        };
        if (onSuccess) {
          onSuccess(tempList[index]);
        }
      }
    });
    setFileList(tempList);
  };

  const canAddFile = () => {
    if (isCheckout) {
      return true;
    }
    if (maxFiles >= 0) {
      return fileList.length < maxFiles;
    }
    return true;
  };

  /**
   *
   * @param {[FileType]} files
   */
  const isFileTypeAccepted = (files) => {
    if (fileType()) {
      const types = fileType().split(",");
      for (let file of files) {
        let ext = filenameToExtension(file.name);
        if (!types.includes(ext)) return false;
      }
      return true;
    }
    return true;
  };

  const fileType = () => {
    switch (type) {
      case "document":
        return DOCUMENTS_TYPE;
      case "image":
        return IMAGES_TYPE;
      case "any":
      default:
        return null;
    }
  };

  const renderIcon = (file) => {
    const status = file?.status;
    switch (status) {
      case "finished":
        return "check";
        break;
      case "error":
        return "remind";
        break;

      default:
        return "file-upload";
        break;
    }
  };

  const getPercent = (status, percent) => {
    switch (status) {
      case "inited":
        return 0;
        break;

      case "uploading":
        let temp = Math.floor(percent - 10);
        if (temp < 0) {
          return 0;
        } else {
          return temp;
        }
        break;

      case "error":
        return 0;
        break;

      case "finished":
        return 100;
        break;

      default:
        return 0;
        break;
    }
  };

  return (
    <UploadWrapper percentage={percentage} className={styles.outerContainer}>
      <Uploader
        className={`${className} ${styles.mobileBreakPoint}`}
        draggable={!breakPoint}
        action={url}
        headers={headers}
        data={data}
        accept={fileType()}
        shouldUpload={(file) => shouldUpload}
        fileListVisible={fileListVisible}
        shouldQueueUpdate={(list, file) => {
          return shouldUploadHandler(list);
        }}
        defaultFileList={defaultValue || []}
        onUpload={(file) => {
          onUploadHanler(file);
        }}
        onChange={(list) => {
          onChangeHandler(list);
        }}
        onRemove={onRemoveHandler}
        onError={(error, file) => {
          onErrorHandler(error, file);
        }}
        onSuccess={(res, file) => {
          onSuccessHandler(res, file);
        }}
        disabled={!canAddFile() /*|| disabled*/}
        multiple={multiple}
        renderFileInfo={(file) => {
          return (
            <Fragment>
              <div
                className={`${styles.iconContainer} ${styles[file?.status]}`}
              >
                <Icon icon={renderIcon(file)} />
              </div>
              <div className={styles.loadingContainer}>
                <div>
                  <p className={styles.itemSelectList}>
                    <span
                      className={`${styles.fileName} ${styles[file?.status]}`}
                      onClick={() => {
                        viewFile(file);
                      }}
                    >
                      {file.name}
                    </span>
                    <span className={styles.percent}>
                      {getPercent(file?.status, file?.progress) || 0}%
                    </span>
                  </p>
                </div>
                <Progress.Line
                  percent={getPercent(file?.status, file?.progress) || 0}
                  showInfo={false}
                  strokeWidth={5}
                />
              </div>
            </Fragment>
          );
        }}
      >
        <div className="w-100">
          <div className={styles.container}>
            <Button
              appearance={"primary"}
              className={styles.uploaderTriggerBtn}
            >
              {"Browse"}
            </Button>
            {!breakPoint && (
              <p className={isCheckout && "text-danger"}>
                {" "}
                {!isCheckout
                  ? "Drop your file here to upload or select from storage " +
                    fileType()
                  : t("pages:settings.checkout.uploadPlaceholder")}
              </p>
            )}
          </div>
        </div>
      </Uploader>
    </UploadWrapper>
  );
}

export default Upload;
