import countries from "assets/data/countries.json";
import currencies from "assets/data/currencies.json";
import { Notification } from "rsuite";
import i18n from "../i18n";
import { isBoolean, pick } from "lodash";
import CryptoAES from "crypto-js/aes";
import { ENV } from "../config/env";
import Payment from "payment";
import * as ct from "countries-and-timezones";
import { DateTime } from "luxon";
import moment from "moment";
import { permessions, interfaces } from "./enums";
import { Badge, Tag, Icon } from "rsuite";

import { CHARGE_METHOD, TEST_MODE_PREFIX } from "config/constants";

export const Locale = {
  sunday: i18n.t("components:calendar.sunday"),
  monday: i18n.t("components:calendar.monday"),
  tuesday: i18n.t("components:calendar.sunday"),
  wednesday: i18n.t("components:calendar.wednesday"),
  thursday: i18n.t("components:calendar.thursday"),
  friday: i18n.t("components:calendar.friday"),
  saturday: i18n.t("components:calendar.saturday"),
  ok: i18n.t("components:calendar.ok"),
};

export function parseErrors(check) {
  let errors = {};
  for (let key of Object.keys(check)) {
    if (check[key]?.hasError) {
      errors[key] = check[key]?.errorMessage;
    }
  }
  return errors;
}

export function errorClassStyle(error) {
  return error && "input-error";
}

// Capitalize
export function capitalize(string) {
  if (!string) return;
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/[-_]+/g, " ")
    .replace(/[^\w\s]/g, "")
    .replace(/ (.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/ /g, "");
}

export function objectToCamelCase(origObj) {
  return Object.keys(origObj).reduce(function (newObj, key) {
    let val = origObj[key];
    newObj[toCamelCase(key)] =
      typeof val === "object" ? objectToCamelCase(val) : val;
    return newObj;
  }, {});
}

export function createAcronym(text) {
  if (!text) return;
  return text.split(/\s/).reduce(function (accumulator, word) {
    return word.length > 2 ? accumulator + word.charAt(0) : accumulator;
  }, "");
}

export function getErrorMessage(err) {
  let messageError = "Internal Sever Connection";
  if (err.response !== undefined) {
    if (err.response.data.statusCode === 400) {
      messageError = "Invalid  parameters";
    } else {
      messageError = err.response.data.message;
    }
  }

  return typeof messageError === "object"
    ? "Internal Sever Connection"
    : messageError;
}

export function isBigger(dateStr) {
  let today = new Date();
  let customDate = new Date(dateStr);
  return customDate > today;
}

export function filenameToExtension(filename) {
  return "." + filename.split(".").pop();
}

export function fullNameShort(name) {
  if (!name) return "U";
  return name
    ?.trim()
    .split(" ")
    .slice(0, 2)
    .map((e) => String(e[0]).toUpperCase())
    .join("");
}

/**
 *
 * @param {Date} date
 * @param timeZone
 * @returns {Date}
 */
export const format_dateISO = (date, timeZone = "Asia/Qatar") => {
  const d = DateTime.fromISO(dateToISO(date, true)).toString();
  let _date = String(d);
  _date = _date.substring(0, _date.indexOf("+"));
  _date = _date.substring(0, _date.indexOf("T"));
  return new Date(_date);
};

/**
 *
 * @param {Date} date
 * @param timeZone
 * @returns {Date}
 */
export const format_dateTimeISO = (date, timeZone = "Asia/Qatar") => {
  const d = DateTime.fromISO(dateToISO(date, true)).toString();
  let _date = String(d);
  _date = _date.substring(0, _date.indexOf("+"));
  return new Date(_date);
};

export const format_datetime = (_date, timeZone = "Asia/Qatar") => {
  if (_date) {
    let date;
    try {
      date =
        _date?.endsWith("+00:00") || _date?.endsWith("Z")
          ? _date
          : _date + "+00:00";
    } catch {
      date = _date;
    }
    date = new Date(date);
    const timeZoneOffset =
      date.getTimezoneOffset() === new Date().getTimezoneOffset()
        ? 0
        : new Date().getTimezoneOffset();
    date.setHours(date.getHours() - timeZoneOffset / 60);
    try {
      return new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        //timeZone,
      }).format(Date.parse(date));
    } catch (ex) {
      return null;
    }
  } else {
    return null;
  }
};

export const date_time_compare = (date1, date2 = new Date()) => {
  if (!date1) {
    return;
  }
  let _date = date1?.endsWith("+00:00") ? date1 : date1 + "+00:00";
  _date = new Date(_date);
  return date2 > _date;
};

export const format_date_only = (date, timeZone = "Asia/Qatar") => {
  if (date) {
    try {
      return new Intl.DateTimeFormat("en-GB", {
        dateStyle: "full",
        //timeZone,
      }).format(Date.parse(date));
    } catch (ex) {
      return null;
    }
  } else {
    return null;
  }
};

export const getCountryNameByCode = (code) => {
  if (code) {
    let country = countries.filter((item) => item.Code === code);
    return country.length > 0 ? country[0].Name : "";
  } else {
    return "";
  }
};

export function startOfWeek() {
  //this normally returns from sunday, but we want from monday
  return moment().startOf("week").toDate();
}

export function getFirstDayOfMonth() {
  return moment().startOf("month").toDate();
}

/**
 *
 * @param {any} date
 * @param {boolean} toString
 * @return Date | string
 */
export function dateToISO(date, toString = false) {
  if (!toString) return moment(date, moment.ISO_8601).toDate();
  return moment(date, moment.ISO_8601).toISOString();
}

/**
 * @param {Date} date
 * @param dateOnly
 * @returns {Date}
 */
export const api_date = (date, dateOnly = false) => {
  let _date = date || new Date("2000-01-01");
  if (_date > new Date()) {
    _date = new Date();
  }
  var utc_date = moment.utc(_date).format("YYYY-MM-DD HH:mm:ss");
  return utc_date;
};

export function getYesterdayISODate() {
  return moment().subtract(1, "days").startOf("day").toDate();
}

export function getYesterdayEndISODate() {
  return moment().subtract(1, "days").endOf("day").toDate();
}

export function getTodayISODate() {
  let today = new Date();
  today.setHours(0, 0, 0, 1); // from midnight
  return today;
}

export function getEndTodayTimeStamp() {
  let today = new Date();
  today.setDate(today.getDate() + 1);
  today.setHours(0, 0, 0, 1); // to midnight
  return today;
}

export function getTomorrowTimestamp() {
  let today = new Date();
  today.setDate(today.getDate() + 1);
  return today;
}

export function pagination(activePage, totalPages) {
  let current = activePage,
    last = totalPages,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

export const formatAmount = (amount) => {
  if (amount) {
    let tens = Math.round(((amount % 1) + Number.EPSILON) * 100) / 100;
    let number = amount >> 0;
    number = number.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    if (tens !== 0) {
      if (tens < 0) {
        tens = tens.toString().substring(2, tens.toString().length);
      } else {
        tens = tens.toString().substring(1, tens.toString().length);
      }
      if (tens.length === 2) {
        // .3 ===> .30
        tens = `${tens}0`;
      }
    } else {
      tens = ".00";
    }
    return { number, tens };
  }
  return "";
};

export const getCurrencySymbolByCode = (code) => {
  if (code) {
    return currencies[code]?.symbol;
  }
  return "";
};

export const getCardImg = (_method, label) => {
  const method = _method?.toLowerCase();
  switch (method) {
    case CHARGE_METHOD.DEBIT_CARD:
      return "naps.svg";
    case CHARGE_METHOD.APPEL_PAY:
      return "ap-logo.svg";
    case CHARGE_METHOD.GOOGLE_PAY:
      return "gp-logo.svg";
    case CHARGE_METHOD.AMEX:
      return "amex.svg";
    case CHARGE_METHOD.CLICK_TO_PAY:
      return "click-to-pay.svg";
    case CHARGE_METHOD.CREDIT_CARD:
      if (!label) {
        return "cardAlt.svg";
      }
      return `${label?.toLowerCase()}.svg`;

    default:
      return "cardAlt.svg";
  }
};

export const getCardTrademarkImg = (cardBrand) => {
  if (!cardBrand) return "cardAlt.svg";

  return `${cardBrand.toLowerCase()}.svg`;
};

export const getFileExtension = (path) => {
  return path.substr(path.lastIndexOf(".") + 1);
};

/**
 *
 * @param {string} link
 */
export const isURL = (link) => {
  if (typeof link != "string") return false;
  return !!link.match(/^https?:\/\/.*/);
};

export const ellipsisText = (text, length) => {
  if (text) {
    return text.substring(0, length) + "...";
  }
  return "";
};

/**
 * Function to calculate expiresAt of payment link
 * @param nb{number} the number of weeks/days,...
 * @param type {string} the type of expires days,weeks,...
 * @returns {number|Date}
 */
export const calculateExpiresAt = (nb, type) => {
  let d = new Date();

  if (nb <= 0) {
    d.setDate(d.getDate() + 1); // set default to tomorrow
    return d;
  }

  switch (type) {
    case "days":
      d.setDate(d.getDate() + nb);
      break;

    case "months":
      d.setMonth(d.getMonth() + nb);
      break;

    case "years":
      return new Date(
        d.getFullYear() + nb,
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes(),
        d.getSeconds()
      ).getTime();

    case "weeks":
      d.setDate(d.getDate() + nb * 7); // in a week there 7 days
      break;
  }

  return d;
};

/**
 * Get list of countries based on json countries list
 * @returns {[]}
 */
export const getListCurrencies = () => {
  const list = Object.keys(currencies);
  let list_select = [];
  list.forEach((item) => {
    list_select.push({ label: item, value: item });
  });
  return list_select;
};

export function filterNonNullForObject(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, val]) => isBoolean(val) || val)
  );
}

/**
 * Function to encrypt text using AES from crypto-js package
 * @param text{string} a text to encrypt
 * @returns {*}
 */
// export const encryptAES = (text) => {
//     if (!text) return;
//     return CryptoAES.encrypt(text, ENV.CRYPTO_KEY).toString()
// }

/**
 * Function to convert enum/object var to object[label,value]
 * @param Obj{object} to object to convert
 * @returns {{label: string, value: *}[]|*[]}
 * @constructor
 */
export const EnumToObjectLabelValue = (Obj) => {
  if (Obj) {
    return Object.keys(Obj).map((el) => {
      return {
        label: el,
        value: Obj[el],
      };
    });
  }

  return [];
};

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

/**
 * function to format card number output based on type ex: 1212121212121212=>1212 1212 1212 1212
 * @param value
 * @returns {string|*}
 */
export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCreditCardNumberMask(bin, last) {
  if (!bin) {
    return "XXXX XXXX XXXX XXXX";
  }
  const issuer = Payment.fns.cardType(bin);

  let nextValue;
  let clearValue;
  switch (issuer) {
    case "amex":
      clearValue = bin + "XXXXX" + (last || "XXXX");
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    default:
      clearValue = bin + "XXXXXX" + (last || "XXXX");
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
      break;
  }

  return nextValue.trim();
}

/**
 * Function to format cvc of card base on type and limit in 3-4 numbers
 * @param value
 * @param prevValue
 * @param allValues
 * @returns {string}
 */
export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

/**
 * Function for format expiry date, ex: 1223 => 12/23
 * @param value
 * @returns {string}
 */
export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

/**
 * format the amount input format
 * @param value
 * @returns {string|*}
 */
export const formatAmountInput = (value) => {
  if (!value) return;
  if (value.indexOf(".") !== -1) {
    let tens = value.split(".")[1];
    if (tens.length > 2) {
      return parseFloat(value).toFixed(2);
    }
  }
  return value;
};

/**
 * @param {number} amount
 * @returns {string}
 */
export const formatBalance = (amount) => {
  return new Intl.NumberFormat().format(amount);
};

/**
 * Check if date in bigger than today
 * @param dateStr
 * @returns {boolean}
 */
export function isBiggerThanToday(dateStr) {
  let today = new Date();
  let customDate = new Date(dateStr);
  return customDate > today;
}

/**
 * Function to get browser details needed for mastercard
 * @returns {{acceptHeaders: string, screenWidth: number, screenHeight: number, "3DSecureChallengeWindowSize": string, timeZone: number, language: string, colorDepth: number}}
 */
export function getBrowserDetails() {
  return {
    "3DSecureChallengeWindowSize": "FULL_SCREEN",
    acceptHeaders: "application/json",
    colorDepth: window.screen.colorDepth,
    language: navigator.language,
    screenHeight: window.innerHeight,
    screenWidth: window.innerWidth,
    timeZone: 273,
  };
}

/**
 * Function to get next payout date base on frequency day
 * @returns {string|string}
 */
export function getNextPayout() {
  const excludeToday = true;
  let refDate = new Date();
  const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
    ENV.NEXT_PAYOUT_FREQUENCY?.slice(0, 3).toLowerCase()
  );
  if (dayOfWeek < 0) return;
  refDate.setHours(0, 0, 0, 0);
  refDate.setDate(
    refDate.getDate() +
      +!!excludeToday +
      ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
  );
  return format_date_only(refDate);
}

/**
 * Function to get the timezone of a client based on his country iso code
 * @param code{string} a valid country ISO code
 * @returns {*}
 */
export const getTimeZoneFromCountryCode = (code) => {
  if (!code) return "Asia/Qatar";
  let obj = ct.getCountry(code?.toUpperCase());
  if (obj) return obj?.timezones[0];
  return "Asia/Qatar"; // we can set a default timezone here
};

/**
 * Function to convert a date/timestamp to specific timezone date
 * @param timeStamp{string} the timestamp to convert
 * @param timeZone{string} the timezone we want to convert to
 * @returns {string} formatted ISO date
 */
export function timestampToISODateByTimezone(
  timeStamp,
  timeZone = "Asia/Qatar"
) {
  if (!timeStamp) return;
  let date = new Date(timeStamp);
  let dateStr = date.toLocaleDateString("en-US", { timeZone });
  return dateToISO(Date.parse(dateStr), true);
}

/**
 *
 * @param {string} uid
 * @returns {boolean}
 */
export function isTestModePrefix(uid) {
  if (!uid) return true;
  return uid.startsWith(TEST_MODE_PREFIX);
}

/**
 *
 * @param callback
 * @param delay
 * @returns {function(): void}
 */
let timeout; // with a global var it will work
export function debounce(callback, delay) {
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
}

export const getDiffTime = (date_from, date_two = new Date()) => {
  if (!date_from) return "";

  let diff =
    (new Date(date_two).getTime() - new Date(date_from).getTime()) / 1000;
  diff /= 60;
  const min = Math.abs(Math.floor(diff));
  return `${min}min ${Math.abs(Math.round(diff % 60))}sec`;
};

export const getCookieValue = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i in ca) {
    let c = ca[i];
    while (c?.charAt(0) === " ") {
      c = c?.substring(1);
    }
    if (c?.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const replaceSpacesUnderscore = (string) => {
  if (!string) return;
  return string.split(" ").join("_").toLowerCase();
};

export const replaceUnderscoreSpaces = (string) => {
  if (!string) return;
  return capitalize(string.split("_").join(" "));
};

export const cleanCustomFields = (array) => {
  if (array?.length < 0) return;
  const _array = pickInArrays(array, [
    "name",
    "isRequired",
    "type",
    "placeholder",
    "options",
    "defaultValue",
  ]);
  return _array?.filter((item) => {
    return item?.name?.length !== 0 && item?.label?.length !== 0;
  });
};

// to create a clone copy of array of obj with specified props
export function pickInArrays(array, props) {
  if (array?.length < 0 || props?.length < 0) return;
  return array?.map(function (obj) {
    return pick(obj, props);
  });
}

/**
 * Check if an object is empty[the props are empty]
 * @param obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
  if (!obj) return true;
  return !Object.values(obj).some((o) => o?.length > 0);
}

/**
 * Validate international phone numbers
 * @param phone
 * @returns {boolean}
 */
export function validatePhoneNumber(phone) {
  if (!phone) return false;
  const regex = /^\+(?:[()0-9-] ?){6,18}[0-9]$/;
  return regex.test(phone);
}

/**
 * Validate emails
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
  if (!email) return false;
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
}

export function getMinDate() {
  let d = new Date();
  d.setMonth(d.getMonth() + 1);
  return d;
}

/**
 *
 * @param pathname
 * @return {boolean}
 */
export function isPrivateRoute({ pathname }) {
  return (
    !String(pathname).startsWith("/pay/") &&
    !String(pathname).startsWith("/payscreen/") &&
    !String(pathname).startsWith("/callback/")
  );
}

export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator?.userAgent) && !window?.MSStream;
}

export function generateString(length = 6) {
  let result = "";
  let characters = "abcdefghijklmnopqrstuvwxyz";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getCognitoCredentials() {
  return {
    UserPoolId: ENV.USER_POOL_ID,
    ClientId: ENV.CLIENT_ID,
  };
}

//

export function errorParser(res, errorBase) {
  let errorDescription = errorBase;

  if (res?.status === 401 || res?.status === 403) {
    return false;
  }

  if (res?.status === 400 && res?.data?.errors) {
    const errors = res?.data?.errors;
    errorDescription = errors.map((err, key) => {
      return <li key={key}>{err?.message}</li>;
    });
  }

  if (res?.status === 422 && res?.data?.errors) {
    const errors = res?.data?.errors;
    errorDescription = errors.map((err, key) => {
      const message = err?.message
        ?.replace("validation.", "")
        ?.replace("IsInvalid", "");
      return <li key={key}>{message} is invalid.</li>;
    });
  }

  if (
    typeof res?.data === "string" &&
    res?.data?.toLowerCase() !== "Internal server error".toLowerCase()
  ) {
    errorDescription = res?.data;
  }

  if (
    typeof res?.data?.message === "string" &&
    res?.data?.message?.toLowerCase() !== "Internal server error".toLowerCase()
  ) {
    errorDescription = res?.data?.message;
  }

  return errorDescription;
}

export function manageLocalStorage() {
  const get = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  const set = (key, value) => {
    const ifExist = localStorage.getItem(key);
    ifExist && localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify(value));
  };

  const remove = (key) => {
    localStorage.removeItem(key);
  };

  return {
    get,
    set,
    remove,
  };
}

export function fileNameFromKey(key) {
  const splited = key.split("/");
  if (splited?.length > 0) {
    return splited[splited.length - 1];
  } else {
    return "unnamed";
  }
}

export function getInterfaceByRole(role, exclude) {
  let path = null;
  const _exclude = exclude || "";
  const temp = Object.keys(interfaces);
  for (let index = 0; index < temp.length; index++) {
    const interfaceName = temp[index];
    if (
      permessions[interfaceName].access.includes(role) &&
      interfaceName !== _exclude
    ) {
      path = interfaces[interfaceName];
      break;
    }
  }
  if (path) {
    return path;
  } else {
    return "/settings";
  }
}

export function getDibsyFees(amount, method) {
  if (method === "creditcard") {
    return amount * (0.45 / 100) + 0.8;
  }
  if (method === "applepay") {
    return amount * (0.75 / 100);
  }
  return null;
}

export function getBankFees(amount, amountNet) {
  return amount - amountNet;
}

export function getFullTens(amount) {
  const _amount = amount?.toString();
  if (_amount?.includes(".")) {
    const splited = _amount?.split(".");
    if (splited[1]?.length > 2) {
      return "." + splited[1];
    }
  }

  return null;
}

export function renderLinkStatus(record) {
  if (record?.reusable) {
    return (
      <Badge content={record?.totalPayments}>
        {" "}
        <Tag className={record?.active ? "succeeded" : "expired"}>
          {record?.active ? "ACTIVE" : "INACTIVE"}
        </Tag>{" "}
      </Badge>
    );
  }

  if (!record?.reusable && record?.totalPayments > 0) {
    return (
      <Badge
        content={<Icon icon="check-circle" />}
        className="empty-link-badge"
      >
        {" "}
        <Tag className={record?.active ? "succeeded" : "expired"}>
          {record?.active ? "ACTIVE" : "INACTIVE"}
        </Tag>{" "}
      </Badge>
    );
  }

  return (
    <Tag className={record?.active ? "succeeded" : "expired"}>
      {record?.active ? "ACTIVE" : "INACTIVE"}
    </Tag>
  );
}
