import React, {useEffect} from 'react';

function FormErrorMessage({error}) {
    useEffect(()=>{

    },[error])
    if(!error)
        return null
    return (
        <p className={"input-error-text"}>
            {error}
        </p>
    );
}

export default FormErrorMessage;
