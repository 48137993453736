import * as React from "react";

function SvgSmallQuestionIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 15A7 7 0 108 1a7 7 0 000 14z"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.963 5.9a2.1 2.1 0 014.08.7c0 1.4-2.1 2.1-2.1 2.1M8 11.5h.007"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgSmallQuestionIcon;
