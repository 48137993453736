import {Schema} from "rsuite";
import {GetBankCodeFromIBAN} from "utils/IBANValidator";
import i18n from "i18n";

const {StringType} = Schema.Types;

const model = Schema.Model({
    bankName: StringType().isRequired(i18n.t("validation:bankRequired")),
    accountName: StringType().isRequired(i18n.t("validation:accountHolderRequired")),
    iban: StringType().isRequired(i18n.t("validation:IBANRequired"))
        .addRule((val, data) => {
            return String(val).indexOf("_") < 0
        }, i18n.t("validation:enterValidIBAN"))
        .addRule((val, data) => {
            const code = GetBankCodeFromIBAN(val);
            return code === data.bankName
        }, i18n.t("validation:IBANNotMatchBank"))
})
export default model