import React, { Fragment, useEffect, useState } from "react";
import { FlexboxGrid, List, Icon } from "rsuite";
import styles from "./card.module.scss";
import { useCanBreakPointMobile } from "hooks/useViewport";
import { PlaceholderField } from "../../index";

let { Item: ListItem } = List;
let { Item: FlexItem } = FlexboxGrid;

const Index = ({ title, content, isOdd }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 4000);
    return () => clearTimeout(timeout);
  }, []);

  const breakPoint = useCanBreakPointMobile();

  return (
    <Fragment>
      <ListItem className={isOdd ? styles.ItemOdd : styles.listItem}>
        <FlexboxGrid>
          <FlexItem colspan={12}>
            <p className={styles.cardItemTitle}>{title}</p>
          </FlexItem>
          <FlexItem className={breakPoint && "text-right"} colspan={12}>
            {content || !loading ? (
              <p className={styles.cardItemContent}>
                {content || <span className={styles.emptySpan}>{"---"}</span>}
              </p>
            ) : (
              <PlaceholderField />
            )}
          </FlexItem>
        </FlexboxGrid>
      </ListItem>
    </Fragment>
  );
};

export default Index;
