import * as React from "react";

function SvgGetStartedIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.492 4.203h0c.715-.58 1.773-.08 1.773.827 0 .33-.157.641-.422.854v.001a6.58 6.58 0 00-2.402 5.809c.314 3.052 2.757 5.52 5.806 5.86 3.975.446 7.353-2.669 7.353-6.556a6.584 6.584 0 00-2.444-5.117h0a1.095 1.095 0 01-.418-.851c0-.905 1.046-1.41 1.745-.85a8.743 8.743 0 013.27 6.818c0 5.037-4.279 9.098-9.395 8.729h0c-4.23-.298-7.68-3.668-8.073-7.89a8.74 8.74 0 013.207-7.634zM8.92 1.327a1.077 1.077 0 112.154 0v6.111a1.077 1.077 0 11-2.154 0V1.327z"
        fill="currentColor"
        stroke="#0C2036"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default SvgGetStartedIcon;
