import { atom } from "recoil";

export const teamTableSettingsAtom = atom({
  key: "linksTableSettingsAtom",
  default: {
    count: 20,
    page: 1,
    reload: false,
    totalCounts: 0,
    loading: false,
    showAddMemberDrawer: false,
    showEditMemberDrawer: false,
  },
});

export const selectedRoleAtom = atom({
  key: "selectedRoleAtom",
  default: {
    role: "",
  },
});

export const addMemberAtom = atom({
  key: "addMemberAtom",
  default: {
    email: "",
    role: "developer",
    loadingBtn: false,
  },
});

export const selectedMemberAtom = atom({
  key: "selectedMemberAtom",
  default: {
    id: "",
    email: "",
    role: "",
    pending: "",
    expiresAt: "",
    loadingBtn: false,
    loadingDeleteBtn: false,
  },
});

export const teamTableDataAtom = atom({
  key: "teamTableDataAtom",
});

export const verifyInvitationAtom = atom({
  key: "verifyInvitationAtom",
  default: {
    id: null,
    type: "accept",
    hasAccount: false,
    email: "",
    currentEmail: "",
    expired: false,
    organization: "",
    pending: false,
    loading: false,
    showInvitModal: false,
    isValid: false,
  },
});
