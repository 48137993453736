import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Tooltip, Whisper } from "rsuite";
import { useRecoilState } from "recoil";
import { createPaymentLinkAtom } from "store/linksRecoil";
import styles from "./dynamic-tooltip.module.scss";

const Index = ({ className = "" }) => {
  const { t } = useTranslation("pages");
  let [paymentLink, setPaymentLink] = useRecoilState(createPaymentLinkAtom);

  const tooltip = (
    <Tooltip>
      <p>
        Give your customers the option to pay what they want for your product or
        service, with the ability to{" "}
        <a
          className={styles.setMax}
          onClick={() => {
            setPaymentLink({
              ...paymentLink,
              allowMaxAmount: true,
            });
          }}
        >
          set a maximum amount
        </a>
        .
      </p>
    </Tooltip>
  );

  return (
    <Fragment>
      <span className={className}>Pay what you want</span>
      <Whisper placement="top" trigger="click" speaker={tooltip}>
        <Icon className={styles.iconInfo} icon="info-circle" />
      </Whisper>
    </Fragment>
  );
};

export default Index;
