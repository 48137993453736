import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Icon } from "rsuite";
import styles from "./add-customer-modal.module.scss";
import { CustomerIdSchema } from "schemas";
import { parseErrors } from "utils/helpers";

//
import OldCustomerForm from "./old-customer-form";

function CreateCustomerModal({
  visible,
  closeModal,
  handleChange,
  defaultValue,
}) {
  const [oldCustomerFormValue, setOldCustomerForm] = useState(
    defaultValue || {}
  );
  const [oldCustomerFormErrors, setOldCustomerFormErrors] = useState({});

  useEffect(() => {
    if (Object.keys(oldCustomerFormErrors).length > 0) {
      setOldCustomerFormErrors(
        parseErrors(CustomerIdSchema.check(oldCustomerFormValue))
      );
    }
  }, [oldCustomerFormValue]);

  function onSubmit() {
    const _errors = parseErrors(CustomerIdSchema.check(oldCustomerFormValue));
    setOldCustomerFormErrors(_errors);
    if (Object.keys(oldCustomerFormErrors).length === 0) {
      handleChange(oldCustomerFormValue);
      closeModal();
    }
  }

  function emptyInput() {
    handleChange({});
    closeModal();
  }

  return (
    <Modal size={"xs"} show={visible} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>{"Search Customer"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OldCustomerForm
          formValue={oldCustomerFormValue}
          setFormValue={setOldCustomerForm}
          formErrors={oldCustomerFormErrors}
        />
      </Modal.Body>
      <Modal.Footer className={styles.oldCustomerFooter}>
        <Button
          className={`px-5 ${styles.deleteButton}`}
          appearance="subtle"
          onClick={emptyInput}
        >
          <Icon icon={"trash"} />
        </Button>
        <div className="w-100">
          <Button
            className={"px-4"}
            onClick={() => {
              closeModal();
            }}
            appearance="subtle"
          >
            {"Cancel"}
          </Button>
          <Button className={"px-5"} appearance="primary" onClick={onSubmit}>
            {"Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateCustomerModal;
