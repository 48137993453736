import React from 'react';
import {Button} from "rsuite";
import {css} from "@emotion/css"
import pSBC from 'shade-blend-color';


function CustomerCheckoutButton({
                                    loading,
                                    customColor = null,
                                    children,
                                    ...props
                                }) {

    const shadedCustomColor = React.useMemo(() => {
        return pSBC(-0.1, customColor)
    }, [customColor])

    const shadedCustomColorDisabled = React.useMemo(() => {
        return pSBC(0.4, customColor)
    }, [customColor])

    return (
        <Button
            {...props}
            {
                ...(!customColor ? {appearance: "primary"} : {})
            }
            className={`${!customColor ? '' : css`
              background-color: ${customColor} !important;
              color: white !important;

              &:hover, &:focus, &:active, &:focus:active {
                background-color: ${shadedCustomColor} !important;
                color: white;
              }

              &:disabled {
                color: white;
                background-color: ${shadedCustomColorDisabled} !important;

                &:hover, &:focus {
                  background-color: ${shadedCustomColorDisabled} !important;
                  color: white;
                }
              }
            `} ${props.className}`}
        >
            {
                children
            }
        </Button>
    );
}

export default CustomerCheckoutButton;