import React from 'react';
import {useAPI} from "../hooks/useAPI";
import {ENV as Env} from "../config/env";
import {STATUSES} from "../utils/enums";
import {useSetRecoilState} from "recoil";
import {feedbackContentAtom} from "../store/checkoutRecoil";
import { useTranslation } from "react-i18next";

function ApplePayService() {
    const {call, ...rest} = useAPI();
    const updateFeedbackContent = useSetRecoilState(feedbackContentAtom);

    return {
        validateMerchant: (publicKey) => {
            return call({
                method: 'POST',
                baseURL: Env.CHECKOUT_API_URL,
                link: `/payments/applepay/authorize`,
                data: {
                    key: publicKey
                }
            })
        },
        processPayment: (publicKey,applePayPaymentToken,sessionId,paymentToken) => {
            return call({
                method: 'POST',
                baseURL: Env.CHECKOUT_API_URL,
                link: `/payments/pay/apple_pay/${sessionId}`,
                data: {
                    key: publicKey,
                    applePayPaymentToken,
                    paymentToken
                }
            })
        },
        setFeedbackContent : (isVisible = false, success = false, feedbackText = "", feedbackTitle = "") => {
            updateFeedbackContent({isVisible, feedbackText, success, feedbackTitle})
        },
        ...rest
    }
}

function useApplePayService() {

    const {call, ...rest} = useAPI();
    const {t}=useTranslation("pages");
    const applePayService = ApplePayService();


    return {
        startPaymentProcessing: ({label,amount,publicKey, paymentToken,setServerResponse}) => {
            if (!window.ApplePaySession) {
                return;
            }

            const request = {
                countryCode: "QA",
                currencyCode: "QAR",
                merchantCapabilities: ["supports3DS"],
                supportedNetworks: ["visa", "masterCard"],
                total: {
                    label,
                    type: "final",
                    amount,
                },
            };

            const session = new ApplePaySession(3, request);
            let dibsySessionId = null;

            session.onvalidatemerchant = (event) => {
                applePayService.validateMerchant(publicKey).then(res => {
                    session.completeMerchantValidation(res?.data?.merchantSession);
                    dibsySessionId = res?.data?.sessionId;
                }).catch(err => {
                    setServerResponse("Error fetching merchant session")
                })
            };

            session.onpaymentauthorized = (event) => {
                applePayService.processPayment(publicKey, JSON.stringify(event.payment.token), dibsySessionId,paymentToken).then(res => {

                    let data = res?.data;
                    let paymentStatus = {
                        status: 1,
                    };
                    if (!data?.error && data?.payment?.status === STATUSES.SUCCEEDED) {
                        // show success feedback
                        applePayService.setFeedbackContent(true, true,
                            t("link_checkout.feedbacks.success_payment_text"), t("link_checkout.feedbacks.link_success_title"))
                        paymentStatus.status = 0;
                    } else {
                        applePayService.setFeedbackContent(true, false,
                            t("link_checkout.feedbacks.failed_payment_text"), t("link_checkout.feedbacks.link_failed_title"))
                    }
                    session.completePayment(paymentStatus);
                }).catch(err => {

                    applePayService.setFeedbackContent(true, false,
                        t("link_checkout.feedbacks.failed_payment_text"), t("link_checkout.feedbacks.link_failed_title"));
                    session.completePayment({
                        status: 1
                    });
                })
            };

            session.oncancel = (event) => {
                setServerResponse("Payment has been cancelled")
            };

            session.begin();
        },
        ...rest

    }
}

export {
    ApplePayService,
    useApplePayService
};