import React, { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useMembershipsInvit } from "services/memberShipService";
import { verifyInvitationAtom } from "store/teamRecoil";
import { registerAtom } from "store/authRecoil";
import { Button, Modal, Notification } from "rsuite";
import { useAppInitiateService } from "services/AppInitiateService";
import { useSignOut } from "services/AuthService";
import styles from "./invitation-modal.module.scss";
import { useHistory } from "react-router";
import { debounce } from "lodash";
import { Routes } from "utils/routes";
import { manageLocalStorage } from "utils/helpers";

const Index = ({ visible }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [invitaState, setInvitaState] = useRecoilState(verifyInvitationAtom);

  const UpdateRegisterState = useSetRecoilState(registerAtom);

  const membershipInvit = useMembershipsInvit();
  const appInitiateService = useAppInitiateService();
  const signOut = useSignOut();

  const closeModal = () => {
    setInvitaState((old) => ({
      ...old,
      ...{ showInvitModal: false },
    }));
  };

  function switchAccount() {
    closeModal();
    const { hasAccount, id, email } = invitaState || {};
    if (hasAccount) {
      manageLocalStorage().set("membership_invitation", {
        uid: id,
        email: email,
      });
    } else {
      UpdateRegisterState((old) => ({
        ...old,
        ...{
          email: email || "",
        },
      }));
    }
    debounce(() => {
      signOut.call(!hasAccount ? false : true);
      if (!hasAccount) {
        history.push(Routes.registerInvite + "/" + id);
        window.location.reload();
      }
    }, 500)();
  }

  const onSubmit = () => {
    setLoading(true);
    const { organization, id } = invitaState || {};
    membershipInvit.accept(id).then((res) => {
      if (res?.success) {
        appInitiateService
          .initInformation()
          .then((res) => {
            Notification.success({
              title: "Invitation accepted",
              description: `You are currently a member of (${organization}) team.`,
            });
          })
          .finally(() => {
            setLoading(false);
            closeModal();
          });
      }
    });
  };

  return (
    <Modal size={"xs"} show={visible} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          {invitaState?.type === "accept" ? "Invitation " : "Change account"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {invitaState?.type === "accept" ? (
          <p>
            You have been invited to join{" "}
            <span className={styles.boldMessage}>
              ({invitaState?.organization || "Unnamed Business"})
            </span>{" "}
            . Click the join button below to be part of their team
          </p>
        ) : (
          <p>
            This invitation was sent to{" "}
            <span className={styles.boldMessage}>({invitaState?.email})</span>{" "}
            while you are currently logged in as{" "}
            <span className={styles.boldMessage}>
              ({invitaState?.currentEmail})
            </span>
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={"px-4"}
          onClick={() => {
            closeModal();
          }}
          appearance="subtle"
        >
          {"Cancel"}
        </Button>
        <Button
          loading={loading}
          className={"px-5"}
          appearance="primary"
          onClick={() => {
            invitaState?.type === "accept" ? onSubmit() : switchAccount();
          }}
        >
          {invitaState?.type === "accept" ? "Join" : "Logout"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Index;
