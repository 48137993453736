import {atom} from "recoil";

export const checkout3dsModalAtom = atom({
    key: "checkout3dsModalAtom",
    default: {
        isVisible: false,
        type:false,
    }
})

export const customerFormAtom = atom({
    key: "customerFormAtom",
    default: {
        isVisible: false,
        name:"",
        phone:"",
        email:"",
        // this must be initialized with null
        custom_fields:null,
        isEditVisible:true,
    }
})

export const feedbackContentAtom = atom({
    key: "feedbackContentAtom",
    default: {
        isVisible: false,
        success:false,
        feedbackTitle:"",
        feedbackText:"",
    }
})

export const checkoutLinkDataAtom = atom({
    key: "checkoutLinkDataAtom",
    default:{
        link:{},
        checkoutLogo:""
    }
})

export const checkoutFormAtom = atom({
    key: "checkoutFormAtom",
    default:{
        cardNumber:"",
        cardCode:"",
        expiryDate:"",
        cardHolder:"",
        continueFormData:"",
    }
})

export const checkoutCardSelectedAtom = atom({
    key: "checkoutCardSelectedAtom",
    default:{
        type:"",
    }
})