import React, { useEffect } from "react";
import { FormField, Image } from "components";
import { Icon } from "rsuite";
import styles from "./create-link.module.scss";

const RemovableField = ({
  index,
  data,
  onChange,
  removeField,
  onClick,
  ...props
}) => {
  useEffect(() => {}, []);

  return (
    <div
      {...props}
      className={styles.removableField}
      onClick={(e) => {
        e.target.tagName === "DIV" && onClick();
      }}
    >
      <Icon
        icon={"window-close"}
        className={styles.removeIcon}
        onClick={removeField}
      />
      <FormField
        readOnly={true}
        value={data?.name.charAt(0).toUpperCase() + data?.name.slice(1)}
        className={"w-100 mb-0 mx-3 clickable-input"}
        placeholder={"E.g ID Number"}
        name={data?.name}
      />
      <span className={"handle"}>
        <Image src={"sort-handle.svg"} alt="sort handle" />
      </span>
    </div>
  );
};

export default RemovableField;
