export const onboarding_loader = {
    back: "back_loader",
    upload_cr_document: "upload_cr_documents_loader",
    upload_qid_documents: "upload_qid_documents_loader",
    upload_documentsBankAccountStatement: "upload_documentsBankAccountStatement",
    upload_OtherDocuments: "upload_OtherDocuments",
    upload_ComputerCardDocument: "upload_ComputerCardDocument",
}
export const init_loader = {
    fetch_information: "fetch_information_loader"
}
export const settings_loader = {
    save_general_settings: "save_general_settings_loader",
    resend_verification_email: "resend_verification_email_loader"
}