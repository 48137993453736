import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "utils/routes";
import React, { lazy, Suspense, useEffect } from "react";
import { CustomLoader, Sidebar } from "components";
import { Notification } from "rsuite";
import styles from "./App.module.scss";
import InvitationModal from "./invitation-modal";

import { useCanBreakPointMobile } from "hooks/useViewport";
import { useRecoilValue, useRecoilState } from "recoil";
import { authAtom } from "store/authRecoil";
import { verifyInvitationAtom } from "store/teamRecoil";
import { GUARDS } from "../../config/guards";
import { useLocation } from "react-router";
import { useAppInitiateService } from "services/AppInitiateService";
import { useMembershipsInvit } from "services/memberShipService";
import { RouteExtended } from "./RouteExtended";
import { isPrivateRoute, manageLocalStorage } from "../../utils/helpers";
import { debounce } from "lodash";

//auth pages
//
const AuthPages = lazy(() => import("pages/auth-pages/index"));

//dashboard routes
const Onboarding = lazy(() => import("pages/onboarding"));
const Customers = lazy(() => import("pages/customers"));
const PaymentMainPage = lazy(() => import("pages/payment"));
const LinksPage = lazy(() => import("pages/links"));
const DocumentsMobileUploadPage = lazy(() =>
  import("pages/mobile/documents_upload")
);
const CallBacks = lazy(() => import("pages/callbacks"));
const Settings = lazy(() => import("pages/settings"));
const Insights = lazy(() => import("pages/insights"));
const Balance = lazy(() => import("pages/balance"));
const Team = lazy(() => import("pages/team"));
const PageNotFound = lazy(() => import("pages/error/page-not-found"));

//checkout pages
const Checkout = lazy(() => import("pages/checkout"));
const Payscreen = lazy(() => import("pages/payscreen"));

function App() {
  const breakPoint = useCanBreakPointMobile();
  const auth = useRecoilValue(authAtom);
  const [invitaState, setInvitaState] = useRecoilState(verifyInvitationAtom);
  const appInitiateService = useAppInitiateService();
  const membershipInvit = useMembershipsInvit();
  const location = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    appInitiateService.init();
  }, []);

  const canShowSidBar = () => {
    return auth?.token && isPrivateRoute(location);
  };

  useEffect(() => {
    //if user is logged in
    if (!auth?.token || !isPrivateRoute(location)) {
      return;
    }
    let uid = null;
    let fromLocalStorage = false;

    if (pathname?.toLowerCase()?.startsWith("/memberships")) {
      const temp = pathname.split("/");
      const pos = temp.indexOf("invite");
      uid = temp[pos + 1];
    } else {
      const pending_invit = manageLocalStorage().get("membership_invitation");
      const { uid: _uid, email: _email } = pending_invit || {};
      if (_uid && _email === auth?.username) {
        uid = _uid;
      }
      fromLocalStorage = true;
      manageLocalStorage().remove("membership_invitation");
    }

    uid &&
      membershipInvit.check(uid).then((res) => {
        const { data, success } = res;
        const { expired, pending, email } = data || {};
        const isValid = success && !expired && pending;
        if (isValid) {
          debounce(() => {
            setInvitaState((old) => ({
              ...old,
              ...res?.data,
              ...{ showInvitModal: true },
              ...(email === auth?.username
                ? { isValid: true, type: "accept" }
                : {
                    isValid: false,
                    currentEmail: auth?.username,
                    type: "switch",
                  }),
            }));
          }, 1000)();
        } else {
          !fromLocalStorage &&
            Notification.error({
              title: "Invitation not valid",
              description:
                "This invitation is not valid. Please ask the organization owner to send it again.",
            });
        }
      });
  }, [auth]);

  if (!appInitiateService.ready) {
    return <CustomLoader />;
  }

  return (
    <div className={styles.container}>
      {canShowSidBar() && (
        <div>
          <Sidebar />
        </div>
      )}
      <div
        className={`
                ${styles.content} 
                ${
                  canShowSidBar() && !breakPoint
                    ? styles.showSideBar
                    : breakPoint &&
                      !String(location.pathname).startsWith("/pay/")
                    ? styles.mobilePadding
                    : ""
                } 
                ${!canShowSidBar() && breakPoint && styles.guestPage}
                `}
      >
        <Switch>
          <Suspense fallback={<CustomLoader />}>
            <Switch>
              <Route
                path="/beta/:path*"
                render={({ match }) => (
                  <Redirect to={`/${match.params.path}`} />
                )}
              />

              {/*Checkout routes*/}
              <Route path={Routes.LinkCheckout} component={Checkout} />

              <Route path={Routes.HostedCheckoutLink} component={Payscreen} />

              <RouteExtended
                guards={[GUARDS.GUEST]}
                component={AuthPages}
                path={[
                  Routes.login,
                  //Routes.register,
                  //
                  Routes.forgotPasswd,
                  //
                  Routes.resetPasswd,
                  Routes.resetVerify,
                  //
                  Routes.registerVerify,
                  Routes.registerInvite,

                  Routes.registerInvite,
                  Routes.mfaVerify,
                ]}
              />

              {/*Dashboard routes*/}
              <RouteExtended
                exact
                path={Routes.onboardingDocumentsMobile}
                component={DocumentsMobileUploadPage}
                guards={[GUARDS.GUEST]}
              />

              <RouteExtended
                guards={[GUARDS.USER]}
                exact
                path="/"
                component={Onboarding}
                limitedAccess={true}
                name="onboarding"
              />
              <RouteExtended
                guards={[GUARDS.USER]}
                path={Routes.OBHome}
                component={Onboarding}
                limitedAccess={true}
                name="onboarding"
              />

              <RouteExtended
                guards={[GUARDS.USER]}
                path={Routes.PYHome}
                component={PaymentMainPage}
                limitedAccess={true}
                name="payments"
              />

              <RouteExtended
                guards={[GUARDS.USER]}
                path={Routes.Settings.General}
                component={Settings}
              />
              <RouteExtended
                guards={[GUARDS.USER]}
                path={Routes.LinksHome}
                component={LinksPage}
              />

              {/* <RouteExtended
                guards={[GUARDS.USER]}
                path={Routes.Insights.Base}
                component={Insights}
                limitedAccess={true}
              /> */}

              <RouteExtended
                guards={[GUARDS.USER]}
                path={Routes.Balance}
                component={Balance}
                limitedAccess={true}
                name="balance"
              />

              <RouteExtended
                guards={[GUARDS.USER]}
                path={Routes.Team}
                component={Team}
                limitedAccess={true}
                name="team"
              />

              <RouteExtended
                guards={[GUARDS.USER]}
                path={`${Routes.Customers}/:id`}
                component={Customers}
                limitedAccess={true}
                name="customers"
              />

              <Route component={CallBacks} path={Routes.CallBacks.Base} />
              <Route component={PageNotFound} />
            </Switch>
          </Suspense>
        </Switch>
        {invitaState?.showInvitModal ? (
          <InvitationModal visible={invitaState.showInvitModal} />
        ) : null}
      </div>
    </div>
  );
}

export default App;
