import React from "react";
import styles from "./FilterHeader.module.scss";
import { FlexboxGrid } from "rsuite";
import Divider from "./../divider";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { balanceDetailsDataAtom } from "store/balanceRecoil";
import { Amount } from "components";
import { format_datetime, getNextPayout } from "utils/helpers";

function FilterHeader() {
  const { t } = useTranslation("pages");
  const {
    onHold,
    sentToBank,
    currency,
    availableBalance,
    nextPayout,
  } = useRecoilValue(balanceDetailsDataAtom);
  return (
    <div className={styles.container}>
      <FlexboxGrid
        className={styles.row}
        align={"middle"}
        justify={"space-between"}
      >
        <span>{t("balance.filter.availableBalance")}</span>
        <Amount
          className={styles.rowAmount}
          amount={availableBalance}
          currency={currency}
        />
      </FlexboxGrid>
      <div className={styles.nextPayout}>
        <span className={styles.nextPayoutTitle}>
          {t("balance.filter.nextPayout")}
        </span>
        <p className={styles.nextPayoutDate}>{getNextPayout()}</p>
      </div>
      <Divider />
      <FlexboxGrid
        className={styles.row}
        align={"middle"}
        justify={"space-between"}
      >
        <span>{t("balance.filter.onHold")}</span>
        <Amount
          className={styles.rowAmount}
          amount={onHold}
          currency={currency}
        />
      </FlexboxGrid>
      <Divider />
      <FlexboxGrid
        className={styles.row}
        align={"middle"}
        justify={"space-between"}
      >
        <span>{t("balance.filter.sentToBank")}</span>
        <Amount
          className={styles.rowAmount}
          amount={sentToBank}
          currency={currency}
        />
      </FlexboxGrid>
    </div>
  );
}

export default FilterHeader;
