import { Schema } from "rsuite";

const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType()
    .isRequired("Name is required.")
    .maxLength(30, "The field cannot be greater than 50 characters")
    .pattern(/^[a-zA-Z\s_-]*$/i, "Name is invalid."),
  placeholder: StringType()
    .isRequired("Placeholder is required.")
    .maxLength(30, "The field cannot be greater than 50 characters"),
  type: StringType().isRequired("Type is required."),
  // label: StringType().isRequired("Label is required."),
});

export default model;
