import { useEffect, useRef } from "react";
import { Icon, Avatar, Notification } from "rsuite";
import styles from "./OrganisationsListe.module.scss";
import { capitalize, errorParser } from "utils/helpers";
import { useUserSettingsService } from "../../../services/SettingsService";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../../store/organizationRecoil";
import { userAtom } from "store/userRecoil";

export const OrganizationsList = (props) => {
  const userSettingsService = useUserSettingsService();
  const { id: currentOrganizationId } = useRecoilValue(organizationAtom) || {};
  const user = useRecoilValue(userAtom);

  const { organizations } = user;
  const { ref } = props;
  const _ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (_ref.current && !_ref.current.contains(event.target)) {
        setTimeout(() => {
          props?.setShowOrgs(false);
        }, 300);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const renderContent = () => {
    const organizationsList = organizations.filter(
      (org) => org?.id !== currentOrganizationId
    );

    if (organizationsList?.length > 0) {
      return organizationsList.map((organization) => {
        return (
          <div
            className={styles.companyInformation}
            onClick={() => switchOrganization(organization.id)}
          >
            <div className={styles.companyLogo}>
              <Avatar className={styles.companyLogoChild}>
                {capitalize(organization?.name?.charAt(0)) || "U"}
              </Avatar>
              <div className={styles.companyLogoInformation}>
                <p>{organization?.name || "Unnamed"}</p>
                <span>ID #{organization?.id}</span>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className={styles.noCompanyContainer}>
          <Icon icon={"info"} />
          <p>No organizations found</p>
        </div>
      );
    }
  };

  const switchOrganization = (organizationId) => {
    userSettingsService.switchOrganization({ organizationId }).then((res) => {
      if (res?.status !== 200) {
        const error = errorParser(
          res,
          "There was an error while trying to switch the organization, please try again later."
        );
        error &&
          Notification.error({
            title: "User settings",
            description: error,
          });
      } else {
        window.location.reload();
      }
    });
  };

  return (
    <div {...props} ref={ref}>
      <div ref={_ref}>
        <div className={styles.customDevider} />
        {renderContent()}
        <div className={styles.customDevider} />
      </div>
    </div>
  );
};
