import { useEffect } from "react";
import { useAPI } from "hooks/useAPI";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "store/organizationRecoil";
import { teamTableSettingsAtom } from "store/teamRecoil";
import { selectedMemberAtom } from "store/teamRecoil";

function useAddMember() {
  const { call, ...rest } = useAPI();
  const orgInformation = useRecoilValue(organizationAtom);

  useEffect(() => {}, []);
  return {
    call: ({ email, role }) =>
      call({
        link: `/memberships`,
        method: "POST",
        data: {
          email: email?.toLowerCase(),
          role: role,
          organizationId: orgInformation?.id,
        },
      }),
    ...rest,
  };
}

function useGetMembers() {
  const { call, ...rest } = useAPI();
  const orgInformation = useRecoilValue(organizationAtom);

  useEffect(() => {}, []);

  return {
    load_data: () => {
      return call({
        link: `/memberships?organizationId=${orgInformation?.id}`,
      });
    },
  };
}

function useUpdateMember() {
  const { call } = useAPI();
  const { id, role } = useRecoilValue(selectedMemberAtom);
  const orgInformation = useRecoilValue(organizationAtom);

  useEffect(() => {}, []);

  return {
    delete: () => {
      return call({
        link: `/memberships/${id}?organizationId=${orgInformation?.id}`,
        method: "DELETE",
      });
    },
    update: () => {
      return call({
        link: `/memberships/${id}`,
        method: "PUT",
        data: {
          role: role,
          organizationId: orgInformation?.id,
        },
      });
    },
  };
}

function useMembershipsInvit() {
  const { call } = useAPI();
  const orgInformation = useRecoilValue(organizationAtom);

  useEffect(() => {}, []);

  return {
    check: (id) => {
      return call({
        link: `/memberships/${id}/signup`,
      });
    },
    accept: (id) => {
      return call({
        link: `/memberships/${id}/accept`,
        method: "PUT",
      });
    },
  };
}

export { useAddMember, useGetMembers, useUpdateMember, useMembershipsInvit };
