import React, { useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { checkoutLinkDataAtom, customerFormAtom } from "store/checkoutRecoil";
import { Button, ControlLabel, Form, FormGroup } from "rsuite";
import styles from "./customer-form.module.scss";
import {
  CustomerCheckoutButton,
  FormErrorMessage,
  FormField,
  PhoneInput,
} from "components";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  isEmptyObject,
  validateEmail,
  validatePhoneNumber,
} from "utils/helpers";

const Index = () => {
  const [formData, setFormData] = useRecoilState(customerFormAtom);
  let { link, checkoutButtonColor } = useRecoilValue(checkoutLinkDataAtom);
  const { t } = useTranslation("pages");
  const [errors, setErrors] = useState({});
  const form = useRef(null);
  const formCustomerSubmit = () => {
    if (validateFields()) {
      setFormData({ ...formData, isVisible: false });
    }
  };

  const validateFields = () => {
    let errors_fields = {};
    const { name, phone } = formData;
    formData?.custom_fields?.forEach((field) => {
      if (field?.value.length < 2) {
        errors_fields[field?.name] = t("validation:fieldRequired");
      } else {
        delete errors_fields[field?.name];
      }
    });

    // check name,phone and email
    if (!name) {
      errors_fields.name = t("validation:fullNameRequired");
    } else {
      delete errors_fields?.name;
    }
    if (!phone) {
      errors_fields.phone = t("validation:phoneRequired");
    } else {
      if (validatePhoneNumber(phone)) delete errors_fields?.phone;
      else errors_fields.phone = t("validation:phoneNotValid");
    }

    // if(!email){
    //     errors_fields.email=t("validation:emailRequired");
    // }else{
    //     if(validateEmail(email))
    //         delete errors_fields?.email;
    //     else
    //         errors_fields.email=t("validation:emailNotValid");
    // }

    if (!isEmptyObject(errors_fields)) {
      setErrors({ ...errors, ...errors_fields });
      return false;
    }

    return true;
  };

  const formChange = (_, e) => {
    if (!e?.target) return;
    let { name, value } = e.target;

    // check if custom fields change
    if (["email", "name", "phone"].includes(name)) {
      setFormData({ ...formData, [name]: value });
    } else {
      let custom_fields = JSON.parse(JSON.stringify(link.custom_fields));
      if (formData.custom_fields !== null) {
        custom_fields = JSON.parse(JSON.stringify(formData.custom_fields));
      }

      let index = custom_fields.findIndex((field) => field.name == name);
      custom_fields[index].value = value;
      setFormData({ ...formData, custom_fields });
    }
  };

  return (
    <div className={styles.customerForm}>
      <Form
        ref={form}
        formError={errors}
        onCheck={(formError) => setErrors(formError)}
        onChange={formChange}
        formValue={formData}
        className={"mt-3"}
        fluid
      >
        <FormField
          className={"formGroupCheckout"}
          name={"name"}
          error={errors?.name}
          label={t("link_checkout.form_customer.nameLabel")}
          placeholder={t("link_checkout.form_customer.namePlaceholder")}
        />
        <FormField
          className={"formGroupCheckout"}
          name={"email"}
          error={errors?.email}
          label={t("link_checkout.form_customer.emailLabel")}
          placeholder={t("link_checkout.form_customer.emailPlaceholder")}
        />
        <FormGroup>
          <ControlLabel>
            {t("link_checkout.form_customer.phoneLabel")}
          </ControlLabel>
          <PhoneInput
            name={"phone"}
            className={"checkoutPhone"}
            value={formData?.phone}
            onChange={(val, e) => {
              formChange(_, e);
            }}
          />
          <FormErrorMessage error={errors.phone} />
        </FormGroup>
        {link?.custom_fields?.map((field, index) => (
          <FormField
            key={index}
            className={"formGroupCheckout"}
            name={field?.name}
            error={errors[field?.name]}
            label={capitalize(field?.label)}
            placeholder={field?.label}
          />
        ))}
        <CustomerCheckoutButton
          onClick={formCustomerSubmit}
          customColor={checkoutButtonColor}
          className={"w-100 checkoutBtn"}
        >
          {t("link_checkout.form_customer.continueBtn")}
        </CustomerCheckoutButton>
      </Form>
    </div>
  );
};
export default Index;
