import React from 'react';
import {Loader} from "rsuite";
import {Logo} from "../index";
import styles from "./custom-loader.module.scss"

const Index = () => {
    return (
        <div className={styles.custom_loader}>
            <Loader size="md" className={"mb-5"}/>
            <Logo logo_height={25} />
        </div>
    );
};

export default Index;