import React, {Fragment} from 'react';
import {useTranslation} from "react-i18next";
import {Icon, Tooltip, Whisper} from "rsuite"
import styles from "./status-tooltip.module.scss"

const Index = () => {
    const {t} = useTranslation("pages");

    const tooltip = (
        <Tooltip>
            <p>{t("transaction_details.statusTooltip")}</p>
            <a href={"https://support.dibsy.one/what-does-each-transaction-status-mean-error-messages-inc"} target="_blank">{t("transaction_details.statusMore")}</a>
        </Tooltip>
    )

    return (
        <Fragment>
            {t("transaction_details.status")}
            <Whisper placement="top" trigger="click" speaker={tooltip}>
                <Icon className={styles.iconInfo} icon="info-circle"/>
            </Whisper>
        </Fragment>
    );
};

export default Index;