import * as React from "react";

function SvgQuestionMarkRoundedIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 .9C5.43.9.9 5.43.9 11c0 5.57 4.53 10.1 10.1 10.1 5.57 0 10.1-4.53 10.1-10.1C21.1 5.43 16.57.9 11 .9zm0 19c-4.907 0-8.9-3.993-8.9-8.9S6.093 2.1 11 2.1c4.908 0 8.9 3.993 8.9 8.9s-3.992 8.9-8.9 8.9z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
      <path
        d="M7.863 8.724l-.005.105h1.367l.007-.092c.035-.468.21-.84.49-1.094.28-.254.677-.4 1.174-.4.49 0 .883.15 1.153.392.269.241.422.581.422.978 0 .35-.073.62-.236.86-.164.242-.426.462-.817.702-.475.284-.832.587-1.069.94-.238.356-.35.755-.346 1.224v.728H11.399v-.544c0-.346.059-.6.214-.827.157-.231.42-.446.85-.7h.001c.898-.54 1.478-1.328 1.478-2.444 0-1.476-1.235-2.575-3-2.575-.98 0-1.733.301-2.25.8-.518.5-.79 1.188-.829 1.947zm2.93 5.408c-.61 0-1.05.441-1.05 1.057 0 .61.44 1.05 1.05 1.05.302 0 .564-.108.75-.297.187-.188.293-.452.293-.753 0-.303-.106-.569-.293-.758a1.034 1.034 0 00-.75-.299z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgQuestionMarkRoundedIcon;
