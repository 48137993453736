import {Schema} from "rsuite";
import i18n from "../i18n";

const {StringType} = Schema.Types;

const model = Schema.Model({
    code: StringType()
        .isRequired(i18n.t("pages:register.verification.schema.codeRequired"))
        .addRule((value, data) => {
            return /^[0-9]{6}$/.test(value);
        }, i18n.t("pages:register.verification.schema.codeInvalid"))
});


export default  model