import { Schema } from "rsuite";
import i18n from "../i18n";
import { isValidPhoneNumber } from "libphonenumber-js";

const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType()
    .isRequired(i18n.t("validation:fullNameRequired"))
    .maxLength(30, "The field cannot be greater than 50 characters"),
  email: StringType()
    .isRequired("Email is required")
    .isEmail(i18n.t("validation:emailNotValid")),
  phone: StringType()
    .isRequired(i18n.t("validation:phoneRequired"))
    .addRule((value) => {
      return isValidPhoneNumber(value);
    }, i18n.t("validation:phoneNotValid")),
});
export default model;
