import React from 'react';

const FieldError = ({state, name, label}) => {

        if (!state || !name)
            return null;

        const getFirstMsg = (name) => {
            if (!name)
                return;
            return state[name]?.message;
        }
        const error = getFirstMsg(name)

        return (
            <div>
                {
                    error ?
                        <span data-testid="field"
                              className={"input-error-text"}>{error}</span>
                        : null
                }
            </div>
        );
    }
;

export default FieldError;