import {atom} from "recoil";

export const insightsAtom = atom({
    default: {},
    key: "insightsAtom"
})

export const insightsFilterAtom = atom({
    default: {
        from: new Date("2000-01-01"),
        to: new Date(),
        period: "anytime",
        isCustomPeriod: false,
        custom_period: [],
    },
    key: "insightsFilterAtom"
})

export const insightsSettingsAtom = atom({
    key: "insightsSettingsAtom",
    default:{
        reload:false,
        showMobileFilters:false
    }
})

//component d zbi hhhhh
