import { Schema } from "rsuite";
import { isValidPhoneNumber } from "libphonenumber-js";
import i18n from "../i18n";

const { StringType } = Schema.Types;

const model = Schema.Model({
  email: StringType()
    .isEmail(i18n.t("pages:register.schema.invalidEmail"))
    .isRequired(i18n.t("pages:register.schema.requiredEmail")),
  firstName: StringType().isRequired(
    i18n.t("pages:register.schema.firstNameRequired")
  ),
  lastName: StringType().isRequired(
    i18n.t("pages:register.schema.lastNameRequired")
  ),
  phone: StringType()
    .isRequired(i18n.t("validation:phoneRequired"))
    .addRule((value) => {
      return isValidPhoneNumber(value);
    }, i18n.t("validation:phoneNotValid")),
  password: StringType()
    .addRule((value, data) => {
      return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/.test(
        value
      );
    }, i18n.t("pages:change_password.schema.invalidPasswd"))
    .isRequired(i18n.t("pages:register.schema.requiredPasswd")),
});

export default model;
