import React from 'react';
import ApplePayIcon from "./ApplePayIcon";
import styles from "./ApplePayButton.module.scss"


const ApplePayButton = ({theme="black",title, ...extraProps}) => {
    return (
        <button
            className={`${theme=="black" ? styles.blackBtn : styles.lightBtn } ${styles.ApplePayButton}`}

            {...extraProps}
        >
            <div
                className={styles.ApplePayButtonContent}
            >
                <span
                    className={styles.ApplePayButtonText}
                    style={{
                        color: theme === "light" ? "black" : "white",
                    }}
                >
                  {title}
                </span>
                <ApplePayIcon
                    style={{
                        width: 30 * 1.6,
                        height: 12.15 * 1.6,
                    }}
                    fill={theme === "light" ? "black" : "white"}
                />
            </div>
        </button>
    );
};

export default ApplePayButton;