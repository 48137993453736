import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Icon,
  Checkbox,
  SelectPicker,
  FormGroup,
  ControlLabel,
} from "rsuite";
import { FormField, FormErrorMessage } from "components";
import styles from "./add-field-modal.module.scss";
import { CustomFieldcheme } from "schemas";
import { parseErrors } from "utils/helpers";
import { TagsInput } from "react-tag-input-component";
import AnimateHeight from "react-animate-height";

//

function CreateFieldModal({
  visible,
  closeModal,
  removeField,
  handleAddCustomFeild,
  currentIndex,
  custom_fields,
  handleUpdateCustomFeild,
}) {
  const [formValue, setFormValue] = useState(
    custom_fields[currentIndex] || {
      name: "",
      placeholder: "",
      type: "text",
      isRequired: false,
      defaultValue: "",
    }
  );
  const [formErrors, setFormErrors] = useState({});
  const form = useRef(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      let _errors = parseErrors(CustomFieldcheme.check(formValue));
      if (formValue.type === "select" && !formValue?.options?.length) {
        _errors = {
          ..._errors,
          options: "Options are required.",
        };
      }
      setFormErrors(_errors);
    }
  }, [formValue]);

  function handleSubmit() {
    let _errors = {
      ...parseErrors(CustomFieldcheme.check(formValue)),
    };
    if (formValue.type === "select" && !formValue?.options?.length) {
      _errors = {
        ..._errors,
        options: "Options are required.",
      };
    }
    if (Object.keys(_errors).length > 0) {
      setFormErrors((old) => ({
        ...old,
        ..._errors,
      }));
    } else {
      setFormErrors({});
      if (currentIndex) {
        handleUpdateCustomFeild(formValue, currentIndex);
      } else {
        handleAddCustomFeild(formValue);
      }
      closeModal();
    }
  }

  function handleChange(key, val) {
    setFormValue((old) => ({
      ...old,
      [key]: val,
    }));
  }

  return (
    <Modal size={"md"} show={visible} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>{"Add custom field"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          ref={form}
          checkTrigger={"blur"}
          formDefaultValue={formValue}
          formError={formErrors}
          onChange={(_, element) => {
            let { name, value } = element.target;
            setFormValue({
              ...formValue,
              [name]: name == "name" ? value.trim() :value,
            });
          }}
          fluid
        >
          <FormField
            label={"Name"}
            error={formErrors.name}
            placeholder={"Type name"}
            name={"name"}
          />

          <FormField
            label={"Placeholder"}
            error={formErrors.placeholder}
            placeholder={"Type placeholder"}
            name={"placeholder"}
          />
          <FormGroup>
            <ControlLabel>Field type</ControlLabel>
            <SelectPicker
              searchable={false}
              value={formValue?.type}
              cleanable={false}
              placeholder={"Field Type"}
              data={[
                { label: "Text", value: "text" },
                { label: "Number", value: "number" },
                { label: "Email", value: "email" },
                { label: "Date", value: "date" },
                { label: "Select", value: "select" },
                { label: "Text area", value: "textarea" },
              ]}
              onChange={(val, e) => handleChange("type", val)}
            />
            <FormErrorMessage error={formErrors.type} />
          </FormGroup>
          <AnimateHeight
            className={`${formValue?.type === "select" ? "mb-4" : ""}`}
            duration={500}
            height={formValue?.type === "select" ? "auto" : 0}
          >
            <FormGroup>
              <ControlLabel>Options</ControlLabel>
              <TagsInput
                value={formValue?.options}
                onChange={(val) => {
                  setFormValue((old) => ({
                    ...old,
                    options: val,
                  }));
                }}
                name="fruits"
                placeHolder="Type your option and press enter"
              />
              <FormErrorMessage error={formErrors.options} />
            </FormGroup>
          </AnimateHeight>
          <FormGroup>
            <div className="custom-checkbox-container">
              <Checkbox
                inline
                title="Required"
                checked={formValue?.isRequired}
                indeterminate={false}
                onChange={(val, e) => {
                  handleChange("isRequired", e);
                }}
              ></Checkbox>
              <ControlLabel>Required</ControlLabel>
              <FormErrorMessage error={formErrors.isRequired} />
            </div>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer className={styles.addFieldFooter}>
        <Button
          className={`px-5 ${styles.deleteButton}`}
          appearance="subtle"
          onClick={() => {
            removeField(currentIndex);
            closeModal();
          }}
        >
          <Icon icon={"trash"} />
        </Button>
        <div className="w-100">
          <Button
            className={"px-4"}
            onClick={() => {
              closeModal();
            }}
            appearance="subtle"
          >
            {"Cancel"}
          </Button>
          <Button
            className={"px-5"}
            appearance="primary"
            onClick={() => handleSubmit()}
          >
            {"Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateFieldModal;
