import i18next from 'i18next';
import pages_en from "./translations/en/pages.json";
import components_en from "./translations/en/components.json";
import api_codes_en from "./translations/en/api_codes.json";
import validation_en from "./translations/en/validation.json";

i18next
    .init({
        interpolation: { escapeValue: false }, // React already does escaping
        lng: 'en',
        resources: {
            en: {
                pages: pages_en,
                api_codes: api_codes_en,
                components: components_en,
                validation:validation_en
            },
        }
    })
export default i18next