import React, {useRef} from 'react';
import {SASS_COLORS} from "config/constants";
import {Line} from '@reactchartjs/react-chart.js'
import styles from "./InsightsStatistics.module.scss"
import {useRecoilValue} from "recoil";
import {insightsAtom} from "store/insightsRecoil";
import {INSIGHTS_TYPES} from "utils/enums";
import {WaitingField} from "../../index";
import {useTranslation} from "react-i18next";
import {format_dateISO, format_dateTimeISO, formatBalance} from "utils/helpers";
import {useCanBreakPointMobile, useCanBreakPointMobileTable} from "hooks/useViewport";
import {Placeholder} from "rsuite"

const {Graph} = Placeholder

function InsightsStatistics({loading}) {
    const {results, type} = useRecoilValue(insightsAtom)
    const isDays = type === INSIGHTS_TYPES.DAYS
    const breakpointMobile = useCanBreakPointMobile();
    const breakpointMedium = useCanBreakPointMobileTable();

    let ref = useRef();

    const {t} = useTranslation("pages")
    React.useEffect(() => {
        ref?.update && ref?.update()
    }, [results])

    const getStepSize = () => {
        return Math.floor(Math.max.apply(Math, results?.map(function (o) {
            return o.total;
        })) / 4)
        // 4 is the number of steps
    }

    const getChartHeight = () => {
        return breakpointMobile ? 220 : (breakpointMedium ? 120 : 80)
    }

    if ((!results || results?.length === 0) && loading)
        return <div className={styles.container}>
            <Graph
                style={{
                    width: "100%"
                }}
                active
            />
            {/*<Message
                content={t("insights.noData")}
                type={"warning"}
            />*/}
        </div>
    return (
        <div className={styles.container}>
            <WaitingField
                height={300}
                wait={loading}>
                <Line
                    height={getChartHeight()}
                    ref={(r) => ref = r}
                    data={
                        {
                            id: "default",
                            labels: results?.map(e => {
                                return isDays ?
                                    format_dateISO(e.date) :
                                    format_dateTimeISO(e.date)
                            }),
                            datasets: [
                                {
                                    label: 'QAR',
                                    data: results?.map(e => e.total),
                                    fill: false,
                                    backgroundColor: SASS_COLORS.PRIMARY,
                                    borderColor: SASS_COLORS.PRIMARY,
                                    lineTension: 0,
                                    pointBorderColor: "white",
                                    pointBorderWidth: 2,
                                    pointRadius: 6,
                                    pointHoverRadius: 8,
                                    pointHoverBorderWidth: 8,
                                    pointHoverBorderColor: SASS_COLORS.PRIMARY,
                                    pointBackgroundColor: SASS_COLORS.PRIMARY,
                                    pointHoverBackgroundColor: "white",

                                },
                            ],
                        }
                    }

                    options={
                        {
                            scales: {
                                yAxes: [
                                    {
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'QAR',
                                            align: "start"
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            callback: function (value, index, values) {
                                                return value;
                                            },
                                            fontColor: SASS_COLORS.GRAY,
                                            fontFamily: "roboto",
                                            stepSize: getStepSize()
                                        },
                                        gridLines: {
                                            color: SASS_COLORS.LIGHT_GRAY,
                                            borderDash: [5, 10],
                                        }
                                    },
                                ],
                                xAxes: [
                                    {
                                        type: 'time',
                                        // distribution: 'series',
                                        time: {
                                            round: true,
                                            unit: isDays ? "day" : "hour",
                                            displayFormats: {
                                                quarter: 'MMM D'
                                            },
                                            bounds: "ticks"
                                        },
                                        ticks: {
                                            fontColor: SASS_COLORS.GRAY,
                                            fontFamily: "roboto"
                                        },
                                        gridLines: {
                                            display: false
                                        }
                                    }
                                ]

                            },
                            tooltips: {
                                title: null,
                                displayColors: false,
                                backgroundColor: SASS_COLORS.SECONDARY,
                                bodyFontFamily: "roboto",
                                footerFontFamily: "roboto",
                                callbacks: {
                                    label: function (context) {
                                        let {label, value} = context
                                        return `QAR ${formatBalance(value)}`
                                    },
                                    title: function (context) {
                                        const pos = context[0]
                                        if (!pos)
                                            return ""
                                        const {label} = pos
                                        if (isDays)
                                            return label.substring(0, label.lastIndexOf(","))
                                        return label
                                    }
                                }

                            },
                            legend: {
                                display: false
                            },

                        }
                    }/>
            </WaitingField>
        </div>
    );
}

export default InsightsStatistics;