import * as React from "react";

function SvgLinkIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.25 8.6a3.85 3.85 0 100-7.7 3.85 3.85 0 000 7.7z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
      <path
        d="M10.934 3.5v-.1h-6.35a3.85 3.85 0 00-3.85 3.85v9.167a3.85 3.85 0 003.85 3.85h9.167a3.85 3.85 0 003.85-3.85v-6.35h-1.034v6.35a2.817 2.817 0 01-2.816 2.816H4.584a2.817 2.817 0 01-2.817-2.816V7.25a2.817 2.817 0 012.817-2.817h6.35V3.5z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgLinkIcon;
