import { useAPI } from "../hooks/useAPI";
import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  authAtom,
  loginAtom,
  registerAtom,
  resetPasswdAtom,
} from "store/authRecoil";
import jwt_decode from "jwt-decode";
import { getCognitoCredentials } from "utils/helpers";
import Cookies from "js-cookie";
import { GUARDS } from "config/guards";
import { ENV } from "config/env";
import axios from "axios";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

import AWS from "aws-sdk";

import { COGNITO_EXCEPTIONS } from "utils/enums";

function useLoginService() {
  const { call, ...rest } = useAPI();
  const setAuth = useSetRecoilState(authAtom);
  const resetLoginFields = useResetRecoilState(loginAtom);
  var sessionUserAttributes = {};
  useEffect(() => {}, []);

  return {
    call: ({ email, password, new_password = null, token = null }) => {
      return new Promise((resolve, reject) => {
        const authenticationData = {
          Username: email.toLowerCase(),
          Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(
          authenticationData
        );
        const userPool = new CognitoUserPool(getCognitoCredentials());
        const userData = {
          Username: email.toLowerCase(),
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
          newPasswordRequired: function (userAttributes, requiredAttributes) {
            delete userAttributes.email_verified;
            delete userAttributes.email;
            delete userAttributes.phone_number;
            sessionUserAttributes = userAttributes;

            if (new_password) {
              cognitoUser.completeNewPasswordChallenge(
                new_password,
                sessionUserAttributes,
                this
              );
            } else {
              reject({
                name: COGNITO_EXCEPTIONS.NEW_PASSWORD_REQUIRED,
                message: "",
              });
            }
          },

          totpRequired: function () {
            if (token) {
              cognitoUser.sendMFACode(token, this, "SOFTWARE_TOKEN_MFA");
            } else {
              reject({
                name: COGNITO_EXCEPTIONS.MFA_REQUIRED,
                message: "",
              });
            }
          },

          onSuccess: function (result) {
            const accessToken = result.getAccessToken().getJwtToken();
            const { username } = jwt_decode(accessToken);
            const auth = {
              token: accessToken,
              username,
              guard: GUARDS.USER,
              init: true,
            };
            Cookies.set("auth", JSON.stringify(auth), {
              path: "/",
            });
            resetLoginFields();
            setAuth(auth);
            resolve(true);
          },

          onFailure: function (err) {
            reject(err);
          },
        });
      });
    },

    ...rest,
  };
}

function useSignupService() {
  const { firstName, lastName, phone, email, password, code } = useRecoilValue(
    registerAtom
  );
  const { call, ...rest } = useAPI();

  let _code = code;
  let _email = email;

  useEffect(() => {}, []);

  return {
    call: (membership, role, organizationId) => {
      return new Promise((resolve, reject) => {
        const userPool = new CognitoUserPool(getCognitoCredentials());
        let attributeList = [];

        const firstNameData = {
          Name: "custom:firstName",
          Value: firstName,
        };

        const lastNameData = {
          Name: "custom:lastName",
          Value: lastName,
        };

        const phoneData = {
          Name: "phone_number",
          Value: phone,
        };

        const emailData = {
          Name: "email",
          Value: email.toLowerCase(),
        };

        const attrFirstName = new CognitoUserAttribute(firstNameData);
        const attrLastName = new CognitoUserAttribute(lastNameData);
        const attrPhone = new CognitoUserAttribute(phoneData);
        const attrEmail = new CognitoUserAttribute(emailData);

        attributeList.push(attrFirstName);
        attributeList.push(attrLastName);
        attributeList.push(attrPhone);
        attributeList.push(attrEmail);

        // if signup from invitation
        if (membership) {
          const membershipData = {
            Name: "custom:membership",
            Value: membership,
          };
          const attrMembership = new CognitoUserAttribute(membershipData);
          attributeList.push(attrMembership);
        }

        if (role && organizationId) {
          const attrOrganizationId = new CognitoUserAttribute({
            Name: "custom:organizationId",
            Value: organizationId,
          });
          const attrRole = new CognitoUserAttribute({
            Name: "custom:oldRole",
            Value: role,
          });
          attributeList.push(attrOrganizationId);
          attributeList.push(attrRole);
        }

        userPool.signUp(
          email.toLowerCase(),
          password,
          attributeList,
          null,
          function (err, result) {
            if (err) {
              reject(err?.message);
            } else {
              resolve(true);
            }
            return;
          }
        );
      });
    },
    verify: (temp_email, temp_code) => {
      if (temp_email && temp_code) {
        _email = temp_email;
        _code = temp_code;
      }
      return new Promise((resolve, reject) => {
        const userPool = new CognitoUserPool(getCognitoCredentials());
        const userData = {
          Username: _email,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(_code, true, function (err, result) {
          if (err) {
            reject(err?.message);
          } else {
            resolve(true);
          }
          return;
        });
      });
    },
    getMembership: (membershipId) =>
      call({
        link: `/memberships/${membershipId}`,
      }),
    resendVerificationCode: () => {
      return new Promise((resolve, reject) => {
        const userPool = new CognitoUserPool(getCognitoCredentials());
        const userData = {
          Username: email,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.resendConfirmationCode(function (err, result) {
          if (err) {
            reject(err?.message);
          } else {
            resolve(true);
          }
          return;
        });
      });
    },
    ...rest,
  };
}

function useForgotPasswordService() {
  useEffect(() => {}, []);
  const call = ({ email }) => {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(getCognitoCredentials());
      const userData = {
        Username: email.toLowerCase(),
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          resolve({ success: true });
        },
        onFailure: function (err) {
          reject(err.message);
        },
      });
    });
  };
  return {
    call,
  };
}

function useResetPasswordService() {
  useEffect(() => {}, []);
  const call = ({ email, password, code }) => {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(getCognitoCredentials());
      const userData = {
        Username: email.toLowerCase(),
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmPassword(code, password, {
        onSuccess() {
          resolve({
            success: true,
          });
        },
        onFailure(err) {
          reject(err.message);
        },
      });
    });
  };
  return {
    call,
  };
}

function useChangePassword() {
  useEffect(() => {}, []);
  const call = ({ current_password, new_password }) => {
    return new Promise((resolve, reject) => {
      if (current_password === new_password) {
        reject("The new password cannot be the same as the current password.");
      }
      const cognitoUser = getAuthenticatedUser();
      if (cognitoUser) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            reject(err.message);
          } else {
            cognitoUser.changePassword(
              current_password,
              new_password,
              function (err, res) {
                if (err) {
                  reject(err.message);
                } else {
                  resolve({
                    success: true,
                  });
                }
              }
            );
          }
        });
      } else {
        reject("User is not authenticated");
      }
    });
  };
  return {
    call,
  };
}

function useSignOut() {
  const setAuth = useSetRecoilState(authAtom);
  useEffect(() => {}, []);
  const call = (refreash = true) => {
    const cognitoUser = getAuthenticatedUser();
    if (cognitoUser) {
      cognitoUser.getSession(function (err, session) {
        if (session) {
          cognitoUser.signOut();
        }
      });
    }
    Cookies.remove("auth");
    if (refreash) {
      window?.location?.reload();
    } else {
      setAuth({
        guard: GUARDS.GUEST,
        init: false,
      });
    }
  };
  return {
    call,
  };
}

function useGetUserSession() {
  return {
    get: () => {
      return new Promise((resolve, reject) => {
        const user = getAuthenticatedUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              reject();
            } else {
              const cognito = new AWS.CognitoIdentityServiceProvider({
                region: ENV.USER_POOL_REGION,
              });
              const accessToken = session.accessToken.jwtToken;
              const token = session.getIdToken().getJwtToken();

              const mfaEnabled = await new Promise((resolve) => {
                cognito.getUser(
                  {
                    AccessToken: accessToken,
                  },
                  (err, data) => {
                    if (err) {
                      resolve(false);
                    } else {
                      resolve(
                        data.UserMFASettingList &&
                          data.UserMFASettingList.includes("SOFTWARE_TOKEN_MFA")
                      );
                    }
                  }
                );
              });

              resolve({
                user,
                mfaEnabled: mfaEnabled ? mfaEnabled : false,
                accessToken,
                headers: {
                  Authorization: token,
                },
                ...session,
              });
            }
          });
        } else {
          reject();
        }
      });
    },
  };
}

function useGetQrCode() {
  const url =
    ENV.AWS_LAMBDA_URL + (ENV.ENVIRONMENT == "DEV" ? "/test-mfa" : "/mfa");
  return {
    get: async (accessToken, headers) => {
      return await axios.get(url, {
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        params: {
          accessToken,
        },
      });
    },
  };
}

function useDeleteAuthenticatedUser() {
  const call = (email) => {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(getCognitoCredentials());
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.getSession(function (err, session) {
        if (err) {
          reject(err.message);
        } else {
          cognitoUser.deleteUser(function (err, result) {
            if (err) {
              reject(err?.message);
            } else {
              resolve();
            }
          });
        }
      });
    });
  };
  return {
    call,
  };
}

function useMfaSettings() {
  const url =
    ENV.AWS_LAMBDA_URL + (ENV.ENVIRONMENT == "DEV" ? "/test-mfa" : "/mfa");
  return {
    validate: async (accessToken, headers, code) => {
      return await axios.post(
        url,
        {},
        {
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
          params: {
            accessToken,
            userCode: code,
          },
        }
      );
    },

    enable: (user) => {
      const settings = {
        PreferredMfa: true,
        Enabled: true,
      };
      return new Promise((resolve, reject) => {
        user.setUserMfaPreference(null, settings, function (err, result) {
          if (err) {
            reject(err.message);
          } else {
            resolve();
          }
        });
      });
    },
    disable: async (accessToken, headers, code, user) => {
      return new Promise((resolve, reject) => {
        axios
          .delete(url, {
            headers: {
              ...headers,
              "Content-Type": "application/json",
            },
            params: {
              accessToken,
              userCode: code,
            },
          })
          .then((res) => {
            if (res.data?.errorType === "UserNotFoundException") {
              const settings = {
                PreferredMfa: false,
                Enabled: false,
              };
              user.setUserMfaPreference(null, settings, function (err, result) {
                if (err) {
                  reject(err.message);
                } else {
                  resolve();
                }
              });
            } else if (
              res.data?.errorType === "EnableSoftwareTokenMFAException"
            ) {
              reject("Please provide a valid 6-digit number");
            } else {
              reject(
                "There was an error while disabling MFA, please try again later."
              );
            }
          })
          .catch((err) => {
            reject(
              "There was an error while disabling MFA, please try again later."
            );
          });
      });
    },
  };
}

function getAuthenticatedUser() {
  const userPool = new CognitoUserPool(getCognitoCredentials());
  return userPool.getCurrentUser();
}

export {
  useLoginService,
  useSignupService,
  useForgotPasswordService,
  useResetPasswordService,
  useSignOut,
  useChangePassword,
  useMfaSettings,
  useGetQrCode,
  useGetUserSession,
  useDeleteAuthenticatedUser,
};
