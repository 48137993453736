import { atom } from "recoil";
import { api_date } from "../utils/helpers";

export const balanceFilterAtom = atom({
  key: "balanceFilterAtom",
  default: {
    isCustomPeriod: false,
    from: 0,
    to: new Date(),
    period: "anytime",
    custom_period: [],
  },
});

export const balanceTableSettingsAtom = atom({
  key: "balanceTableSettingsAtom",
  default: {
    count: 20,
    page: 1,
    totalCounts: 0,
    loading: false,
  },
});

export const balanceDetailsDataAtom = atom({
  key: "balanceDetailsDataAtom",
  default: {
    data: [],
    onHold: "",
    currency: "QAR",
    sentToBank: 0,
    availableBalance: 0,
  },
});

export const balanceOrderDetailsAtom = atom({
  key: "balanceOrderDetailsAtom",
  default: {
    amount: "",
    amount_net: "",
    fees: "",
    refund_deducted: "",
    currency: "QAR",
    settledAt: api_date(new Date()),
    bankAccount: {},
    transactions: [],
  },
});

export const reloadBalanceDetailsAtom = atom({
  key: "reloadBalanceDetailsAtom",
  default: {
    reload: false,
  },
});
