import React, { useRef, useState } from "react";
import { Form, InputPicker, ControlLabel, FormGroup, Icon } from "rsuite";
import { useCustomersService } from "services/CustomersService";
import { debounce } from "lodash";


function OldCustomerForm({ formValue, setFormValue, formErrors }) {
  const form = useRef(null);
  const [data, setData] = useState(
    Object.keys(formValue).length ? [{ ...formValue }] : []
  );
  const [loading, setLoading] = useState(false);

  const customersService = useCustomersService();

  function getUsers(word) {
    customersService
      .fetch_data(word)
      .then((res) => {
        let { data, success } = res;
        if (success) {
          setData(data?.content?.filter((customer) => customer !== null));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleSearch(word) {
    if (!word) {
      return;
    }

    setLoading(true);
    debounce(() => {
      getUsers(word);
    }, 800)();
  }

  function handleSelect(value, item, e) {
    setFormValue(item);
  }

  return (
    <Form
      ref={form}
      checkTrigger={"blur"}
      // onChange={(formValue) => {
      //   setFormValue(formValue);
      // }}
      //formDefaultValue={formValue}
      formError={formErrors}
      fluid
    >
      {/* <FormField
        label={"Find cutomer"}
        error={formErrors.customerId}
        placeholder={"Search by ID, name, phone ..."}
        name={"customerId"}
      /> */}

      <FormGroup>
        <ControlLabel>Find cutomer</ControlLabel>
        <InputPicker
          block
          data={data}
          placeholder={
            Object.keys(formValue).length
              ? formValue?.name || formValue?.id
              : "Search by ID, name, phone"
          }
          labelKey="name"
          className={Object.keys(formValue).length ? "custom-as-text" : ""}
          valueKey="id"
          onSearch={handleSearch}
          cleanable={false}
          onSelect={handleSelect}
          renderMenu={(menu) => {
            if (loading) {
              return (
                <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                  <Icon icon="spinner" spin /> Loading...
                </p>
              );
            }
            return menu;
          }}
        />
      </FormGroup>
    </Form>
  );
}

export default OldCustomerForm;
