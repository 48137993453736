import React from 'react';
import styles from "./Checkout.module.scss"
import FakeRowStyles from "./FakeRow.module.scss"
import {Col, Row} from "rsuite";
import {Image} from "../index";

/**
 *
 * @param {number} price
 * @param {string} currency
 * @returns {JSX.Element}
 * @constructor
 */
function Checkout({
                      price,
                      currency = "QAR",
                      color = "#47d175"
                  }) {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerCardsSection}>
                    <Image src={"visa.svg"} alt="visa"/>
                    <Image src={"mastercard.svg"} alt="mastercard"/>
                    <Image src={"amex.svg"} alt="amex"/>
                </div>
            </div>
            {/*<h2 className={styles.price}>{price} {currency}</h2>*/}
            <Row>
                <Col xs={24}>
                    <FakeRow/>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <FakeRow
                        rightElement={<Image src={"no_card.svg"} alt={"No card"}/>}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FakeRow/>
                </Col>
                <Col xs={12}>
                    <FakeRow
                        rightElement={<Image src={"no_cvc.svg"} alt={"No CVC"}/>}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <FakeRow inverse={true} preview={color}/>
                </Col>
            </Row>
        </div>
    );
}

const FakeRow = ({inverse, rightElement, preview}) => {
    return <div className={`${FakeRowStyles.row} ${inverse && !preview ? FakeRowStyles.dark : ""}`}
                style={(preview) ? { background: preview }: {}}
            >
        <div className={FakeRowStyles.rightElement}>
            {rightElement}
        </div>
    </div>
}

export default Checkout;