import React, {useEffect, useState} from 'react';
import styles from "./RadioComponent.module.scss"
import {Button} from "rsuite";
import styled from "styled-components"
import FormErrorMessage from "../form-error-message";

const RadioContainer = styled.div`
  button {
    border-radius: 100px !important;
    padding: 4px 20px !important;
  }

  margin-right: 6px;

  &:last-child {
    margin-right: 0px;
  }
`

/**
 *
 * @param {[{value:string,label:string}]} items
 * @param value
 * @param defaultValue
 * @param onChange
 * @param errorMessage
 * @returns {JSX.Element}
 * @constructor
 */
function RadioComponent({items = [], value, defaultValue, onChange, errorMessage}) {
    const [active, setActive] = useState(defaultValue);
    useEffect(() => {
        handleActive(value ?? defaultValue)
    }, [])

    useEffect(() => {
        setActive(value)
    }, [value])

    const handleActive = (value) => {
        setActive(value)
        onChange && onChange(value)
    }

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                {
                    items.map(e => <RadioContainer key={e.value}><Button
                        size={"sm"}
                        appearance={active === e.value ? "primary" : "default"}
                        onClick={() => handleActive(e.value)}
                    >
                        {e.label}
                    </Button>
                    </RadioContainer>)
                }
            </div>
            <FormErrorMessage
                error={errorMessage}
            />

        </div>
    );
}

export default RadioComponent;