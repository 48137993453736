import React from 'react';
import styles from "./feedback-content.module.scss";
import {useRecoilValue} from "recoil";
import {feedbackContentAtom} from "store/checkoutRecoil";
import {Image} from "../../index";

const Index = () => {
    const {success, feedbackTitle, feedbackText} = useRecoilValue(feedbackContentAtom)
    return (
        <div className={styles.feedback}>
            <Image src={success ? "success.svg" : "error.svg"} alt=""/>
            <h3 className={success ? styles.titleSuccess : styles.titleError}>{feedbackTitle}</h3>
            <p className={styles.feedbackText}>{feedbackText}</p>
        </div>
    );
};

export default Index;