import { atom } from "recoil";
import { GUARDS } from "../config/guards";

/*
 * accountId,
 * guard,
 * merchantId,
 * token
 * */
export const forgotPasswdAtom = atom({
  key: "forgotPasswd",
  default: {
    email: "",
    forgotSuccessSent: false,
    forgotFailedSent: false,
    loadingBtn: false,
  },
});

export const loginAtom = atom({
  key: "loginAtom",
  default: {
    mfa: false,
    code: "",
    email: "",
    password: "",
    loginSuccess: true,
    hidePassword: true,
    loadingBtn: false,
  },
});

export const authAtom = atom({
  key: "authAtom",
  default: {
    guard: GUARDS.GUEST,
  },
});

export const registerAtom = atom({
  key: "registerAtom",
  default: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    hidePassword: true,
    code: "",
    loadingBtn: false,
  },
});

export const resetPasswdAtom = atom({
  key: "resetPasswd",
  default: {
    type: "default",
    old_password: "",
    email: "",
    password: "",
    c_password: "",
    code: "",
    hidePassword: true,
    isCodeValide: false,
    resetSuccess: false,
    resetFail: false,
    loadingBtn: false,
  },
});

export const changePasswdAtom = atom({
  key: "changePasswd",
  default: {
    current_password: "",
    new_password: "",
    c_password: "",
    hidCurrentPwd: true,
    hideNewpPwd: true,
    changeSuccess: false,
    changeFail: false,
    loadingBtn: false,
  },
});
