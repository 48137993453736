import { useRecoilState, useSetRecoilState } from "recoil";
import { authAtom } from "store/authRecoil";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useAPI } from "hooks/useAPI";
import { userAtom } from "store/userRecoil";
import { organizationAtom } from "store/organizationRecoil";
import { init_loader } from "../config/loaders";
import { GUARDS } from "../config/guards";
import { useLocation } from "react-router";
import { settingsAtom } from "../store/settingsRecoil";
import { ONBOARDING_STATUS } from "../utils/enums";
import { isPrivateRoute } from "../utils/helpers";
import { ONBOARDING_DOCS } from "../utils/enums";
import { omit } from "lodash";
import { fileNameFromKey } from "utils/helpers";

//

import {
  companyAtom,
  documentsUploadedAtom,
  payoutAccountAtom,
  profileAtom,
} from "store/onboardingRecoil";
import { onboardStepsRecoil } from "store/onboardStepsRecoil";
import useDetectStateChange from "../hooks/useDetectStateChange";
import { currentRoleAtom } from "store/userRecoil";

import {
  documentsOtherDocumentsAtom,
  documentsQidDocumentsAtom,
  documentsBankAccountStatementAtom,
  documentsCrFileAtom,
  loadingStateAtom,
} from "store/onboardingRecoil";

function useAppInitiateService() {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [ready, setReady] = useState(false);
  const setSettings = useSetRecoilState(settingsAtom);
  const setUser = useSetRecoilState(userAtom);
  const [orgInformation, setOrganization] = useRecoilState(organizationAtom);
  const setProfile = useSetRecoilState(profileAtom);
  const setPayout = useSetRecoilState(payoutAccountAtom);
  const setCompany = useSetRecoilState(companyAtom);
  const setStep = useSetRecoilState(onboardStepsRecoil);
  const setDocumentsUploaded = useSetRecoilState(documentsUploadedAtom);
  const setCurrentRole = useSetRecoilState(currentRoleAtom);
  const { globalValuesInitiate } = useDetectStateChange();

  //

  const setCrFile = useSetRecoilState(documentsCrFileAtom);
  const setQidDocuments = useSetRecoilState(documentsQidDocumentsAtom);
  const setDocumentsBankAccountStatement = useSetRecoilState(
    documentsBankAccountStatementAtom
  );
  const setDocumentsOtherDocuments = useSetRecoilState(
    documentsOtherDocumentsAtom
  );

  const setLoading = useSetRecoilState(loadingStateAtom);

  //
  const location = useLocation();

  const apiCall = useAPI();

  const setAuthGuest = () => {
    setAuth({
      guard: GUARDS.GUEST,
      init: true,
    });
    setLoading(true);
    setReady(false);
  };

  const authToken = () => {
    if (!isPrivateRoute(location)) {
      setReady(true);
      return;
    }
    const authCookie = Cookies.get("auth");
    if (authCookie) {
      try {
        const _auth = {
          ...JSON.parse(authCookie),
          init: true,
        };
        apiCall
          .call({
            method: "POST",
            link: "users/auth",
            token: _auth.token,
            reloadPageOn403: false,
          })
          .then((res) => {
            if (res?.status !== 200) {
              setAuthGuest();
            } else {
              setAuth({
                ..._auth,
                init: true,
              });
            }
          });
      } catch (ex) {
        setAuthGuest();
      }
    } else {
      setAuthGuest();
    }
  };

  const init = () => {
    authToken();
  };

  useEffect(() => {
    if (auth.init) {
      //exclude this route from initiating merchant info
      if (isPrivateRoute(location)) {
        initInformation().then(() => {
          setReady(true);
          
        });
      } else {
        setReady(true);
      }
    }
  }, [auth]);

  const initInformation = () => {
    return new Promise((resolve, reject) => {
      if (auth?.token) {
        apiCall
          .call({
            link: `/users/current`,
            loading_id: init_loader.fetch_information,
          })
          .then((res) => {
            if (res.success) {
              const _user = omit(res.data, "organization");
              const _organization = res.data?.organization;
              const __organizationsList = res.data?.organizations;
              const onboardingStatus =
                _organization?.onboarding?.status ||
                ONBOARDING_STATUS.NEEDS_DATA;
              let testMode = _organization?.testMode;
              //
              let step = 0;
              const {
                onboarding,
                payout,
                profile,
                legalRepresentative,
                legalIdentity,
                displayName,
              } = _organization || {};

              const files = onboarding?.files;

              if (onboardingStatus !== ONBOARDING_STATUS.COMPLETED) {
                testMode = true;
              }

              const currentRole = __organizationsList.filter(
                (org) => org?.id === _organization?.id
              )[0]?.role;

              setCurrentRole({
                role: currentRole?.toLowerCase(),
              });

              setUser({
                ..._user,
                ...{
                  firstName: _user?.firstName?.trim(),
                  lastName: _user?.lastName?.trim(),
                },
              });

              setOrganization({ ..._organization });

              setSettings((old) => ({ ...old, testMode: testMode }));

              //each step depends on previous step
              if (
                Object.keys(legalRepresentative)?.length &&
                legalIdentity?.registrationNumber !== null
              ) {
                step = 1;
                setCompany({ ...legalIdentity, ...legalRepresentative });
                globalValuesInitiate({
                  key: companyAtom.key,
                  value: { ...legalIdentity, ...legalRepresentative },
                });

                if (Object.keys(profile)?.length) {
                  step = 2;
                  setProfile({ ...profile, ...{ displayName: displayName } });
                  globalValuesInitiate({
                    key: profileAtom.key,
                    value: { ...profile },
                  });
                  if (payout) {
                    step = 3;
                    setPayout({ ...payout });

                    globalValuesInitiate({
                      key: payoutAccountAtom.key,
                      value: { ...payout },
                    });
                    if (files) {
                      setQidDocuments([]);
                      setDocumentsOtherDocuments([]);
                      setDocumentsBankAccountStatement([]);
                      setCrFile([]);
                      //
                      if (files?.length > 0) {
                        files.forEach((file) => {
                          const _file = {
                            ...file,
                            name: fileNameFromKey(file?.key),
                            progress: 100,
                            status: "finished",
                            servKey: file?.key,
                          };
                          switch (file?.name) {
                            case ONBOARDING_DOCS.BANK_STATEMENT:
                              setDocumentsBankAccountStatement((oldArray) => [
                                ...oldArray,
                                _file,
                              ]);
                              break;

                            case ONBOARDING_DOCS.QID:
                              setQidDocuments((oldArray) => [
                                ...oldArray,
                                _file,
                              ]);
                              break;

                            case ONBOARDING_DOCS.COMMERCIAL_REGISTRATION:
                              setCrFile((oldArray) => [...oldArray, _file]);
                              break;

                            case ONBOARDING_DOCS.OTHER_DOCS:
                              setDocumentsOtherDocuments((oldArray) => [
                                ...oldArray,
                                _file,
                              ]);
                              break;
                          }
                        });
                      }
                    }
                    if (onboardingStatus !== ONBOARDING_STATUS.NEEDS_DATA) {
                      step = 4;
                      setDocumentsUploaded(true);
                    }
                  }
                }
              }
              setStep(step);
            }
          })
          .finally(() => {
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  const toggleTestMode = () => {
    apiCall
      .call({
        link: `/organizations/${orgInformation?.id}/testmode?organizationId=${orgInformation?.id}`,
        loading_id: "app_toggle_test_mode",
        method: "POST",
      })
      .then((res) => {
        if (res?.success) {
          setSettings((old) => ({ ...old, testMode: res?.data?.testMode }));
        }
      });
  };

  return {
    initInformation,
    init,
    ready: ready,
    toggleTestMode,
    loading: apiCall.loading,
  };
}

export { useAppInitiateService };
