import {Schema} from "rsuite";
import i18n from "../i18n";

const {StringType} = Schema.Types;
const model = Schema.Model({
    email: StringType()
        .isEmail(i18n.t("pages:login.schema.invalidEmail"))
        .isRequired(i18n.t("pages:login.schema.requiredEmail")),
    password: StringType()
        .isRequired(i18n.t("pages:login.schema.requiredPasswd"))
        .minLength(8,i18n.t("pages:login.schema.invalidPasswd"))
});


export default  model;