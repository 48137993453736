import {Schema} from "rsuite";
import i18n from "../i18n";
import {isValidPhoneNumber} from "libphonenumber-js";

const {StringType} = Schema.Types;

const model = Schema.Model({
    name: StringType().isRequired(i18n.t("validation:fullNameRequired")),
    email: StringType().isRequired(i18n.t("validation:emailRequired"))
        .isEmail(i18n.t("validation:emailNotValid")),
    phone: StringType().isRequired(i18n.t("validation:phoneRequired"))
        // .pattern(/^\+(?:[0-9] ?){6,14}[0-9]$/,
        //     i18n.t("validation:phoneNotValid"))
    // .addRule((value) => {
    //     return isValidPhoneNumber(value)
    // }, i18n.t("validation:phoneNotValid")),
})
export default model