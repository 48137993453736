import { useAPI } from "../hooks/useAPI";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom } from "store/authRecoil";
import { organizationAtom } from "store/organizationRecoil";

// function useGetOnboaringService() {
//   const { call, ...rest } = useAPI();

//   const setProfile = useSetRecoilState(profileAtom);
//   const setPayout = useSetRecoilState(payoutAccountAtom);
//   const setCompany = useSetRecoilState(companyAtom);
//   const setStep = useSetRecoilState(onboardStepsRecoil);
//   const setDocumentsUploaded = useSetRecoilState(documentsUploadedAtom);
//   const { globalValuesInitiate } = useDetectStateChange();
//   return {
//     call: () => {
//       return call({
//         link: `/users/current`,
//         onSuccess: (data) => {
//           let step = 0;
//           const {
//             onboarding: documents,
//             payout,
//             profile,
//             legalRepresentative,
//             legalIdentity,
//           } = data?.organization || {};
//           //each step depends on previous step
//           if (legalRepresentative && legalIdentity?.legalName) {
//             step = 1;
//             setCompany({ ...legalIdentity, ...legalRepresentative });
//             globalValuesInitiate({
//               key: companyAtom.key,
//               value: { ...legalIdentity, ...legalRepresentative },
//             });

//             if (profile) {
//               step = 2;
//               setProfile({ ...profile });
//               globalValuesInitiate({
//                 key: profileAtom.key,
//                 value: { ...profile },
//               });
//               if (payout) {
//                 step = 3;
//                 setPayout({ ...payout });

//                 globalValuesInitiate({
//                   key: payoutAccountAtom.key,
//                   value: { ...payout },
//                 });
//                 if (
//                   documents?.qidDoc?.length > 0 &&
//                   documents?.bankAccountStatement &&
//                   documents?.orgCommercialRegistrationDoc
//                 ) {
//                   let keys = Object.keys(documents);
//                   keys = keys.filter((e) => {
//                     return e !== "status";
//                   });
//                   let valid = true;
//                   for (let key of keys) {
//                     const _d = documents[key];
//                     if (_.isArray(_d)) {
//                       if (_d.length <= 0 && key !== "otherDoc") {
//                         valid = false;
//                         break;
//                       }
//                     } else if (_.isObject(_d)) {
//                       if (!(_d?.path && _d?.key)) {
//                         valid = false;
//                         break;
//                       }
//                     }
//                   }
//                   if (valid) {
//                     step = 4;
//                     setDocumentsUploaded(true);
//                   }
//                 }
//               }
//             }
//           }
//           setStep(step);
//         },
//       });
//     },
//     ...rest,
//   };
// }

function usePayoutService() {
  const { call, ...rest } = useAPI();
  const orgInformation = useRecoilValue(organizationAtom);
  return {
    call: ({ bankName, accountName, iban }) =>
      call({
        link: `/organizations/${orgInformation?.id}/payouts?organizationId=${orgInformation?.id}`,
        method: "PUT",
        data: {
          bankName,
          accountName,
          iban,
        },
        onSuccess: () => {
          window.localStorage.setItem("step", 3);
        },
      }),
    ...rest,
  };
}

function useProfileService() {
  const { call, ...rest } = useAPI();
  const orgInformation = useRecoilValue(organizationAtom);

  return {
    call: ({
      url,
      categoryCode,
      productsAndServices,
      volumeEstimate,
      displayName,
    }) =>
      call({
        link: `/organizations/${orgInformation?.id}/profile?organizationId=${orgInformation?.id}`,
        method: "PUT",
        data: {
          ...(url ? { url } : {}),
          categoryCode,
          productsAndServices,
          volumeEstimate,
          displayName: displayName,
        },
      }),
    ...rest,
  };
}

function useCompanyService() {
  const { call, ...rest } = useAPI();
  const [orgInformation, setInformation] = useRecoilState(organizationAtom);
  return {
    call: ({
      fullName,
      nationalityCode,
      idNumber,
      legalName,
      ubo,
      countryCode,
      agree,
    }) =>
      call({
        link: `/organizations/${orgInformation?.id}/company?organizationId=${orgInformation?.id}`,
        data: {
          representative: {
            fullName,
            nationalityCode,
            ubo,
            idNumber,
          },
          legalIdentity: {
            legalName,
            agree,
            countryCode,
          },
        },
        onSuccess: (data) => {
          setInformation((old) => ({
            ...old,
            fullName,
            company: legalName,
          }));
        },

        method: "PUT",
      }),
    ...rest,
  };
}

function useDocumentsService() {
  const { call, ...rest } = useAPI();
  const orgInformation = useRecoilValue(organizationAtom);

  return {
    deleteDocument: ({ name, path, key }) =>
      call({
        link: `/organizations/${orgInformation?.id}/onboarding/files?organizationId=${orgInformation?.id}
        `,
        method: "DELETE",
        data: {
          name: name,
          key: key,
        },
      }),

    submitLastStep: () =>
      call({
        link: `/organizations/${orgInformation?.id}/onboarding?organizationId=${orgInformation?.id}
        `,
        method: "PUT",
      }),
    ...rest,
  };
}

function useDocumentsMobileUploadService() {
  const { call, ...rest } = useAPI();
  const auth = useRecoilValue(authAtom);

  return {
    getToken: ({ qrLink }) => {
      return call({
        link: `/merchants/${auth.merchantId}/onboarding/files/mobile/generate_token?qrLink=${qrLink}`,
      });
    },
    checkToken: ({ token, merchantId }) =>
      call({
        link: `/merchants/${merchantId}/onboarding/files/mobile/verify_token`,
        token,
      }),
    ...rest,
  };
}

export {
  useCompanyService,
  useProfileService,
  usePayoutService,
  useDocumentsService,
  // useGetOnboaringService,
  useDocumentsMobileUploadService,
};
