import { useAPI } from "hooks/useAPI";
import { authAtom } from "../store/authRecoil";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  checkoutSettingsAtom,
  paymentMethodsSettingsAtom,
} from "../store/accountSettingsRecoil";
import { settings_loader } from "../config/loaders";
import { APIsAtom } from "../store/APIsRecoil";
import { RESET_TYPE } from "../utils/enums";
import { organizationAtom } from "store/organizationRecoil";
import { settingsAtom } from "../store/settingsRecoil";

export function usePaymentMethodsSettingsService() {
  const { call, ...rest } = useAPI();
  const setPaymentMethods = useSetRecoilState(paymentMethodsSettingsAtom);
  const orgInformation = useRecoilValue(organizationAtom);

  const fetch = () => {
    return call({
      link: `organizations/${orgInformation?.id}/settings/payment-methods?organizationId=${orgInformation?.id}`,
      onSuccess: (data) => {
        setPaymentMethods({ ...data });
      },
    });
  };

  const updateMethodState = ({ checked, method }) => {
    return call({
      link: `organizations/${orgInformation?.id}/settings/payment-methods?organizationId=${orgInformation?.id}`,
      method: "put",
      data: {
        method,
        enabled: checked,
      },
      onSuccess: (data) => {
        setPaymentMethods({ ...data });
      },
    });
  };

  return {
    fetch,
    updateMethodState,
    ...rest,
  };
}

export function useAPIsSettingsService() {
  const { call, ...rest } = useAPI();
  const setAPIs = useSetRecoilState(APIsAtom);
  const orgInformation = useRecoilValue(organizationAtom);
  const settings = useRecoilValue(settingsAtom);

  const fetch = () => {
    return call({
      link: `organizations/${orgInformation?.id}/settings/api-keys?organizationId=${orgInformation?.id}&testMode=${settings?.testMode}`,
      onSuccess: (data) => {
        setAPIs({ ...data });
      },
    });
  };

  const resetApi = (resetType = RESET_TYPE.PUBLIC) => {
    return call({
      link: `organizations/${orgInformation?.id}/settings/api-keys?organizationId=${orgInformation?.id}&testMode=${settings?.testMode}`,
      method: "put",
      data: {
        resetType,
      },
      onSuccess: (data) => {
        setAPIs({ ...data });
      },
    });
  };

  return {
    fetch,
    resetApi,
    ...rest,
  };
}

export function useUserSettingsService() {
  const { call, ...rest } = useAPI();
  const switchOrganization = ({ organizationId }) => {
    return call({
      link: `/users/settings?organizationId=${organizationId}`,
      method: "POST",
      data: { organizationId: organizationId },
      onSuccess: (data) => {},
    });
  };

  return {
    switchOrganization,
    ...rest,
  };
}

export function useGeneralSettingsService() {
  const { call, ...rest } = useAPI();
  const auth = useRecoilValue(authAtom);

  const save = ({ phone, firstName, lastName }) => {
    return call({
      link: `/users`,
      method: "PUT",
      loading_id: settings_loader.save_general_settings,
      data: {
        phone,
        firstName,
        lastName,
      },
    });
  };

  const resendVerification = () => {
    return call({
      link: `/settings/${auth.merchantId}/verification`,
      method: "post",
      loading_id: settings_loader.resend_verification_email,
    });
  };

  return {
    fetch,
    save,
    resendVerification,
    ...rest,
  };
}

export function useCheckoutService() {
  const { call, ...rest } = useAPI();
  const orgInformation = useRecoilValue(organizationAtom);
  const setSettings = useSetRecoilState(checkoutSettingsAtom);

  const uploadLogo = ({ logo: file }) => {
    return call({
      link: `/organizations/${orgInformation?.id}/settings/checkout/logo?organizationId=${orgInformation?.id}`,
      method: "POST",
      fileObjectKey: "file",
      data: {
        file,
      },
      withProgress: true,
    });
  };

  const updateCheckoutButtonColor = (color) => {
    return call({
      link: `/organizations/${orgInformation?.id}/settings/checkout/color?organizationId=${orgInformation?.id}`,
      method: "PUT",
      data: {
        color: color,
      },
    });
  };
  const getCheckoutProperties = () => {
    return call({
      link: `/organizations/${orgInformation?.id}/settings/checkout?organizationId=${orgInformation?.id}`,
      onSuccess: (data) => {
        if (data) {
          const state = {
            checkoutButtonColor: data?.color,
            logo: data?.logo ? "https://" + data?.logo : data?.logo,
          };
          setSettings((old) => ({
            ...old,
            ...state,
          }));
        }
      },
    });
  };

  return {
    uploadLogo,
    updateCheckoutButtonColor,
    getCheckoutProperties,
    ...rest,
  };
}
