import React from "react";
import {
  ControlLabel,
  FormControl,
  FormGroup,
  InputGroup,
  InputPicker,
} from "rsuite";
import { FormErrorMessage } from "../index";
import styles from "./amount.module.scss";
import { getListCurrencies } from "../../utils/helpers";

const Index = ({
  disableCurrency,
  label,
  placeholder,
  error,
  currencyError,
  name,
  currencyName,
  className,
  inputClassName,
  labelClassName,
  currencyChange,
  hintText,
  amountValue,
  disabled = false,
  ...props
}) => {
  return (
    <FormGroup className={`${styles.amountComponent} ${className}`}>
      {label ? (
        <ControlLabel className={labelClassName}>{label}</ControlLabel>
      ) : null}
      <InputGroup
        className={styles.amountCurrencyInput}
        disabled={disabled}
        inside
      >
        <InputPicker
          disabled={disableCurrency}
          cleanable={false}
          defaultValue={"QAR"}
          className={styles.amountCurrency}
          onChange={currencyChange}
          data={getListCurrencies()}
        />
        <FormControl
          value={amountValue}
          step="0.01"
          type={"number"}
          className={`${styles.amountValue} ${inputClassName} ${
            error ? "input-error" : ""
          }`}
          errorMessage={false}
          name={name}
          placeholder={placeholder}
          onWheel={(e) => e.target.blur()}
          {...props}
        />
      </InputGroup>
      {error ? (
        <FormErrorMessage error={error} />
      ) : (
        [hintText ? hintText : null]
      )}
    </FormGroup>
  );
};

export default Index;
