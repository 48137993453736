import React from "react";
import ReactDOM from "react-dom";
import "./assets/sass/global-style.scss";
import App from "./pages/app/App";
import { RecoilRoot } from "recoil";
import { ViewportProvider } from "hooks/useViewport";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <ViewportProvider>
          <BrowserRouter basename="/">
            <App />
          </BrowserRouter>
        </ViewportProvider>
      </I18nextProvider>
    </React.StrictMode>
  </RecoilRoot>,
  document.getElementById("root")
);
