import { useCallback, useEffect, useState } from "react";
import axiosBase from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom } from "store/authRecoil";
import { API_URL, APP_DEV } from "config/constants";
import { loadingSelector, unsetLoadingSelector } from "store/loadingRecoil";
import { useSignOut } from "../services/AuthService";
import { useTranslation } from "react-i18next";
import { ENV } from "../config/env";
import useOnlineStatus from "@rehooks/online-status";
import { Notification } from "rsuite";

const axios = axiosBase.create({
  baseURL: API_URL,
  timeout: 1000 * 60 * 3600,
  headers: { "X-Origin-App": "Dibsy" },
});

const CancelToken = axiosBase.CancelToken;
let cancel;

/*axiosBase.interceptors.request(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
})*/

const DEFAULT_ERROR_MESSAGE = "Internal server error";

function useAPI() {
  const FILTER_CHANGED_KEY = "Filter changed";

  const { t } = useTranslation(["api_codes", "components"]);

  const [responseData, setData] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [loader, setLoader] = useState(false);

  const onlineStatus = useOnlineStatus();

  //this loading is initialized with true by d
  const [fetchLoader, setFetchLoader] = useState(true);
  const [progress, setProgress] = useState(0);
  const setLoading = useSetRecoilState(loadingSelector);
  const unsetLoading = useSetRecoilState(unsetLoadingSelector);

  const signoutService = useSignOut();

  const auth = useRecoilValue(authAtom);

  const handleBeforeSend = (loading_id) => {
    if (loading_id) setLoading(loading_id);
    setLoader(true);
    setProgress(0);
  };
  const handleSuccess = (onSuccess, data) => {
    onSuccess && onSuccess(data);
  };

  const handleError = (onError, ex) => {
    onError && onError();
  };

  const handleAfterSend = useCallback(
    (onDone, loading_id) => {
      onDone && onDone();
      if (loading_id) unsetLoading(loading_id);
      setLoader(false);
      if (fetchLoader) setFetchLoader(false);
    },
    [loader, fetchLoader]
  );
  const getFileConfig = () => {
    return {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    };
  };

  const uploadProgressHandler = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const perc = Math.floor((loaded / total) * 100);
    setProgress(perc);
  };

  const getAuthHeader = (token) => {
    if (!token) return {};
    return { Authorization: "Bearer " + token };
  };

  /**
   *
   * @param {string} link The url in the backend
   * @param {"GET"|"POST"|"PUT"|"DELETE"} method
   * @param onSuccess callback when the call is success
   * @param onError
   * @param onDone
   * @param data
   * @param loading_id
   * @param fileObjectKey
   * @param withProgress
   * @param isCheckout
   * @param token
   * @param withCredentials
   * @param header
   * @param baseURL
   * @param reloadPageOn403
   * @returns {Promise<{data:any,message:string,success:boolean,errorCode:number}>}
   */
  async function call({
    link,
    method = "GET",
    onSuccess,
    onError,
    onDone,
    data = {},
    loading_id,
    fileObjectKey,
    withProgress,
    isCheckout = false,
    token = null,
    withCredentials = false,
    header = null,
    baseURL = null,
    reloadPageOn403 = true,
  }) {
    if (!onlineStatus && !APP_DEV) {
      Notification.closeAll();
      Notification.error({
        title: t("components:useAPI.title"),
        description: t("components:useAPI.description"),
      });
      return Promise.reject();
    }

    const _method = fileObjectKey ? "POST" : method || "GET";
    const config = {
      baseURL: baseURL ? baseURL : API_URL,
      ...(withProgress && {
        onUploadProgress: uploadProgressHandler,
      }),
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        ...(header ? header : {}),
        ...getAuthHeader(token || auth?.token),
        ...(fileObjectKey && { ...getFileConfig() }),
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      method: _method,
      url: link,
      ...(withCredentials ? { withCredentials: true } : {}),
      ...(!fileObjectKey && _method.toUpperCase() !== "GET" && { data }),
    };
    handleBeforeSend(loading_id);
    let caller;

    if (fileObjectKey) {
      let form = new FormData();
      if (data[fileObjectKey]?.length > 0) {
        for (const fi of Array.from(data[fileObjectKey])) {
          if (!fi) continue;
          form.append(fileObjectKey, fi, fi.name);
        }
      } else {
        if (data[fileObjectKey])
          form.append(
            fileObjectKey,
            data[fileObjectKey],
            data[fileObjectKey].name
          );
      }
      for (let fieldKey of Object.keys(data)) {
        if (fieldKey === fileObjectKey) continue;
        form.append(fieldKey, data[fieldKey]);
      }
      caller = axios.post(link, form, config);
    } else {
      caller = axios(config);
    }

    return new Promise((resolve, reject) => {
      caller
        .then((response) => {
          setData(response.data?.data || {});
          setStatus(response?.status);
          handleSuccess(onSuccess, response.data?.data || response.data);
          resolve({
            data: response?.data?.data || response?.data,
            success: response?.status?.toString()?.startsWith("2"),
            status: response?.status,
            /*       message: t(`api_codes:${response.data?.errorCode}`),
                        errorCode: response.data?.errorCode,*/
          });
        })
        .catch((ex) => {
          if (APP_DEV) {
            console.log("useAPI", ex);
          }
          setError(ex.response?.data || DEFAULT_ERROR_MESSAGE);
          handleError(onError, ex.response?.data || DEFAULT_ERROR_MESSAGE);
          resolve({
            data: ex.response?.data || DEFAULT_ERROR_MESSAGE,
            success: false,
            status: ex.response?.status,
            /*message: ex.response?.data?.errorCode ? t(`api_codes:${ex.response?.data?.errorCode}`) : DEFAULT_ERROR_MESSAGE,
                        errorCode: ex.response?.data?.errorCode || 0,*/
          });
          if (ex.response?.status === 403 || ex.response?.status === 401) {
            Notification.closeAll();
            Notification.error({
              title: t("components:useAPI.title"),
              description: t("components:useAPI.forbidden"),
            });
          }
          if (ex.response?.status === 403) {
            setTimeout(() => {
              signoutService.call(reloadPageOn403);
            }, 1000);
          }
          if (!ex.response?.status) {
            //check if it's not cancel token from axios
            if (typeof ex?.message === "string") {
              const message = ex.message;
              if (message === FILTER_CHANGED_KEY) {
                //it's cancel token do nothing
                return;
              }
            }
            Notification.closeAll();
            Notification.error({
              title: t("components:useAPI.title"),
              description: t("components:useAPI.serverDown"),
            });
          }
        })
        .finally(() => {
          handleAfterSend(onDone, loading_id);
        });
    });
  }

  function cancel_request() {
    cancel(FILTER_CHANGED_KEY);
  }

  return {
    call,
    cancel_request,
    data: responseData,
    status,
    error,
    loading: loader,
    progress,
    fetchLoader,
  };
}

export { useAPI };
