import React, { useEffect, useState } from "react";
import styles from "./amount.module.scss";
import { formatAmount, getCurrencySymbolByCode } from "utils/helpers";

let listLeft = ["QAR", "USD", "EUR"];

const Index = ({
  amount,
  amountStyle,
  currency,
  currencyStyle,
  className,
  tensStyle,
}) => {
  let [fullAmount, setFullAmount] = useState({});

  useEffect(() => {
    setFullAmount(formatAmount(amount));
  }, [amount]);

  return (
    <span className={`${className} ${styles.amountWrapper}`}>
      {listLeft.includes(currency) ? (
        <>
          <span className={`${styles.amountCurrency} ${currencyStyle}`}>
            {getCurrencySymbolByCode(currency)}
          </span>
          <span className={`${styles.amountText} ${amountStyle}`}>
            {fullAmount.number || 0}
            {fullAmount.tens ? (
              <span className={`${styles.amountTens} ${tensStyle}`}>
                {fullAmount.tens}
              </span>
            ) : null}
          </span>
        </>
      ) : (
        <>
          <span className={`${styles.amountText} ${amountStyle}`}>
            {fullAmount.number || 0}
            {fullAmount.tens ? (
              <span className={`${styles.amountTens} ${tensStyle}`}>
                {fullAmount.tens}
              </span>
            ) : null}
          </span>
          <span className={`${styles.amountCurrency} ${currencyStyle}`}>
            {getCurrencySymbolByCode(currency)}
          </span>
        </>
      )}
    </span>
  );
};

export default Index;
