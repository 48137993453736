import React from 'react';
import styles from "./fake-cards.module.scss"
import {Icon} from "rsuite"
import {useClipboard} from "use-clipboard-copy";
import {TEST_CARD_TYPE} from "utils/enums";
import {useSetRecoilState} from "recoil";
import {checkoutCardSelectedAtom} from "store/checkoutRecoil";
import {TEST_AUTH_CARD, TEST_FAILED_CARD, TEST_SUCCESS_CARD} from "config/constants";

const Index = () => {

        const [toggleCards, setToggleCards] = React.useState(false);
        const [successCopied, setSuccessCopied] = React.useState(false);
        const [declinedCopied, setDeclinedCopied] = React.useState(false);
        const [authCopied, setAuthCopied] = React.useState(false);
        const setSelectedCard = useSetRecoilState(checkoutCardSelectedAtom);
        const clipboard = useClipboard({})


        const copyText = (type) => {
            let cardNumber = "";
            switch (type) {
                case TEST_CARD_TYPE.authentication:
                    cardNumber = TEST_AUTH_CARD;
                    setAuthCopied(true);
                    break;
                case TEST_CARD_TYPE.failed:
                    cardNumber = TEST_FAILED_CARD;
                    setDeclinedCopied(true)
                    break;

                default:
                    break;
            }
            setSelectedCard({type})
            clipboard.copy(cardNumber)

            // clear text
            setTimeout(() => {
                setDeclinedCopied(false)
                setSuccessCopied(false)
                setAuthCopied(false)
            }, 3000)
        }

        return (
            <div className={styles.fakeCards}>
                <div className={styles.toggleSection} onClick={e => setToggleCards(!toggleCards)}>
                <span>
                    <Icon icon="credit-card-alt"/>&nbsp; TEST CARDS
                </span>
                    <Icon icon="angle-down"/>
                </div>
                {
                    toggleCards && <div className={styles.cards}>
                        <div className={`${styles.card} ${styles.success}`}
                             onClick={e => copyText(TEST_CARD_TYPE.authentication)}>
                            <span>{!authCopied ? "Authentication" : "Copied"}</span>
                            <Icon icon="copy"/>
                        </div>
                        <div className={`${styles.card} ${styles.error}`} onClick={e => copyText(TEST_CARD_TYPE.failed)}>
                            <span>{!declinedCopied ? "Declined" : "Copied"}</span>
                            <Icon icon="copy"/>
                        </div>
                        <p className={styles.text}>
                            Click to copy the card number or use any of our our test cards.
                            Use any future expiration date and three-number CVC.
                        </p>
                    </div>
                }
            </div>
        );
    }
;

export default Index;