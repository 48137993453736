import {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {isEqual, omit, isArray} from "lodash"
import {changeDetectRecoil} from "store/changeDetectRecoil";

/**
 *
 * @param {string} key unique key of the observed object
 * @param {any} observe object you want to listen to it's changes
 * @returns {{clearHistory: ()=>void, setInitialValue: (value: any) => void, changed: boolean}}
 */
function useDetectStateChange(key = "", observe = null) {
    const [changed, setChanged] = useState(false);
    const [changeDetect, setChangeDetect] = useRecoilState(changeDetectRecoil)
    useEffect(() => {
        if (observe) {
            const oldState = changeDetect[key];
            if (oldState) {
                if (isEqual(observe, oldState)) {
                    setChanged(false)
                } else {
                    setChanged(true)
                }
            } else {
                initiateValue(key, observe)
            }
        }
    }, [observe])

    function clearHistory() {
        setChangeDetect(old => ({
            ...omit(old, key)
        }))
    }

    /**
     *
     * @param {[{key:string, value:any}] || {key:string, value:any}} data
     */
    function globalValuesInitiate(data) {
        if (data) {
            if (isArray(data)) {
                for (let el of data) {
                    initiateValue(el.key, el.value)
                }
            } else {
                initiateValue(data.key, data.value)
            }
        }
    }

    function initiateValue(key, value) {
        if (key && value) {
            setTimeout(()=>{
                setChanged(false)
                setChangeDetect(old => ({
                    ...old,
                    [key]: value
                }))
            },100)
        }
    }

    function setInitialValue(value) {
        initiateValue(key, value)
    }

    function resetInitialValue() {
        setInitialValue(observe)
    }

    return {
        stateChanged: changed,
        setInitialValue,
        clearHistory,
        globalValuesInitiate,
        resetInitialValue
    }
}

export default useDetectStateChange;