import { atom } from "recoil";

export const generalSettingsAtom = atom({
  key: "generalSettingsAtom",
  default: {
    fullName: "",
    email: "",
    phone: "",
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  },
});

export const checkoutSettingsAtom = atom({
  key: "checkoutSettingsAtom",
  default: {
    logo: null,
    logoName: null,
    loaded: false,
    checkoutButtonColor: null,
  },
});

export const paymentMethodsSettingsAtom = atom({
  key: "paymentMethodsSettingsAtom",
  default: {
    amex: false,
    creditcard: false,
    debitcard: false,
    naps: false,
    googlepay: false,
    clicktopay: false,
  },
});
