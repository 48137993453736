import { atom } from "recoil";

export const customersFilterAtom = atom({
  key: "customersFilterAtom",
  default: {
    search: "",
    blacklist: false,
  },
});

export const customersTableSettingsAtom = atom({
  key: "customersTableSettingsAtom",
  default: {
    count: 20,
    page: 1,
    reload: false,
    totalCounts: 0,
    loading: false,
    showCreateCustomerDrawer: false,
    showMobileFilters: false,
  },
});

export const customersDataAtom = atom({
  key: "customersDataAtom",
});

export const createCustomerAtom = atom({
  key: "createCustomerAtom",
  default: {
    phone: "",
    email: "",
    name: "",
  },
});

export const customerPaymentsTableSettingsAtom = atom({
  key: "customerPaymentsTableSettingsAtom",
  default: {
    count: 20,
    page: 1,
    reload: false,
    totalCounts: 0,
    loading: false,
  },
});

export const customerPayementsDataAtom = atom({
  key: "customerPayementsDataAtom",
});

export const selectedCustomerAtom = atom({
  key: "selectedCustomerAtom",
  default: {
    id: "",
    phone: "",
    email: "",
    name: "",
    createdAt: "",
    cardTokens: [],
    loading: false,
  },
});
