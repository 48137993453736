import React from 'react';
import styles from "../merchant-info.module.scss";
import {FlexboxGrid, Placeholder} from "rsuite";

const {Graph} = Placeholder;

const Index = () => {
    return (
        <div className={styles.checkoutHeader}>
            <Graph width={60} height={60} className={"mb-3"}/>
            <div className={styles.merchantInfo}>
                <Graph width={150} height={15}/> <br/>
                <Graph width={170} height={15}/> <br/>
                <Graph width={200} height={8} className={"mt-2"}/>
                <div className={styles.checkoutAmount}>
                    <Graph width={100} height={15} className={"mt-2"}/>
                </div>
            </div>
        </div>
    );
};

export default Index;